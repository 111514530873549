@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.modal {
  height: 100vh;
  .content {
    width: vw_d(900);
    height: 100%;
    max-height: vw_d(604);

    &.name {
      width: vw_d(750);
      height: 100%;
      max-height: vw_d(450);
    }
  }

  .field {
    margin-bottom: vw_d(20);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

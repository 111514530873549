@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

:global {
  .INDDesktop #INDmenu-btn {
    top: auto !important;
    right: 15px !important;
    bottom: 33px !important;
  }

  .intercom-lightweight-app-launcher {
    bottom: 15px !important;
    right: 15px !important;
  }

  .intercom-namespace .intercom-app div:nth-child(2) {
    bottom: 15px !important;
    right: 15px !important;
  }

  .hideIntercom {
    opacity: 0 !important;
    visibility: hidden !important;
    cursor: none;
    pointer-events: none;
  }
}

.app {
  position: relative;
  min-height: 100vh;

  @include screen('mobile') {
    transform: none;
    min-height: inherit;
  }

  &.full-height {
    height: 100vh;
    overflow: hidden;
  }
}

.pop-up-manager {
  z-index: 999;
}

.info-popup {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

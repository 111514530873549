@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

:global {
  .jw-rightclick {
    display: none !important;
  }
}

body {
  /* custom icons */
  .icon-full-screen {
    display: block !important;
    // background-image: url('client/assets/player/icon-full-screen.svg');
    // background-size: contain;
    // background-repeat: no-repeat;
    width: vw_d(72) !important;
    height: vw_d(72) !important;
    border-radius: 50%;
    z-index: 100;
    overflow: hidden;
    margin-bottom: vw_d(50);

    &:hover {
      &:after {
        background-color: rgba(255, 255, 255, 0.39);
      }
    }

    &.small {
      width: vw_d(36) !important;
      height: vw_d(36) !important;
      svg {
        width: vw_d(18.5);
        height: vw_d(18.5);
      }
    }

    svg {
      width: vw_d(37);
      height: vw_d(37);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.19);
      filter: blur(12px) saturate(50%);
      pointer-events: none;
      transition: 0.45s;
    }
  }

  .icon-sound {
    display: block !important;
    width: vw_d(72) !important;
    height: vw_d(72) !important;
    margin-left: vw_d(30);
    border-radius: 50%;
    z-index: 100;
    overflow: hidden;
    margin-bottom: vw_d(50);

    &:hover {
      &:after {
        background-color: rgba(255, 255, 255, 0.39);
      }
    }

    &.small {
      width: vw_d(36) !important;
      height: vw_d(36) !important;
      svg {
        width: vw_d(18.5);
        height: vw_d(18.5);
      }
    }

    svg {
      width: vw_d(37);
      height: vw_d(37);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.19);
      filter: blur(12px) saturate(50%);
      pointer-events: none;
      transition: 0.45s;
    }
  }

  .player {
    position: relative;
    width: 100%;
    height: 100%;
    &.hideProgress {
      :global {
        .jw-slider-horizontal .jw-slider-container {
          display: none;
        }
      }
    }
    .cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-size: cover;
    }

    .playButton {
      position: absolute;
      top: 44.6%;
      left: 53.3%;
      transform: translate(-50%, -50%);

      &.small {
        width: vw_d(77) !important;
        height: vw_d(77) !important;

        &:before {
          width: vw_d(26);
          height: vw_d(26);
        }
      }

      &.centered {
        top: 50%;
        left: 50%;
      }
    }

    :global {
      .jw-button-container {
        // controllbar container
        position: absolute;
        bottom: 0;
        // bottom: vw_d(50);
        /* hide controllbar options */
        .jw-icon {
          display: none;
        }
      }

      .jw-breakpoint-7:not(.jw-flag-audio-player) .jw-controlbar .jw-button-container {
        padding: 0 32px 0;
      }

      .jw-breakpoint-0 .jw-display .jw-icon,
      .jwplayer:not(.jw-flag-ads):not(.jw-flag-live).jw-breakpoint-0 .jw-text-countdown {
        display: none;
      }

      .jwplayer.jw-flag-aspect-mode {
        height: 100% !important;
      }
      /* hide reset btn*/
      .jwplayer.jw-state-complete .jw-display .jw-icon-display .jw-svg-icon-replay {
        display: none !important;
      }

      .jwplayer.jw-state-paused .jw-display .jw-icon-display .jw-svg-icon-play {
        display: none !important;
      }

      /* made controllbar always visible */
      .jw-controlbar {
        opacity: 1 !important;
        visibility: visible !important;
        bottom: 0;
        height: vw_d(8) !important;
        max-height: inherit !important;
      }

      .jwplayer:not(.jw-flag-user-inactive) .jw-controlbar {
        will-change: initial;
      }

      /* remove custom bottom gradient: */
      .jw-controls-backdrop {
        display: none !important;
      }

      /* styles for progress -- start */
      .jw-slider-horizontal .jw-slider-container {
        height: vw_d(8);
      }

      .jw-progress {
        background-color: $primary-color;
        border-top-right-radius: vw_d(4);
        border-bottom-right-radius: vw_d(4);
      }

      .jw-slider-time {
        padding: 0;
        height: vw_d(8);
      }

      .jw-horizontal-volume-container .jw-buffer,
      .jw-horizontal-volume-container .jw-cue,
      .jw-horizontal-volume-container .jw-progress,
      .jw-horizontal-volume-container .jw-rail,
      .jw-slider-time:not(.jw-chapter-slider-time) .jw-buffer,
      .jw-slider-time:not(.jw-chapter-slider-time) .jw-cue,
      .jw-slider-time:not(.jw-chapter-slider-time) .jw-progress,
      .jw-slider-time:not(.jw-chapter-slider-time) .jw-rail {
        transform: none;
        top: auto;
      }

      .jw-breakpoint-7:not(.jw-flag-audio-player) .jw-controlbar .jw-slider-time {
        padding: 0 !important;
        height: vw_d(8) !important;
      }
      .jw-breakpoint-7:not(.jw-flag-audio-player) .jw-controlbar .jw-slider-time .jw-slider-container {
        height: vw_d(8) !important;
      }
      /* styles for progress -- end */
    }
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.title {
  font-family: $regular-font;
  font-weight: 600;
  color: #fff;
  text-align: center;

  &.m {
    font-size: 35px;
    line-height: 1.3;

    @include screen('mobile') {
      padding: 10px 10px 0;
      font-size: 22px;
      letter-spacing: 0.43px;
      line-height: 1.182;
      font-weight: 500;
    }

    @include screen('mobile-extra-small') {
      font-size: 20px;
      letter-spacing: 0.4px;
    }
  }

  &.l {
    font-size: 50px;
    line-height: 1.2;

    @include screen('mobile-extra-small') {
      font-size: 40px;
    }
  }
}

@import 'client/styles/mixins.scss';
@import 'client/styles/theme.scss';

.player {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  pointer-events: visiblePainted;

  .play-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    background-color: rgba(#fff, 0.5);
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);

    img {
      height: 33px;
      margin-right: -5px;
    }
  }

  .play-toggle-btn {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
  }

  &-controls {
    position: absolute;
    z-index: 3;
    right: 10px;
    bottom: 10px + 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &-btn {
      svg {
        max-width: 21px;
        max-height: 21px;
      }
    }
  }

  :global {
    .jw-aspect.jw-reset {
      padding-top: 240px !important;
    }

    .jw-display-container.jw-reset {
      display: none !important;
    }

    .jw-icon {
      display: none !important;
    }

    .jw-slider-time.jw-chapter-slider-time.jw-background-color.jw-slider-horizontal {
      padding: 0 !important;
      margin-bottom: -16px !important;
    }

    .jw-timesegment-resetter.jw-reset {
      height: 8px !important;
    }

    .jw-timesegment-bar.jw-progress.jw-timesegment-progress.jw-reset {
      background-color: $primary-color;
    }

    .jw-tooltip-time,
    .jw-knob.jw-reset {
      display: none !important;
    }
  }
}

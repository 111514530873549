@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.footer {
  position: fixed;
  display: flex;
  gap: 1px;
  align-items: center;
  background-color: #363636;

  left: 0;
  bottom: 0;
  width: 100%;
  height: $footer-height-mobile;
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;

  &.hidden {
    transform: translate(0, calc(100% + 15px));
  }
}

.footer-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  gap: 7px;
  height: 100%;
  background-color: #272727;

  @include screen('mobile-extra-small') {
    width: 18%;
  }

  &-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0);

    @include screen('mobile-extra-small') {
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }
  }

  &-label {
    font-size: 8px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    opacity: 0.5;
  }

  &.forInvestor {
    &:nth-child(3) {
      .footer-btn-icon {
        background: linear-gradient(0deg, rgb(39, 39, 39) 0%, rgb(7, 87, 211) 100%);
      }
    }
  }

  &:nth-child(3) {
    width: 40%;
    gap: 0;

    @include screen('mobile-extra-small') {
      width: 28%;
    }

    .footer-btn-icon {
      width: 55px;
      height: 55px;
      min-width: 55px;
      min-height: 55px;
      background: linear-gradient(180deg, #464646 0%, #272727 75%);
      border-radius: 50%;
      transform: translate(0, -10px);

      @include screen('mobile-extra-small') {
        transform: scale(0.9);
        transform: translate(0, -5px);
      }

      img {
        display: block;
        width: 30px;

        @include screen('mobile-extra-small') {
          width: 28px;
        }
      }
    }

    .footer-btn-label {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      opacity: 1;
      transform: translate(0, -10px);

      @include screen('mobile-extra-small') {
        font-size: 8px;
      }
    }

    &.active {
      .footer-btn-icon {
        background: linear-gradient(180deg, #3787f3 0%, $primary-color 75%);
      }
    }
  }

  &.active {
    background-color: $primary-color;
  }

  .text-logo {
    position: relative;
    width: 65px;
    margin-top: -5px;

    img {
      display: block;
      width: 100%;
    }

    p {
      font-size: 6.5px;
      letter-spacing: 0.15px;
      text-align: center;
      text-transform: none;
      margin-top: 3px;
    }
  }
}

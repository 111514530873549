@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.slider-wrapper {
  position: relative;
  width: 100%;
  height: vw_d(385);
  overflow: hidden;

  @include screen('mobile') {
    height: 240px;
  }

  :global {
    .swiper-pagination {
      display: flex;
      bottom: vw_d(26);
      left: vw_d(26);
    }

    .swiper-pagination-bullet {
      width: vw_d(11);
      height: vw_d(11);
      margin: 0 !important;
      margin-right: vw_d(4.5) !important;
      background-color: transparent;
      border: solid 1px #fff;
      border-radius: 50%;
      cursor: pointer;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }

  .swiper {
    width: 100%;
    height: vw_d(385);
    border-radius: vw_d(16);
    overflow: inherit !important;

    @include screen('mobile') {
      height: 240px;
      border-radius: 16px 16px 0 0;
    }

    .swiper-slide {
      position: relative;
      height: 100%;

      .slide-inner {
        height: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .pagination {
    position: absolute;
    display: flex;
    bottom: vw_d(26);
    left: vw_d(26);
    z-index: 2;

    @include screen('mobile') {
      left: 50%;
      bottom: 16px;
      transform: translate(-50%, 0);
    }

    .pagination-item {
      width: vw_d(11);
      height: vw_d(11);
      margin-right: vw_d(4.5);
      background-color: transparent;
      border: solid 1px #fff;
      border-radius: 50%;
      cursor: pointer;
      @include screen('mobile') {
        width: 9px;
        height: 9px;
        margin-right: 4.5px;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background-color: #fff;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

@value transitionDuration: 300ms;

.founders-slider {
  position: relative;
  display: flex;
  align-items: center;

  &.animated {
    .photo-wrap {
      transition: all transitionDuration linear;
      transform: translate(vw_d(16), 0);

      @include screen('mobile') {
        transform: translate(16px, 0);
      }

      &:first-child {
        opacity: 0;
      }

      &:last-child {
        opacity: 1;
      }
    }
  }
}

.photos {
  display: flex;
  flex-direction: row-reverse;
  margin-right: vw_d(17);

  @include screen('mobile') {
    margin-right: 17px;
  }
}

.photo-wrap {
  position: relative;
  width: vw_d(16);
  height: vw_d(63);
  transform: translate(0, 0);

  @include screen('mobile') {
    width: 16px;
    height: 63px;
  }

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      z-index: #{10 - $i};
    }
  }

  &:last-child {
    position: absolute;
    opacity: 0;
    left: vw_d(-16);
    top: 0;

    @include screen('mobile') {
      left: 16px;
    }
  }

  &:first-child {
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
    width: vw_d(63);

    @include screen('mobile') {
      width: 63px;
    }
  }
}

.photo {
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-color: white;
  width: vw_d(63);
  height: vw_d(63);
  border-radius: 50%;
  box-shadow: 0 vw_d(5) vw_d(6) vw_d(4) rgba(0, 0, 0, 0.2);

  @include screen('mobile') {
    width: 63px;
    height: 63px;
  }
}

.name-and-position {
  transition: opacity;
  transition-duration: transitionDuration;
  transition-timing-function: linear;

  .name {
    font-size: vw_d(18);
    line-height: vw_d(30.46);
    letter-spacing: 0;
    font-weight: 600;

    @include screen('mobile') {
      font-size: 16px;
      line-height: 1.904;
    }

    @include screen('mobile-extra-small') {
      font-size: 14px;
      line-height: 1.8;
    }
  }

  .position {
    font-size: vw_d(14);
    line-height: vw_d(21.76);
    letter-spacing: 0;

    @include screen('mobile') {
      font-size: 14px;
      line-height: 1.554;
    }

    @include screen('mobile-extra-small') {
      font-size: 13px;
      line-height: 1.4;
    }
  }
}

.transition {
  &-fade {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
    }

    &-enter-done {
      opacity: 1;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
    }
  }
}

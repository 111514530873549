@import 'routes/client/styles/mixins.scss';

.logo {
  position: relative;
  height: 34px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @include screen('mobile-extra-small') {
    height: 30px;
  }

  img {
    height: inherit;
    margin: 0 auto;
  }

  &-icon {
    height: 18px;
    margin-right: 7px;
  }

  &-text {
    height: 15px;
    margin-right: 7px;
  }

  &-sub-text {
    display: flex;
    flex-direction: column;
    gap: 3px;
    border-left: 1px solid white;
    padding-left: 7px;

    p {
      font-size: 8px;
      font-weight: 600;
      line-height: 0.8;
      text-align: left;
    }
  }
}

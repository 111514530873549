@import 'routes/client/styles/theme.scss';

.icon-button {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 4px;
}

.icon {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    max-width: 100%;
    max-height: 100%;
  }
}

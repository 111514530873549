@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.library {
  position: relative;
}

.content {
  padding-top: vw_d(74);

  &.no-data {
    padding: 0;
    margin: 0;
  }

  .favorite-list {
    margin-bottom: vw_d(150);

    &.is-busy {
      pointer-events: none;
    }

    .title {
      font-size: vw_d(35);
      line-height: vw_d(40);
      letter-spacing: 0;
      font-family: $regular-font;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      padding-top: vw_d(57);
      column-gap: vw_d(98);
      row-gap: vw_d(49);
    }
  }
}

.no-items {
  padding: vw_d(30) vw_d(30) vw_d(50);
  background-color: rgba(#fff, 0.08);
  text-align: center;

  @include screen('mobile') {
    padding: 0 30px 30px;
  }

  .title {
    max-width: vw_d(800);
    font-size: vw_d(50);
    line-height: 1.2;
    letter-spacing: vw_d(5);
    text-align: center;
    padding: vw_d(41) 0 vw_d(28);
    margin: 0 auto;

    @include screen('mobile') {
      max-width: 100%;
      font-size: 35px;
      letter-spacing: 3px;
      padding: 40px 0 30px;
      margin: 0 -20px;
    }
  }

  .message {
    max-width: vw_d(600);
    font-size: vw_d(18);
    letter-spacing: 0.35px;
    line-height: 1.389;
    // font-weight: 600;
    text-align: center;
    margin: 0 auto vw_d(50);

    @include screen('mobile') {
      max-width: 100%;
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: vw_d(234);
    height: vw_d(50);
    font-size: vw_d(16);
    line-height: 1;
    font-weight: 700;
    border-radius: vw_d(33);
    background-color: $primary-color;

    @include screen('mobile') {
      width: 100%;
      max-width: 100%;
      height: 50px;
      font-size: 16px;
      line-height: 1;
      border-radius: 33px;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.modal-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  // background-color: rgba(0, 0, 0, 0.78);
  z-index: 100;

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: vw_d(1400);
    height: 100%;
    max-height: vw_d(704);
    padding: vw_d(58) vw_d(37) 0 vw_d(37);
    border: solid vw_d(1) #979797;
    border-radius: vw_d(12);
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;

    .title {
      padding-bottom: vw_d(81);
      font-size: vw_d(32);
      line-height: 0.857;
      color: #fff;
      font-family: $regular-font;
      font-weight: 700;
    }
  }

  .close-button {
    position: absolute;
    right: vw_d(45);
    top: vw_d(25);
    width: vw_d(20);
    height: vw_d(20);
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: vw_d(20);
      height: vw_d(4);
      border-radius: vw_d(2);
      background-color: #fff;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.slider {
  position: relative;
  min-height: vw_d(524);
}

.slider-title {
  position: relative;
  margin-bottom: vw_d(-68);
  padding: 0 vw_d(77);
  font-size: vw_d(35);
  line-height: 1.143;
  font-family: $regular-font;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  z-index: 10;
}

.slider-wrapper {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  z-index: 11;
  pointer-events: none;

  .nav-btn {
    position: absolute;
    top: vw_d(100);
    bottom: vw_d(100);
    width: vw_d(77);
    background-color: red;
    z-index: 10;
    transition: all 0.3s;
    cursor: pointer;
    pointer-events: visible;

    &.hide {
      opacity: 0;
      visibility: hidden;
    }

    &.next {
      right: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

      .arrow {
        position: absolute;
        width: vw_d(27);
        height: vw_d(46);
        top: 50%;
        left: 50%;
        transform: translate(0%, -50%);
        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: vw_d(32);
          height: vw_d(6);
          background-color: #fff;
          border-radius: vw_d(3);
        }

        &:before {
          top: vw_d(11);
          transform: rotate(45deg);
        }

        &:after {
          top: vw_d(31);
          transform: rotate(-45deg);
        }
      }
    }
    &.prev {
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

      .arrow {
        position: absolute;
        width: vw_d(27);
        height: vw_d(46);
        top: 50%;
        left: 30%;
        transform: translate(0%, -50%) rotate(180deg);
        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: vw_d(32);
          height: vw_d(6);
          background-color: #fff;
          border-radius: vw_d(3);
        }

        &:before {
          top: vw_d(11);
          transform: rotate(45deg);
        }

        &:after {
          top: vw_d(31);
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.swiper {
  width: 100%;
  height: vw_d(524);
  padding: vw_d(100) vw_d(77) vw_d(80);
  overflow: inherit !important;
}

.swiper-slide {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  pointer-events: visible;
  &.active {
    z-index: 3;
  }
}

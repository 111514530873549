@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.content-transition {
  $transition-duration: 300ms;

  &-appear {
    opacity: 0;
  }

  &-appear-active {
    opacity: 1;
    transition: opacity;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-appear-done {
    opacity: 1;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
  }

  &-exit-done {
    opacity: 0;
  }
}

.stageWrapper {
  @include screen('mobile') {
    position: relative;
    height: 100%;
  }
}

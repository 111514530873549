@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.search-results {
  min-height: 100vh;
  padding-top: 156px;
  position: relative;

  .content-wrapper {
    width: 100%;
    padding: 0 0 vw_d(40);

    .back-wrapper {
      padding: 0 vw_d(77);

      button {
        display: flex;
        align-items: center;
        font-size: vw_d(20.09);
        line-height: 1.566;
        letter-spacing: vw_d(0.39);
        font-family: $regular-font;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.48);
        text-transform: uppercase;

        .arrow {
          transform: rotate(180deg);
        }
      }
    }

    .loader {
      position: relative;
      min-height: vw_d(314);
      margin-top: vw_d(180);
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: vw_d(25) vw_d(77) 0;

      .serch-string {
        font-size: vw_d(70);
        line-height: 1.143;
        color: #fff;
        font-family: $regular-font;
        font-weight: 700;
        text-transform: uppercase;
      }

      .total-results {
        font-size: vw_d(20.09);
        line-height: 1.566;
        letter-spacing: vw_d(0.39);
        color: #fff;
        font-family: $regular-font;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .results-wrapper {
      position: relative;
      min-height: vw_d(324);
      padding: 0;

      .companies {
        display: flex;
        flex-wrap: wrap;
        width: vw_d(1808);
        margin: 0 auto;
        margin-top: vw_d(100);
      }

      .company {
        position: relative;
        width: vw_d(422);
        height: vw_d(314);
        margin: 0 vw_d(15) vw_d(90);
      }
    }

    .view-more-wrapper {
      display: flex;
      justify-content: center;
      padding-top: vw_d(10);

      .view-more-btn {
        position: relative;
        display: flex;
        align-items: center;
        height: vw_d(80);
        padding: 0 vw_d(58.5);
        font-size: vw_d(25.6);
        font-family: $regular-font;
        font-weight: 600;
        border-radius: vw_d(40);
        background-color: rgba(255, 255, 255, 0.3);
        overflow: hidden;

        .companies-loader {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .no-results-text {
      padding: vw_d(37) vw_d(77) 0;
      font-size: vw_d(35);
      line-height: 1.343;
      color: #fff;
      font-family: $regular-font;
      font-weight: 400;

      b {
        font-weight: 700;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.error {
  text-align: left;
  font-size: vw_d(13);
  letter-spacing: 0.08px;
  color: red;

  @include screen('mobile') {
    padding-top: 4px;
    font-size: 12px;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.company-info {
  position: relative;
  width: 100%;
  max-width: vw_d(760);

  @include screen('mobile') {
    max-width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    padding-top: vw_d(5);
    margin-top: vw_d(-10);
    padding-bottom: vw_d(56.82);
    font-size: vw_d(50.44);
    line-height: 1.05;
    letter-spacing: vw_d(8.07);
    color: white;
    font-family: $regular-font;
    font-weight: 600;

    @include screen('mobile') {
      padding-top: 5px;
      margin-top: -10px;
      padding-bottom: 20px;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 1.3px;
      font-weight: 700;
    }

    @include screen('mobile-extra-small') {
      font-size: 20px;
      line-height: 26px;
    }

    .company-logo {
      position: relative;
      width: vw_d(99);
      min-width: vw_d(99);
      height: initial;
      margin-right: vw_d(26);
      margin-bottom: auto;
      border-radius: vw_d(8);
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        padding-top: 100%; /* initial ratio of 1:1*/
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
  .description {
    font-size: vw_d(26);
    line-height: 1.64;
    color: white;
    font-family: $regular-font;
    font-weight: 300;

    @include screen('mobile') {
      font-size: 16px;
      line-height: 1.423;
      font-weight: 400;
    }

    @include screen('mobile-extra-small') {
      font-size: 14px;
      line-height: 1.4;
    }

    span {
      display: inline-block;
      padding-left: 5px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .general-info {
    padding-top: vw_d(80);

    @include screen('mobile') {
      padding-top: 20px;
    }

    .row {
      display: flex;
      padding: vw_d(28) 0px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.25);

      @include screen('mobile') {
        padding: 15px 0 14px;
      }

      .col-left {
        width: 47%;
        padding-right: vw_d(10);
        font-size: vw_d(26);
        line-height: vw_d(31.4);
        color: white;
        font-family: $regular-font;
        font-weight: 600;

        @include screen('mobile') {
          padding-right: 0;
          padding-left: 10px;
          font-size: 16px;
          line-height: 1.298;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 1.2;
        }
      }
      .col-right {
        width: 53%;
        font-size: vw_d(26);
        line-height: vw_d(31.4);
        color: white;
        font-family: $regular-font;
        font-weight: 500;

        @include screen('mobile') {
          padding-right: 10px;
          font-size: 16px;
          line-height: 1.298;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 1.2;
        }

        .website-link {
          display: inline-block;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .source-info {
    padding-top: vw_d(13.1);
    font-size: vw_d(21.17);
    line-height: 1.484;
    color: #fff;
    font-family: $regular-font;
    font-weight: 300;

    @include screen('mobile') {
      padding-top: 14px;
      padding-left: 11px;
      font-size: 14px;
      line-height: 1.484;
    }

    @include screen('mobile-extra-small') {
      font-size: 13px;
      line-height: 1.4;
    }

    .source-info-wrapper {
      display: inline;

      p {
        display: inline;
        font-size: vw_d(21.17);
        line-height: 1.484;
        color: #fff;
        font-family: $regular-font;
        font-weight: 300;

        @include screen('mobile') {
          font-size: 14px;
        }

        @include screen('mobile-extra-small') {
          font-size: 13px;
        }

        a {
          color: #fff;
        }
      }
    }
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.select-wrapper {
  .label {
    padding-bottom: vw_d(8);
    font-size: 13px;
    line-height: 1.231;
    letter-spacing: vw_d(-0.05);
    font-family: $inter;
    color: rgba(255, 255, 255, 0.87);
    text-align: left;

    @include screen('mobile') {
      font-size: 13px;
      padding-bottom: 8px;
    }
  }
  .dropdownIndicator {
    width: 10px;
    height: 10px;
    margin-right: 20px;
    margin-top: 2px;

    @include screen('mobile-extra-small') {
      margin-right: 20px;
    }

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .control {
    height: vw_d(48);
    box-shadow: inset 0 0 vw_d(2) #fff;
    border: none;
    background-color: transparent;
    border-radius: vw_d(6);
    @include screen('mobile') {
      height: 48px;
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px currentColor;
    }
    &:hover {
      border: none;
    }
    &.withError {
      box-shadow: inset 0 0 vw_d(2) rgba(252, 68, 79, 1);
      @include screen('mobile') {
        box-shadow: inset 0 0 0 1px rgba(252, 68, 79, 1);
      }
    }
  }

  .indicatorSeparator {
    display: none;
  }

  .input {
    input {
      width: auto !important;
      font-size: 16px !important;
      font-family: $inter !important;
      color: rgba(255, 255, 255, 0.87) !important;
    }
  }

  .placeholder {
    font-size: 16px !important;
    font-family: $inter !important;
    color: rgba(255, 255, 255, 0.87) !important;
  }

  .singleValue {
    font-size: 16px !important;
    font-family: $inter !important;
    color: rgba(255, 255, 255, 0.87) !important;
  }

  .menu {
    height: 150px !important;
    background-color: rgba(17, 17, 17, 0.97) !important;
    overflow: auto !important;
  }

  .menuList {
    height: 150px !important;
    background-color: transparent !important;
  }

  .option {
    font-size: 12px !important;
    letter-spacing: -0.25px !important;
    font-weight: 400 !important;
    color: '#fff';
    &:hover {
      color: rgba(17, 17, 17, 0.97);
    }
    &.selected {
      color: rgba(17, 17, 17, 0.97);
    }
  }
}

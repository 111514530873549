@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

@value opentransitiontransform: 300ms;

header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 110px;

  z-index: 100;

  .header-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 40px 15px 30px;
    z-index: 1000;
    .left {
      display: flex;
      align-items: center;
      .menu-wrapper {
        margin-right: vw_d(23);
        .menu-button {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: vw_d(41);
          height: vw_d(42);
          padding: vw_d(10) vw_d(5) vw_d(9) vw_d(7);
          cursor: pointer;
          z-index: 3;
          &:hover {
            div {
              &:nth-child(2) {
                width: 100%;
              }
            }
          }
          div {
            width: 100%;
            height: vw_d(2);
            border-radius: vw_d(1);
            background-color: white;
            transition: all ease 0.3s;
            &:nth-child(2) {
              width: vw_d(20);
            }
          }
          &.active {
            div {
              &:nth-child(1) {
                transform-origin: 0 100%;
                transform: rotate(45deg);
              }
              &:nth-child(2) {
                opacity: 0;
              }
              &:nth-child(3) {
                transform-origin: 0 0;
                transform: rotate(-45deg);
              }
            }
          }
        }
        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgb(0, 0, 0);
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          z-index: 2;
        }
      }
      .logo-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 3;
        .logo {
          display: flex;
          align-items: center;
          img {
            display: block;
            width: vw_d(164);
          }
        }
      }
    }

    .right {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 3;
      .sign-in-button {
        width: 113px;
        margin-right: 15.04px;
        font-size: 16px;
        line-height: 16px;
        color: white;
        font-family: $regular-font;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
      }
      .sign-up-btn {
        min-width: 160px;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
      }
    }
  }
}

.enter {
  left: vw_d(-547);

  &-active {
    left: 0;
    transition: all opentransitiontransform ease;
  }
}

.exit {
  left: 0;
  &-active {
    left: vw_d(-547);
    transition: all opentransitiontransform ease;
  }
}

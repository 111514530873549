@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';
@import 'routes/client/styles/mixins.scss';

.player {
  position: relative;
  width: 100%;
  height: vw_d(230);
  pointer-events: none;

  @include screen('mobile') {
    height: 90px;
    width: 40%;
  }

  &:not(.is-custom-controls) {
    :global {
      .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
        visibility: visible !important;
        opacity: 1 !important;
        pointer-events: auto !important;
      }
  
      .video-js .vjs-control-bar {
        background-color: transparent;
        height: vw_d(8);
        z-index: 2;
      }
  
      .video-js .vjs-control:not(.vjs-progress-control) {
        display: none!important;
      }
  
      .video-js .vjs-progress-control .vjs-progress-holder {
        margin: 0;
        padding-bottom: vw_d(8);
      }
  
      .vjs-play-progress .vjs-svg-icon {
        display: none;
      }
  
      .video-js .vjs-progress-holder,
      .video-js .vjs-progress-holder .vjs-load-progress {
        height: vw_d(8);
      }
  
      .video-js .vjs-play-progress {
        background-color: $primary-color;
        border-top-right-radius: vw_d(4);
        border-bottom-right-radius: vw_d(4);
      }
    }
  }

  :global {
    .video-js {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    .vjs-default-skin.vjs-paused .vjs-big-play-button,
      .video-js .vjs-big-play-button {
        display: none;
      }

    .vjs-picture-in-picture-control {
      display: none;
    }
  }

  &.is-playing {
    .cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      transition: 0.3s;
    }
  }

  video {
    border-top-left-radius: vw_d(4);
    border-top-right-radius: vw_d(4);
    // object-fit: cover;
  }

  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: vw_d(82) !important;
    height: vw_d(82) !important;

    @include screen('mobile') {
      width: 34px !important;
      height: 34px !important;
    }

    &:before {
      width: vw_d(26);
      height: vw_d(26);

      @include screen('mobile') {
        width: 13px;
        height: 13px;
      }
    }

    &:hover {
      pointer-events: auto;
      cursor: pointer;
    }
  }

  .duration,
  .status {
    position: absolute;
    top: vw_d(14);
    left: vw_d(16);
    padding: vw_d(5) vw_d(15) vw_d(4) vw_d(16);
    font-size: vw_d(16);
    line-height: 1.5;
    letter-spacing: 1.28px;
    font-weight: 500;
    border-radius: vw_d(7);
    background-color: rgba(255, 255, 255, 0.18);

    @include screen('mobile') {
      top: 14px;
      left: calc(100% + 14px);
      padding: 3px 4px;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 1.12px;
      border-radius: 3px;
    }
  }

  // .duration {
  //   position: ;
  // }

  .status {
    left: auto;
    right: vw_d(16);

    @include screen('mobile') {
      left: 10px;
      right: auto;
    }
  }
}

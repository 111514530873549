@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

@value transitionOpacity: 300ms;

.enter {
  opacity: 0;
  visibility: hidden;

  &-active {
    opacity: 1;
    visibility: visible;
    transition: all transitionOpacity ease;
  }
}

.exit {
  opacity: 1;
  visibility: visible;

  &-active {
    opacity: 0;
    visibility: hidden;
    transition: all transitionOpacity ease;
  }
}

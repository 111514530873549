@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.back-to-top {
  position: fixed;
  height: vw_d(48);
  border: vw_d(24);
  bottom: vw_d(15);
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  transition: all ease 0.2s;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    filter: saturate(50%);
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.3);
    // opacity: ;
    border-radius: vw_d(24);
  }

  .inner {
    position: relative;
    display: flex;
    padding: 0 vw_d(17) 0 vw_d(15);
    align-items: center;
    color: #fff;
    font-size: vw_d(20.09);
    letter-spacing: vw_d(0.39);
    font-family: $regular-font;
    font-weight: 700;

    img {
      display: block;
      width: vw_d(18);
      margin-right: vw_d(10);
    }
  }
}

:global {
  .visible {
    opacity: 1;
    visibility: visible;
  }
}

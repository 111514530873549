@import 'client/styles/mixins.scss';
@import 'client/styles/theme.scss';

.companies-slider {
  position: relative;
  margin: 0 -#{$content-wrap-margin + 15px} 34px;

  &-items {
    position: relative;
    width: 100%;
    height: 340px;
    overflow: hidden;
    margin-bottom: 0;
    @include screen('mobile-large') {
      height: 400px;
    }
  }

  &-item {
    position: relative;
    height: 340px;
    @include screen('mobile-large') {
      height: 400px;
      width: 400px;
      margin: 0 auto;
    }

    &-preview {
      height: 240px;
      border-radius: 30px;
      background-size: cover;
      background-position: center top;
      margin: 0 #{$content-wrap-margin + 15px} 18px;
      @include screen('mobile-large') {
        height: 300px;
      }
    }

    &-info {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 #{$content-wrap-margin + 15px};
      width: 100%;

      &-title-wrap {
        width: calc(100% - 100px);
      }

      &-title {
        font-size: 28px;
        line-height: 1.1;
        letter-spacing: 4.58px;
        font-weight: 700;
        margin-bottom: 5.34px;
        @extend %overflow-ellipsis;

        @include screen('mobile-large') {
          height: 300px;
        }

        @include screen('mobile-extra-small') {
          font-size: 22px;
          letter-spacing: 1.5px;
        }
      }

      &-description {
        font-size: 14px;
        font-weight: 200;
        line-height: 24.2px;
        @extend %overflow-ellipsis;
      }

      &-votes {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 22px;
        letter-spacing: 0.43px;

        &-icon {
          position: relative;
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background-color: rgba(#25e061, 0.25);

          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            width: 1px;
            border: 6px solid transparent;
            border-bottom: 9px solid #fff;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    :global {
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #fff;
        transition: width, background-color;
        transition-duration: 0.5s;
      }

      .swiper-pagination-bullet-active {
        width: 22px;
        background-color: rgba(white, 0.7);
      }
    }
  }
}

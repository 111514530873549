@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: vw_d(900);
  overflow: hidden;

  @include screen('mobile') {
    display: block;
    height: auto;
    min-height: auto;
  }
}

.top-section {
  position: relative;
  flex-grow: 1;

  @include screen('mobile') {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 60px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: vw_d(934);
    height: 100%;
    background: linear-gradient(270deg, rgba(221, 215, 215, 0) 0%, rgba(0, 0, 0, 1) 50%);
    z-index: 0;
    pointer-events: none;

    @include screen('mobile') {
      display: none;
    }
  }

  &-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;

    .company {
      &-info {
        position: relative;
        max-width: vw_d(445);
        z-index: 1;

        @include screen('mobile') {
          max-width: 100%;
        }
      }

      &-logo {
        display: block;
        max-width: vw_d(120);
        max-height: vw_d(120);
        margin-bottom: vw_d(29.25);

        @include screen('mobile') {
          max-height: 62px;
          max-width: 100%;
          margin-bottom: 22px;
        }
      }

      &-name {
        font-size: vw_d(30);
        line-height: vw_d(42.65);
        letter-spacing: vw_d(4.8);
        margin-bottom: vw_d(20);
        font-weight: 700;
        text-transform: uppercase;

        @include screen('mobile') {
          font-size: 25px;
          line-height: 36px;
          letter-spacing: 2px;
          margin-bottom: 12px;
        }

        @include screen('mobile-extra-small') {
          font-size: 22px;
          line-height: 32px;
          letter-spacing: 1.5px;
        }
      }

      &-description {
        font-size: vw_d(20);
        line-height: vw_d(34);
        letter-spacing: vw_d(0);
        margin-bottom: vw_d(50);
        font-weight: 600;

        @include screen('mobile') {
          font-size: 18px;
          line-height: 30px;
          font-weight: 500;
          margin-bottom: 21px;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 26px;
        }
      }

      &-actions {
        display: flex;
        gap: vw_d(14);
      }
    }

    .investment-status {
      display: flex;
      align-items: center;
      justify-content: center;
      width: vw_d(320);
      height: vw_d(74);
      font-size: vw_d(28.09);
      letter-spacing: vw_d(0.39);
      line-height: 1;
      font-weight: 600;
      border-top: 1px solid rgba(255, 255, 255, 0.31);
      border-bottom: 1px solid rgba(255, 255, 255, 0.31);

      @include screen('mobile') {
        width: 100%;
        height: 46px;
        font-size: 16px;
        letter-spacing: 0px;
        padding: 0 28px;
      }

      .orange-dot {
        display: inline-block;
        width: vw_d(11);
        height: vw_d(11);
        border-radius: 50%;
        background-color: rgb(242, 151, 54);
        margin-right: vw_d(7);

        @include screen('mobile') {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }
      }
    }

    .place-offer-btn,
    .docs-btn {
      font-size: vw_d(20.09);
      letter-spacing: vw_d(0.39);
      line-height: 1;
      height: vw_d(55);
      border-radius: vw_d(28);
      padding: 0 vw_d(28);

      @include screen('mobile') {
        font-size: 16px;
        letter-spacing: 0px;
        height: 42px;
        border-radius: 27px;
        padding: 0 28px;
      }
    }

    .docs-btn {
      min-width: auto;
      background-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      transition: background-color 0.3s ease;

      & > span {
        gap: vw_d(11);
      }

      &-icon {
        display: block;
        max-width: vw_d(16);
        max-height: vw_d(17);

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: white;
          }
        }
      }

      @include screen('mobile') {
        display: none;
      }
    }

    .categories {
      position: absolute;
      left: 0;
      bottom: vw_d(67);
      display: flex;
      gap: vw_d(14);
      z-index: 1;

      @include screen('mobile') {
        position: static;
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 30px;
      }

      .category-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        height: vw_d(35);
        padding: 0 vw_d(18);
        border-radius: vw_d(18);

        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        transition: background-color 0.3s ease;

        font-size: vw_d(16.69);
        line-height: vw_d(26.13);
        letter-spacing: vw_d(0.32);
        font-weight: 600;
        white-space: nowrap;

        @include screen('mobile') {
          height: 30px;
          padding: 0 16px;
          margin: 0 10px 10px 0;
          border-radius: 17px;

          font-size: 14px;
          line-height: 26px;
          letter-spacing: 0.27px;
          font-weight: 700;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
}

.top-player {
  position: absolute;
  right: 0;
  top: 0;
  width: vw_d(1478);
  height: 100%;

  @include screen('mobile') {
    position: relative;
    width: 100%;
    height: 270px;
    overflow: hidden;
  }

  @include screen('mobile-large') {
    position: relative;
    width: 100%;
    height: 350px;
  }

  &:after {
    @include screen('mobile') {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(180deg, rgba(221, 215, 215, 0) 0%, rgba(0, 0, 0, 1) 100%);
      z-index: 0;
      pointer-events: none;
    }
  }

  & :global {
    .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
      height: 100%;
    }
    .dimensions-65a3f71806acb03915be58df.vjs-fluid:not(.vjs-audio-only-mode) {
      padding-top: 0;
    }

    .vjs-control-bar {
      display: none !important;
    }
  }

  video {
    object-fit: cover;
  }

  .gallery-button {
    position: absolute;
    bottom: vw_d(50);
    right: vw_d(234);
    display: flex;
    justify-content: center;
    align-items: center;
    width: vw_d(172);
    height: vw_d(72);
    padding: 0 vw_d(30);
    font-size: vw_d(20);
    line-height: 1;
    letter-spacing: 0.2px;
    font-weight: 600;
    border-radius: vw_d(37);
    overflow: hidden;

    @include screen('mobile') {
      bottom: 16px;
      right: 124px;
      width: 41px;
      height: 41px;
      padding: 0;
      border-radius: 50%;
      z-index: 1;
    }

    &:hover {
      &:after {
        background-color: rgba(255, 255, 255, 0.39);
      }
    }

    .watch-more-icon {
      width: vw_d(36);
      height: vw_d(36);
      margin-right: vw_d(12);

      @include screen('mobile') {
        width: 20px;
        height: 20px;
        margin-right: 0;
      }

      svg {
        width: inherit;
        height: inherit;
      }
    }

    &-label {
      text-align: left;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.19);
      filter: blur(12px) saturate(50%);
      pointer-events: none;
      transition: 0.45s;
    }
  }
}

.bottom-section {
  position: relative;
  display: flex;
  align-items: center;
  height: vw_d(196);
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  @include screen('mobile') {
    height: auto;
    display: block;
    padding: 0;
    margin: 0 37px;
  }

  @include screen('mobile-extra-small') {
    margin: 0 #{$content-wrap-margin - 10px};
  }

  &-content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    gap: vw_d(200);

    @include screen('mobile') {
      display: block;
    }
  }

  .info-block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: vw_d(125);
    flex-grow: 1;

    @include screen('mobile') {
      display: block;
      height: auto;
      margin: 0 -37px;
      padding: 24px 20px 25px 28px;
    }

    @include screen('mobile-extra-small') {
      margin: 0;
      padding: 24px 0 25px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: vw_d(-100);
      top: 0;
      width: 1px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.1);

      @include screen('mobile') {
        top: auto;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      gap: vw_d(7);

      font-size: vw_d(20);
      line-height: 1;
      letter-spacing: vw_d(1.22);
      font-weight: 600;

      @include screen('mobile') {
        font-size: 20px;
        letter-spacing: 1.22px;
        padding-bottom: 14px;
      }

      @include screen('mobile-extra-small') {
        font-size: 18px;
        letter-spacing: 1.2px;
      }

      .green-dot-title {
        @include screen('mobile') {
          font-size: 14px;
          letter-spacing: 0.85px;
        }

        @include screen('mobile-extra-small') {
          font-size: 13px;
          letter-spacing: 0.7px;
        }
      }
    }

    &-content {
      position: relative;
    }

    .investment-details {
      display: flex;
      align-items: center;

      @include screen('mobile') {
        display: block;
      }

      &-block {
        position: relative;
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: 0 vw_d(27);
        gap: vw_d(18);
        border-right: 1px solid rgba(255, 255, 255, 0.1);

        @include screen('mobile') {
          padding: 0;
          border-right: 0;
        }

        &:first-child {
          padding-left: 0;

          @include screen('mobile') {
            padding: 0;
            flex-direction: column-reverse;
          }
        }

        &:last-child {
          border-right: 0;
        }
      }

      .pie-chart-wrap {
        position: relative;
        width: vw_d(65);
        height: vw_d(53);

        .pie-chart {
          position: absolute;
          left: 0;
          bottom: 0;
          width: vw_d(65);
          height: vw_d(65);
        }
      }

      .progress-wrap {
        position: relative;
        width: 100%;
        height: 11px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 5.3px;
        margin: 10px 0 15px;

        .progress {
          position: absolute;
          top: 0;
          left: 0;
          height: 11px;
          background: linear-gradient(270deg, rgb(37, 123, 255) 0%, rgb(7, 87, 211) 100%);
          border-radius: 5.3px;
        }
      }

      .values {
        white-space: nowrap;

        @include screen('mobile') {
          display: flex;
          width: 100%;
          white-space: wrap;
        }

        .target {
          font-size: vw_d(22);
          line-height: vw_d(27);
          letter-spacing: vw_d(1.1);
          font-weight: 600;
          text-transform: uppercase;

          @include screen('mobile') {
            font-size: 20px;
            line-height: 1.35;
            letter-spacing: 1px;
            padding-right: 17px;
            position: relative;

            @include screen('mobile-extra-small') {
              font-size: 16px;
              line-height: 1.3;
              letter-spacing: 0.8px;
              padding-right: 15px;
            }

            &:after {
              content: '';
              position: absolute;
              top: 5px;
              right: 8px;
              height: 16px;
              width: 1px;
              background-color: white;

              @include screen('mobile-extra-small') {
                top: 0;
                height: 100%;
              }
            }
          }
        }

        .raised {
          font-size: vw_d(18);
          line-height: vw_d(26);
          letter-spacing: vw_d(0.9);

          @include screen('mobile') {
            font-size: 16px;
            line-height: 1.688;
            letter-spacing: 0.8px;
            font-weight: 600;
          }

          @include screen('mobile-extra-small') {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.8px;
          }
        }
      }

      .round {
        font-size: vw_d(18);
        line-height: vw_d(28);
        letter-spacing: vw_d(0.39);
        white-space: nowrap;

        @include screen('mobile') {
          font-size: 14px;
          line-height: 1.714;
          letter-spacing: 0.85px;
          display: flex;
        }

        @include screen('mobile-extra-small') {
          font-size: 13px;
          line-height: 1.6;
          letter-spacing: 0.6px;
        }

        &-title {
          font-weight: 600;
        }

        &-valuation {
          font-weight: 400;

          @include screen('mobile') {
            font-weight: 600;
            position: relative;
            padding-left: 16px;

            &:before {
              content: '';
              position: absolute;
              top: 5px;
              left: 8px;
              height: 14px;
              width: 1px;
              background-color: white;
            }
          }
        }
      }
    }

    .lead-investors-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include screen('mobile') {
        margin-left: 2px;
      }
    }

    .lead-investors {
      display: flex;
      align-items: center;

      @include screen('mobile') {
        justify-content: space-between;
        max-width: 276px;
        margin-top: 6px;
      }

      &:hover {
        .lead-investor-title {
          text-decoration: underline;
        }
      }

      &-logos {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        cursor: pointer;

        .lead-investors-logo {
          position: relative;
          width: vw_d(16);
          height: vw_d(57);

          div {
            position: absolute;
            background-color: white;
            background-size: contain;
            background-position: center;
            z-index: 3;
            top: 0;
            left: 0;
            width: vw_d(57);
            height: vw_d(57);
            border-radius: vw_d(7);
            box-shadow: 0 vw_d(5) vw_d(6) vw_d(4) rgba(0, 0, 0, 0.2);

            @include screen('mobile') {
              width: 57px;
              height: 57px;
              border-radius: 7px;
            }
          }

          &:nth-child(1) {
            width: vw_d(57);
            z-index: 3;

            @include screen('mobile') {
              width: 57px;
              height: 57px;
            }
          }

          &:nth-child(2) {
            z-index: 2;
          }

          &:nth-child(3) {
            z-index: 1;
          }
        }
      }

      .lead-investor-title {
        max-width: vw_d(210);
        font-size: vw_d(18);
        line-height: vw_d(28);
        letter-spacing: vw_d(0.45);
        margin-right: vw_d(20);
        margin-left: vw_d(17);
        cursor: pointer;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include screen('mobile') {
          max-width: 152px;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.4px;
          font-weight: 600;
          margin-right: 10px;
          margin-left: 5px;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.3px;
        }
      }

      .counter {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: vw_d(22.97);
        line-height: vw_d(28.5);
        letter-spacing: vw_d(0.58);
        font-weight: 600;

        padding: vw_d(6) vw_d(10) vw_d(5) vw_d(7);
        border-radius: vw_d(7);
        background: linear-gradient(180deg, #257bff 0%, #0757d3 100%);

        @include screen('mobile') {
          font-size: 18px;
          line-height: 28.5px;
          letter-spacing: 0.45px;

          padding: 6px 10px 5px 10px;
          border-radius: 7px;
        }
      }
    }

    .founders-wrap {
      min-width: vw_d(320);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include screen('mobile') {
        min-width: 100%;
        padding: 7px 0 0 3px;
      }

      .founders-slider {
        margin-top: 6px;
      }
    }

    &:first-child {
      align-items: flex-start;
    }

    &:last-child {
      align-items: flex-end;

      &::before {
        display: none;
      }
    }
  }
}

.green-dot {
  width: vw_d(11);
  height: vw_d(11);
  border-radius: 50%;
  background-color: #25e061;

  @include screen('mobile') {
    width: 10px;
    height: 10px;
    margin-right: 7px;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.icon {
  display: inline-block;

  svg {
    display: block;
  }
}

.close-btn {
  width: vw_d(27);
  height: vw_d(27);
  display: block;

  @include screen('mobile') {
    width: 14px;
    height: 14px;
  }

  &:hover {
    span {
      opacity: 0.8;
    }
  }

  span {
    position: absolute;
    width: vw_d(25);
    height: vw_d(4);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: white;

    @include screen('mobile') {
      width: 16px;
      height: 2.5px;
    }

    &:first-child {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

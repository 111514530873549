@import 'client/styles/mixins.scss';
@import 'client/styles/theme.scss';

.news {
  position: relative;

  &-item {
    &:last-of-type {
      border-bottom: none;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.container {
  width: 100%;

  &.is-error {
    .input-wrapper {
      input {
        box-shadow: inset 0 0 vw_d(2) rgba(252, 68, 79, 1);
        @include screen('mobile') {
          box-shadow: inset 0 0 0 1px rgba(252, 68, 79, 1);
        }
      }
    }
  }

  .input-wrapper {
    width: 100%;

    .input {
      position: relative;
    }

    .label {
      padding-bottom: vw_d(8);
      font-size: 13px;
      line-height: 1.231;
      letter-spacing: vw_d(-0.05);
      font-family: $inter;
      color: rgba(255, 255, 255, 0.87);
      text-align: left;

      @include screen('mobile') {
        font-size: 13px;
        padding-bottom: 8px;
      }
    }

    .form-input {
      display: block;
      width: 100%;
      height: vw_d(48);
      line-height: vw_d(44);
      padding: 0 vw_d(12);
      font-size: 16px;
      font-family: $inter;
      background-color: transparent;
      box-shadow: inset 0 0 vw_d(2) #fff;
      border: none;
      border-radius: vw_d(6);
      outline: none;
      color: rgba(255, 255, 255, 0.87);

      &.password {
        padding: 0 vw_d(50) 0 vw_d(12);

        @include screen('mobile') {
          padding: 14px 54px 14px 12px;
        }
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: rgba(255, 255, 255, 0.87);
      }

      @include screen('mobile') {
        height: 48px;
        line-height: 1;
        padding: 14px 44px 14px 12px;
        font-size: 16px;
        border-radius: 6px;
        box-shadow: inset 0 0 0 1px currentColor;
        appearance: none;
      }
    }

    .toggle-type-btn {
      position: absolute;
      top: 50%;
      right: vw_d(10);
      transform: translate(0, -50%);
      font-size: vw_d(13);
      font-family: $inter;
      color: rgba(255, 255, 255, 0.87);

      @include screen('mobile') {
        font-size: 13px;
        right: 10px;
      }
    }
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.text {
  padding-top: 17px;
  font-family: $regular-font;
  font-size: 16px;
  letter-spacing: 0.35px;
  line-height: 1.5;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

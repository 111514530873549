@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.slider {
  position: relative;
  min-height: vw_d(520);
  margin-top: vw_d(-43);
  margin-bottom: vw_d(110);
  z-index: 2;

  :global {
    .swiper-container {
      padding: 0 vw_d(50) 0 vw_d(55);
    }
    .swiper-wrapper {
      padding: vw_d(20) 0;
    }
  }

  .slider-wrapper {
    position: relative;
    .nav-btn {
      position: absolute;
      top: vw_d(0);
      bottom: vw_d(0);
      width: vw_d(155);
      background-color: red;
      z-index: 10;
      transition: all 0.3s;
      cursor: pointer;

      &.hide {
        opacity: 0;
        visibility: hidden;
      }

      &.next {
        right: 0;
        background: rgb(0, 0, 0);
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

        .arrow {
          position: absolute;
          width: vw_d(27);
          height: vw_d(46);
          top: 50%;
          left: 50%;
          transform: translate(0%, -50%);
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: vw_d(32);
            height: vw_d(6);
            background-color: #fff;
            border-radius: vw_d(3);
          }

          &:before {
            top: vw_d(11);
            transform: rotate(45deg);
          }

          &:after {
            top: vw_d(31);
            transform: rotate(-45deg);
          }
        }
      }
      &.prev {
        left: 0;
        background: rgb(0, 0, 0);
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

        .arrow {
          position: absolute;
          width: vw_d(27);
          height: vw_d(46);
          top: 50%;
          left: 35%;
          transform: translate(0%, -50%) rotate(180deg);
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: vw_d(32);
            height: vw_d(6);
            background-color: #fff;
            border-radius: vw_d(3);
          }

          &:before {
            top: vw_d(11);
            transform: rotate(45deg);
          }

          &:after {
            top: vw_d(31);
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: vw_d(198);
    left: 0;
    width: 100%;
    height: vw_d(167);
    background: linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 1) 100%);
    z-index: -1;
  }

  .title {
    font-size: vw_d(35);
    line-height: 1.143;
    letter-spacing: 0.3px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: vw_d(10);
    padding-left: vw_d(78);
  }

  .slide {
    &:hover {
      transform: scale(1.05);
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.thanks {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .icon-wrapper {
    width: vw_d(78.76);
    height: vw_d(78.76);
    margin-bottom: vw_d(34.12);

    @include screen('mobile') {
      width: 78.76px;
      height: 78.76px;
      margin-bottom: 34.12px;
    }

    .icon {
      width: 100%;
      height: 100%;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .text {
    width: vw_d(400);

    @include screen('mobile') {
      width: 312px;
    }
    p {
      font-size: vw_d(20);
      line-height: 1.6;
      font-family: $regular-font;
      font-weight: 400;
      text-align: center;
      color: #fff;
      @include screen('mobile') {
        font-size: 20px;
      }
      &:first-child {
        padding-bottom: vw_d(14.09);
        font-size: vw_d(36);
        letter-spacing: vw_d(0.7);
        line-height: 1.29;
        font-weight: 700;
        @include screen('mobile') {
          padding-bottom: 14.09px;
          font-size: 24px;
          letter-spacing: 0.7px;
        }
      }
    }
  }
}

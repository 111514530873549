@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.container {
  width: 100%;

  &.is-error {
    .text-area-wrapper {
      textarea {
        box-shadow: inset 0 0 vw_d(2) rgba(252, 68, 79, 1);
        @include screen('mobile') {
          box-shadow: inset 0 0 0 1px rgba(252, 68, 79, 1);
        }
      }
    }
  }

  .text-area-wrapper {
    .label {
      padding-bottom: vw_d(8);
      font-size: 13px;
      line-height: 1.231;
      letter-spacing: vw_d(-0.05);
      font-family: $inter;
      color: rgba(255, 255, 255, 0.87);
      text-align: left;

      @include screen('mobile') {
        font-size: 13px;
        padding-bottom: 8px;
      }
    }

    .form-text-area {
      display: block;
      width: 100%;
      height: vw_d(88);
      line-height: 1.25;
      padding: vw_d(10) vw_d(12);
      font-size: 16px;
      font-family: $inter;
      letter-spacing: vw_d(-0.18);
      background-color: transparent;
      box-shadow: inset 0 0 vw_d(2) #fff;
      border: none;
      border-radius: vw_d(6);
      outline: none;
      color: rgba(255, 255, 255, 0.87);
      resize: none;
      &::placeholder {
        color: rgba(255, 255, 255, 0.32);
      }
      @include screen('mobile') {
        height: 88px;
        padding: 10px 12px;
        border-radius: 6px;
        box-shadow: inset 0 0 0 1px currentColor;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.form-wrapper {
  position: relative;
  z-index: 2;
  padding: 0 vw_d(14);

  @include screen('mobile') {
    padding: 0;
  }

  .field-wrapper {
    margin-bottom: vw_d(11);
    @include screen('mobile') {
      margin-bottom: 11px;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: vw_d(29);
    @include screen('mobile') {
      padding-top: 29px;
    }
    .submit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: vw_d(60);
      width: vw_d(339);
      font-size: vw_d(20.09);
      font-family: $regular-font;
      font-weight: 700;
      border-radius: vw_d(30);
      background-color: #0757d3;
      @include screen('mobile') {
        width: 100%;
        height: 60px;
        font-size: 20.09px;
        border-radius: 30px;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.item {
  width: vw_d(440);
  position: relative;

  @include screen('mobile') {
    width: 100%;
    max-width: 365px;
    padding-bottom: 22px;
    margin-bottom: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(white, 0.28);
    }
  }

  @include screen('mobile-large') {
    max-width: inherit;
  }

  .content {
    display: block;
    margin-right: vw_d(67);

    @include screen('mobile') {
      margin: 0;
      width: 253px;
    }

    @include screen('mobile-large') {
      width: 90%;
    }
  }

  .main-image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: vw_d(210);
    border-radius: vw_d(8);
    margin-bottom: vw_d(20);

    @include screen('mobile') {
      border-radius: 4px;
      height: auto;
      padding-bottom: 56%;
      margin-bottom: 12px;
    }
  }

  .info {
    .name {
      margin-bottom: vw_d(10.34);
      font-size: vw_d(28.62);
      letter-spacing: vw_d(4.58);
      line-height: 1.1;
      font-family: $regular-font;
      font-weight: 600;
      color: white;
      margin-bottom: vw_d(5);

      @include screen('mobile') {
        font-size: 18px;
        margin-bottom: 12px;
        letter-spacing: normal;
      }
    }

    .description {
      font-size: vw_d(14);
      line-height: 1.1;
      font-family: $regular-font;
      font-weight: 300;
      color: white;

      @include screen('mobile') {
        font-size: 14px;
      }
    }
  }

  .delete-btn {
    position: absolute;
    top: 10px;
    right: 8px;
    width: vw_d(38);
    height: vw_d(38);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transition: 0.2s opacity ease-in;

    @include screen('mobile') {
      width: 34px;
      height: 34px;
    }

    .icon {
      display: block;
      width: 50%;
      height: 50%;

      svg {
        display: block;
        width: 100% !important;
        height: 100% !important;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.saving-list {
  padding-top: calc(#{$header-height-mobile} + 50px);
  padding-bottom: calc(#{$footer-height-mobile} + 40px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.header {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
}

.control-panel {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  right: 0;
}

.title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1;
}

.items {
  padding-top: 40px;
}

.no-items {
  position: relative;
  padding: 0 15px;
  @include screen('mobile-large') {
    max-width: 400px;
    margin: 0 auto;
  }
  .title {
    font-size: 35px;
    line-height: 1;
    letter-spacing: 3px;
    text-align: center;
    padding: 40px 0 30px;
    margin: 0 -20px;
  }

  .message {
    max-width: 100%;
    font-size: 18px;
    letter-spacing: 0.35px;
    line-height: 1.389;
    text-align: center;
    margin-bottom: 40px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    border-radius: 33px;
    background-color: $primary-color;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.user-profile,
.investor-profile {
  min-height: 100vh;
  position: relative;

  .blur-section {
    position: absolute;
    opacity: 0.4;
    left: 0;
    top: -50%;
    right: 0;
    z-index: 1;
    height: 140vh;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(86px);
    }
    &:after {
      content: '';
      position: absolute;
      height: vw_d(519);
      width: 100%;
      left: 0;
      bottom: 0;
      transform: translateY(vw_d(10));
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .edit-content {
    position: relative;
    z-index: 2;
    padding: vw_d(200) 0 vw_d(102) vw_d(20);
    margin: 0 auto;
    width: vw_d(1080);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      width: vw_d(623);
      padding-bottom: vw_d(13);

      .info-item {
        font-family: $regular-font;
        font-size: vw_d(45);
        color: #ffffff;
        letter-spacing: 0.87px;
        line-height: 1.8;
        padding: vw_d(34) vw_d(57) vw_d(30) vw_d(40);
        border-bottom: vw_d(1.5) solid rgba(255, 255, 255, 0.28);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .welcome-msg {
    font-size: vw_d(45);
    letter-spacing: vw_d(0.85);
    font-weight: 400;
    line-height: 1;
    padding-bottom: 13px;
  }

  .user-name {
    font-size: vw_d(45);
    font-weight: 600;
    line-height: 1;
    text-transform: capitalize;
    padding-bottom: 25px;
  }

  .edit-name-btn {
    width: vw_d(28);
    height: vw_d(28);
    margin-left: vw_d(5);
    opacity: 0.68;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .edit-info-btn {
    text-transform: uppercase;
    font-family: $regular-font;
    font-weight: 400;
    font-size: vw_d(22);
    color: #ffffff;
  }

  .favorite-list {
    position: relative;
    z-index: 2;
    padding: vw_d(84) vw_d(78) vw_d(150);

    &.is-busy {
      pointer-events: none;
    }

    .title {
      font-size: vw_d(35);
      line-height: 1.1;
      font-family: $regular-font;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      padding-top: vw_d(70);
      column-gap: vw_d(112);
      row-gap: vw_d(74);
    }
  }

  .divider {
    width: 100%;
    height: vw_d(1.5);
    background-color: rgba(255, 255, 255, 0.28);
  }
}

.investor-profile {
  display: flex;
  flex-direction: column;
  background-color: #111;

  .blur-section {
    opacity: 0.2;
    top: 0;
    height: 28vw;
  }

  .edit-content {
    padding: vw_d(165) vw_d(198) vw_d(70);
    width: 100%;
    gap: vw_d(81);

    .info {
      width: auto;
      margin-top: 12px;

      .info-item {
        display: block;
        padding: 0;
        border-bottom: 0;
      }
    }
  }

  .edit-section {
    display: flex;
    align-items: center;

    .btns-separator {
      font-size: vw_d(16);
      letter-spacing: vw_d(0.85);
      line-height: 1.375;
      color: rgba(white, 0.68);
      padding: 0 vw_d(5);
    }
  }

  .edit-info-btn {
    text-decoration: underline;
    font-size: vw_d(16);
    letter-spacing: vw_d(0.85);
    line-height: 1.375;
    color: rgba(white, 0.68);
  }

  .inner-pages {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    flex-grow: 1;
  }

  .content-wrapper {
    margin: 0 vw_d(199);
  }

  .header {
    position: relative;
    padding: vw_d(164) 0 vw_d(74);
    z-index: 3;

    .content-wrap {
      display: flex;
      align-items: center;
      gap: vw_d(81);
    }
  }

  .avatar {
    width: vw_d(231);
    height: vw_d(231);

    i {
      width: vw_d(48) !important;
      height: vw_d(48) !important;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

$transitionDuration: 350ms;
@value transitionDuration: #{$transitionDuration};

.investor-details {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, 0.75);
    backdrop-filter: blur(vw_d(10));
  }

  &-pop-up {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    width: vw_d(740);
    min-height: vw_d(500);
    max-height: 80vh;
    padding: vw_d(23) vw_d(13);
    border-radius: vw_d(12);
    background-color: rgba(#000000, 0.75);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transform: translate(-50%, -50%) scale(1);

    @include screen('mobile') {
      top: 77px;
      width: 87.5%;
      min-height: calc(90vh - 65px - 77px); // - footer - padding-top
      max-height: calc(90vh - 65px - 77px);
      padding: 25px 11px;
      border-radius: 12px;
      border-color: rgb(151, 151, 151);
      transform: translate(-50%, 0)  scale(1);
    }

    .header {
      position: absolute;
      display: flex;
      gap: vw_d(25);
      align-items: center;
      left: vw_d(50);
      right: vw_d(50);
      top: vw_d(75);

      @include screen('mobile') {
        gap: 13px;
        top: 25px;
        right: 11px;
        left: 10px;
      }

      .logo {
        width: vw_d(82);
        height: vw_d(82);
        border-radius: vw_d(11);
        background-color: #ffffff;
        background-size: contain;
        background-position: center;

        @include screen('mobile') {
          width: 57px;
          min-width: 57px;
          height: 57px;
          border-radius: 7px;
        }
      }

      .title {
        font-size: vw_d(35);
        line-height: vw_d(41);
        letter-spacing: vw_d(0.9);
        font-weight: 600;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include screen('mobile') {
          font-size: 24px;
          line-height: 1.477;
          letter-spacing: 0.47px;
          font-weight: 700;
        }
      }
    }

    &-content {
      position: relative;
      flex-grow: 1;
      padding: 0 vw_d(37);
      overflow: auto;

      @include screen('mobile') {
        padding: 0;
      }

      .description {
        padding-top: vw_d(180);
        font-size: vw_d(16);
        line-height: vw_d(27);

        @include screen('mobile') {
          font-size: 16px;
          line-height: 1.563;
          padding-top: 81px;
        }

        h2 {
          margin-top: vw_d(50);

          @include screen('mobile') {
            font-size: 16px;
            line-height: 1.563;
            font-weight: 600;
            margin-top: 25px;
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }

      mask-image: linear-gradient(180deg, transparent vw_d(120), white vw_d(180), white 100%),
        linear-gradient(90deg, transparent calc(100% - vw_d(20)), white calc(100% - vw_d(20)));

      @include screen('mobile') {
        mask-image: linear-gradient(180deg, transparent 50px, white 107px, white 100%),
        linear-gradient(90deg, transparent 100%, white 100%);
      }

      &::before {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, transparent vw_d(120), white vw_d(180), white 100%),
          linear-gradient(90deg, transparent calc(100% - vw_d(20)), white calc(100% - vw_d(20)));
      }
    }
  }

  .close-btn {
    position: absolute;
    z-index: 10;
    display: block;
    width: vw_d(20);
    height: vw_d(20);
    left: vw_d(25);
    top: vw_d(25);

    @include screen('mobile') {
      position: fixed;
      width: 21px;
      height: 21px;
      left: 25px;
      top: 25px;
      z-index: 10;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 2px;
        background-color: #fff;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.enter,
  &.appear {
    .investor-details-bg {
      opacity: 0;
    }

    .investor-details-pop-up {
      opacity: 0;
      transform: translate(-50%, -50%);

      @include screen('mobile') {
        transform: translate(-50%, 0);
      }
    }

    &-active {
      .investor-details-bg {
        opacity: 1;
        transition: opacity $transitionDuration ease;
      }

      .investor-details-pop-up {
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: opacity $transitionDuration ease, transform $transitionDuration ease;

        @include screen('mobile') {
          transform: translate(-50%, 0);
        }
      }
    }
  }

  &.exit {
    .investor-details-bg {
      opacity: 1;
    }

    .investor-details-pop-up {
      transform: translate(-50%, -50%);
      opacity: 1;

      @include screen('mobile') {
        transform: translate(-50%, 0);
      }
    }

    &-active {
      .investor-details-bg {
        opacity: 0;
        transition: opacity $transitionDuration ease;
      }

      .investor-details-pop-up {
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity $transitionDuration ease, transform $transitionDuration ease;

        @include screen('mobile') {
          transform: translate(-50%, 0);
        }
      }
    }

    &-done {
      .investor-details-bg {
        opacity: 0;
      }

      .investor-details-pop-up {
        opacity: 0;
        transform: translate(-50%, -50%);

        @include screen('mobile') {
          transform: translate(-50%, 0);
        }
      }
    }
  }
}

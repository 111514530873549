@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.page-wrapper {
  min-height: 100vh;
  padding-top: vw_d(133);
  position: relative;

  .page-header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
  }

  .control-panel {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
  }

  @include screen('mobile') {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    min-height: auto;
    padding-top: calc(#{$header-height-mobile} + 29px);
    padding-bottom: calc(#{$footer-height-mobile} + 40px);
  }

  .content-wrapper {
    padding: 0 vw_d(42);

    @include screen('mobile') {
      width: 100%;
      padding-left: 20px; //$content-wrap-margin;
      padding-right: 20px; // $content-wrap-margin;
    }
    section {
      .main-title {
        font-family: $regular-font;
        // font-size: vw_d(110);
        font-size: clamp(66px, 15.32px + 4.9162vw, 110px);
        font-weight: 800;
        line-height: 1;
        letter-spacing: vw_d(5);
        text-transform: uppercase;
        text-align: left;
        padding-bottom: vw_d(36);

        @include screen('mobile') {
          font-size: 40px;
          letter-spacing: 3px;
          padding-bottom: 20px;
          text-align: center;
        }
      }

      .secondary-title {
        padding-bottom: vw_d(36);
        // font-size: vw_d(66);
        // line-height: vw_d(100);
        font-size: clamp(60px, 53.1px + 0.6704vw, 66px);
        line-height: 1.515;
        font-weight: 800;
        letter-spacing: vw_d(5);
        text-transform: uppercase;

        @include screen('mobile') {
          padding-bottom: 18px;
          font-size: 32px;
          line-height: 39px;
          font-weight: 700;
          letter-spacing: 3px;
          text-align: center;
        }
      }

      p {
        font-family: $regular-font;
        // font-size: vw_d(18);
        // line-height: vw_d(32);
        font-size: clamp(14px, 9.4px + 0.4469vw, 18px); 
        line-height: 1.6;
        font-weight: 500;

        @include screen('mobile') {
          font-size: 16px;
          line-height: 22px;
        }

        strong {
          font-weight: 700;
        }
      }

      &.about {
        position: relative;
        height: calc(100vh - #{vw_d(133)} - #{vw_d(42)});
        padding-left: vw_d(158);
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: cover;
        background-position: 100% 0;
        background-repeat: no-repeat;
        background-color: #070b10;
        border-radius: vw_d(40);

        @include screen('mobile') {
          height: calc(94vh - #{$header-height-mobile} - #{$footer-height-mobile} - 60px);
          padding-left: 0;
          border-radius: 24px;
          background-position: center;
        }

        .content {
          max-width: vw_d(740);

          @include screen('mobile') {
            max-width: 100%;
            padding: 0 20px;
            text-align: center;
          }

          @media only screen and (min-width: 540px) and (max-width: 767px) {
            width: 60%;
            margin: auto;
          }
        }

        .scroll-down-btn {
          position: absolute;
          left: 50%;
          bottom: vw_d(54);
          transform: translateX(-50%);

          @include screen('mobile') {
            bottom: 24px;
          }
        }
      }

      &.story {
        position: relative;
        padding-top: vw_d(207);
        padding-left: vw_d(158);

        @include screen('mobile') {
          padding: 91px 0 0;
          text-align: center;
          margin: 0 -20px;
        }

        .content {
          max-width: vw_d(740);

          @include screen('mobile') {
            max-width: 100%;
            // padding: 0 40px 386px;
            padding: 0 40px 0;
          }

          @media only screen and (min-width: 540px) and (max-width: 767px) {
            width: 85%;
            margin: auto;
          }

          p {
            padding-bottom: vw_d(31);

            @include screen('mobile') {
              padding-bottom: 21px;
            }
          }
        }

        .story-bg-image {
          width: vw_d(1359);
          height: vw_d(995);
          position: absolute;
          top: vw_d(38);
          right: vw_d(-42);

          @include screen('mobile') {
            margin-top: -17px;
            width: 100%;
            height: auto;
            position: static;
            // top: auto;
            top: 450px;
            right: 0;
            // aspect-ratio: 1 / 2.2;
            z-index: -1;

            // z-index: -1;
            // position: relative;
            // top: 0;
            // bottom: 0;
          }

          @media only screen and (min-width: 540px) and (max-width: 767px) {
            display: none;
          }
        }

        .story-bg-image-fold {
          display: none;
          @media only screen and (min-width: 540px) and (max-width: 767px) {
            display: block;
            width: 100%;
          }
        }
      }

      &.why-us {
        padding: vw_d(145) vw_d(158) 0;
        @include screen('mobile') {
          padding: 0;
          margin-top: -188px;
          z-index: 1;
          position: relative;
        }

        @media only screen and (min-width: 540px) and (max-width: 767px) {
          width: 85%;
          margin-left: auto;
          margin-right: auto;
          margin-top: -130px;
        }

        .secondary-title {
          padding-bottom: vw_d(44);

          @include screen('mobile') {
            padding-bottom: 52px;
          }
        }

        .content {
          display: flex;
          column-gap: vw_d(151);
          padding: vw_d(60) vw_d(60) vw_d(28);
          background-color: $primary-color;
          border-radius: vw_d(40);

          @include screen('mobile') {
            display: block;
            padding: 26px 26px 32px 24px;
            border-radius: 24px;
          }

          .text {
            max-width: vw_d(719);

            @include screen('mobile') {
              max-width: 100%;
              padding-bottom: 17px;
            }

            p {
              padding-bottom: vw_d(32);

              @include screen('mobile') {
                padding-bottom: 22px;
              }
            }
          }

          .other {
            display: flex;
            flex-direction: column;
            row-gap: vw_d(65);

            @include screen('mobile') {
              display: block;
            }
          }

          .quote {
            padding: vw_d(40);
            background-color: #1e1e1e;
            border-radius: vw_d(40);

            @include screen('mobile') {
              padding: 24px;
              border-radius: 24px;
              margin-bottom: 32px;
            }

            p {
              font-weight: 600;
              padding-bottom: vw_d(125);

              @include screen('mobile') {
                padding-bottom: 83px;
              }
            }

            .quote-author {
              font-size: vw_d(20);
              line-height: vw_d(32);
              font-weight: 800;
              text-align: right;
              text-transform: uppercase;
              white-space: nowrap;

              @include screen('mobile') {
                font-size: 18px;
                line-height: 28px;
                white-space: normal;
              }
            }
          }

          .logos {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .cukierman-logo {
              width: vw_d(272);

              @include screen('mobile') {
                width: 51%;
              }
            }

            .catalist-logo {
              width: vw_d(201);

              @include screen('mobile') {
                width: 38%;
              }
            }
          }
        }
      }

      &.values {
        margin: 0 vw_d(-42);
        padding: vw_d(175) vw_d(200);
        background-position: 0% 32%;
        background-repeat: no-repeat;
        background-size: cover;

        @include screen('mobile') {
          position: relative;
          padding: 91px 0 52px;
          margin: 0 -20px;
          background-position: 0% 51%;
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 50px;
            background: rgb(255, 255, 255);
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
          }
          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 50px;
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
          }
        }

        .list {
          display: grid;
          grid-template-columns: repeat(4, vw_d(350));
          grid-column-gap: vw_d(40);
          padding-top: vw_d(7);

          @include screen('mobile') {
            display: block;
            padding: 52px 20px 0;
          }

          @media only screen and (min-width: 540px) and (max-width: 767px) {
            width: 85%;
            margin: auto;
          }

          &-item {
            padding: vw_d(60);
            filter: saturate(70%);
            backdrop-filter: blur(24px);
            border-radius: vw_d(40);
            border: 1px solid rgba(255, 255, 255, 0.05);

            @include screen('mobile') {
              padding: 24px 20px 30px 24px;
              border-radius: 24px;
              margin-bottom: 40px;
            }

            .title {
              margin: 0;
              padding-bottom: vw_d(24);
              font-size: vw_d(24);
              line-height: vw_d(32);
              font-weight: 800;
              text-transform: uppercase;

              @include screen('mobile') {
                font-size: 24px;
                line-height: 32px;
                padding-bottom: 24px;
              }
            }
          }
        }
      }

      &.team,
      &.board {
        padding: 0 vw_d(158) vw_d(175);

        @include screen('mobile') {
          padding: 0 0 52px;
        }

        .secondary-title {
          padding-bottom: vw_d(44);

          @include screen('mobile') {
            padding-bottom: 52px;
          }
        }

        .grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(auto, 1fr);
          grid-column-gap: vw_d(40);
          grid-row-gap: vw_d(40);

          @include screen('mobile') {
            display: block;
          }

          @media only screen and (min-width: 540px) and (max-width: 767px) {
            display: grid;
            grid-template-columns: repeat(2, 48%);
            grid-template-rows: repeat(auto, 1fr);
            grid-column-gap: 4%;
            grid-row-gap: 20px;
          }

          &-item {
            @include screen('mobile') {
              margin-bottom: 45px;
            }
          }

          .photo {
            width: 100%;
            min-height: vw_d(424);
            border-radius: vw_d(40);
            overflow: hidden;

            @include screen('mobile') {
              min-height: 472px;
              border-radius: 24px;
            }

            img {
              width: inherit;
              min-height: inherit;
              object-fit: cover;
            }
          }

          .name {
            padding: vw_d(22) vw_d(4) vw_d(12);
            font-size: vw_d(26);
            line-height: vw_d(32);
            font-weight: 800;
            text-transform: uppercase;

            @include screen('mobile') {
              padding: 23px 3px 17px;
              font-size: 26px;
              line-height: 32px;
            }
          }

          .position {
            font-weight: 600;
            padding: 0 vw_d(4);

            @include screen('mobile') {
              padding: 0 3px;
            }
          }
        }
      }
    }
  }
}

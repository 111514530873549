@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

$white: #fff;
$gray: #d3d3d3;
$bgSize: 400%;

.envelope-wrapper {
  position: absolute;
  width: vw_d(1261);
  top: 190px;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 20;

  @include screen('mobile') {
    display: block;
    overflow-y: auto;
    width: 100%;
    top: 0;
    bottom: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .envelope-content {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    height: 100%;

    &.visible {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }

    iframe {
      display: block;
      min-height: 200px !important;
    }
  }

  .content-wrapper {
    width: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    @include screen('mobile') {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .loaderWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include screen('mobile') {
        width: 100%;
      }

      .text {
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        font-weight: 400;
        background: linear-gradient(90deg, $white, $gray, $white) 0 0 / ($bgSize 100%);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        animation: moveBg 8s infinite linear;

        @include screen('mobile') {
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }

      .loader {
        position: relative;
        width: 160px;
        @include screen('mobile') {
          width: 140px;
        }
      }
    }
  }
}

@keyframes moveBg {
  to {
    background-position: $bgSize 0;
  }
}

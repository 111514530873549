@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  // default sm:
  font-size: vw_d(14);
  line-height: 1.56;
  letter-spacing: 0.27px;
  border-radius: vw_d(22);

  .iconEye {
    display: inline-block;
    // default sm:
    width: vw_d(35);
    height: vw_d(30);
    margin-right: vw_d(14);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .iconPlay {
    margin-right: vw_d(10);
  }

  // types:
  &.seeMore {
    background-color: $primary-color;
    padding: vw_d(8) vw_d(25) vw_d(8) vw_d(10);
    transition: 0.3s;

    &:hover {
      background-color: $primary-hover;
    }
  }

  &.watch {
    font-size: vw_d(16);
    letter-spacing: 2.47px;
    background-color: #212223;
    padding: vw_d(5) vw_d(20) vw_d(5) vw_d(5);
  }

  // sizes:
  &.l {
    font-size: vw_d(20);
    letter-spacing: 0.39px;
    border-radius: vw_d(28);
    padding: vw_d(12) vw_d(25) vw_d(12) vw_d(16);

    .iconPlay {
      margin-right: vw_d(13);
    }
  }

  &.m {
    font-size: vw_d(20);
    letter-spacing: 3.21px;
    border-radius: vw_d(27);
    padding: vw_d(6) vw_d(30) vw_d(6) vw_d(6);

    .iconPlay {
      margin-right: vw_d(16);
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.donut-chart-wrap {
  height: 100%;
  overflow: hidden;

  .tooltip {
    position: fixed;
    z-index: 10;
    width: fit-content;
    padding: vw_d(10);
    background-color: rgba(0, 0, 0, 1);
    border-radius: vw_d(4);
    font-size: vw_d(14);
    line-height: vw_d(18);
    letter-spacing: vw_d(0.1);
    pointer-events: none;
    display: none;
    transition: all 0.2s ease;

    @include screen('mobile') {
      // width: 150px;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 18px;
      border-radius: 4px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 100%;
      width: 1px;
      height: 1px;
      border: vw_d(10) solid transparent;
      border-top-color: rgba(0, 0, 0, 1);
      transform: translate(vw_d(-5), 0);

      @include screen('mobile') {
        border: 6px solid transparent;
        border-top-color: rgba(0, 0, 0, 1);
        transform: translate(-3px, 0);
      }
    }
  }
}

.donut-chart {
  position: relative;
  text-align: center;

  svg {
    width: auto;
    height: 100%;

    path {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

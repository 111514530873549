@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.hero {
  position: relative;
  text-align: center;
  padding-top: 76px + 20px;
  margin-bottom: 54px;
}

.heading {
  font-size: 36px;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 8px;

  @include screen('mobile-extra-small') {
    font-size: 32px;
    line-height: 1.2;
  }
}

.sub-heading {
  font-size: 16px;
  line-height: 1.35;
  letter-spacing: -0.1px;
  margin-bottom: 40px;

  @include screen('mobile-extra-small') {
    font-size: 14px;
  }
}

.join-btn {
  min-width: 160px !important;
  padding: 0 26px !important;
  margin: 0 auto;
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.user {
  position: relative;
  display: flex;
  padding-left: 25px;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 20px;
  }

  .name {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4384;
  }

  .logout-btn {
    text-decoration: underline;
    font-size: 16px;
    line-height: 1.4384;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    object-fit: cover;
  }
}

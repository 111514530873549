@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.page-wrapper {
  min-height: 100vh;
  padding-top: vw_d(133);
  position: relative;

  .page-header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
  }

  .control-panel {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
  }

  @include screen('mobile') {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    min-height: auto;
    padding-top: calc(#{$header-height-mobile} + 29px);
    padding-bottom: $footer-height-mobile;
  }
  .content {
    position: relative;
  }

  .cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    @include screen('mobile') {
      background-position: 0% 0%;
    }
  }

  .content-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: vw_d(1159);
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: vw_d(98);
    z-index: 2;

    @include screen('mobile') {
      width: 100%;
      flex-wrap: wrap;
      padding-top: 0;
      padding-bottom: 83.5px;
      padding-left: 37px;
      padding-right: 37px;
    }

    .col-left {
      display: flex;
      flex-direction: column;
      width: vw_d(580);
      @include screen('mobile') {
        position: relative;
        width: 100%;
        padding-bottom: 41px;
        z-index: 2;
      }
      .text {
        padding-left: vw_d(3);
        @include screen('mobile') {
          padding: 0 14px;
        }
        .title {
          padding-bottom: vw_d(24);
          font-family: $regular-font;
          font-size: vw_d(60);
          font-weight: 700;
          line-height: 1.333;
          @include screen('mobile') {
            padding-bottom: 14.35px;
            font-size: 40px;
            line-height: 1.35;
          }
        }

        .sub-title {
          width: vw_d(490);
          padding-bottom: vw_d(50);
          font-family: $regular-font;
          font-size: vw_d(24);
          font-weight: 600;
          line-height: 1.417;
          @include screen('mobile') {
            width: 100%;
            padding-bottom: 45px;
            font-size: 16px;
            line-height: 1.438;
            font-weight: 400;
          }
        }
      }

      .form-wrapper {
        position: relative;
        padding: vw_d(40) vw_d(93) vw_d(40) vw_d(94);
        border-radius: vw_d(12);
        min-height: vw_d(709);
        overflow: hidden;
        flex-grow: 1;
        @include screen('mobile') {
          min-height: 655px;
          padding: 18.46px 14px 40px;
        }
        .gradient-block {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0.23;
          background-image: linear-gradient(180deg, rgba(31, 63, 147, 1) 0%, rgba(69, 83, 153, 1) 100%);
          z-index: 1;
        }
        .title {
          position: relative;
          padding-bottom: vw_d(26.54);
          font-family: $regular-font;
          font-size: vw_d(36);
          letter-spacing: vw_d(0.7);
          font-weight: 700;
          line-height: 1.624;
          z-index: 2;
          @include screen('mobile') {
            padding-bottom: 24.54px;
            padding-left: 16px;
            font-size: 24px;
            letter-spacing: 0.47px;
            line-height: 1.167;
          }
        }
      }
    }

    .col-right {
      width: vw_d(529);
      display: flex;
      flex-direction: column;

      @include screen('mobile') {
        width: 100%;
        position: relative;
        z-index: 1;
      }

      .additional-info {
        position: relative;
        flex-grow: 1;
        padding: vw_d(36.55) vw_d(78) vw_d(103.45) vw_d(68);
        margin-top: vw_d(50);
        border-radius: vw_d(12);
        overflow: hidden;

        @include screen('mobile') {
          padding: 40.01px 14px 39.45px;
          margin-top: 0;
          border-radius: 0 0 12px 12px;
        }

        .gradient-block {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0.23;
          background-image: linear-gradient(180deg, rgba(31, 63, 147, 1) 0%, rgba(69, 83, 153, 1) 100%);
          z-index: 1;
        }

        .wrapper {
          position: relative;
          z-index: 2;
          .phone {
            padding-bottom: vw_d(60);
            @include screen('mobile') {
              padding-bottom: 50.46px;
            }
            .title {
              padding-bottom: vw_d(26.54);
              font-family: $regular-font;
              font-size: vw_d(36);
              font-weight: 700;
              letter-spacing: vw_d(0.7);
              line-height: 1.624;
              @include screen('mobile') {
                padding-bottom: 13.54px;
                font-size: 22px;
                letter-spacing: 0.43px;
                line-height: 1;
              }
            }
            .text {
              padding-bottom: vw_d(23);
              font-family: $regular-font;
              font-size: vw_d(20);
              font-weight: 400;
              line-height: 1.6;
              @include screen('mobile') {
                padding-bottom: 24px;
                font-size: 16px;
                line-height: 2;
              }
            }
            .contact-phone {
              font-family: $regular-font;
              font-size: vw_d(20);
              font-weight: 500;
              line-height: 1.6;
              @include screen('mobile') {
                font-size: 16px;
                line-height: 2;
              }
              a {
                display: flex;
                align-items: center;
                padding-bottom: vw_d(20);

                &:last-child {
                  padding-bottom: 0;
                }

                .icon-phone {
                  margin-right: vw_d(14);
                  @include screen('mobile') {
                    margin-right: 14px;
                  }
                }
              }
            }
          }
          .address {
            .title {
              padding-bottom: vw_d(35.54);
              font-family: $regular-font;
              font-size: vw_d(36);
              font-weight: 700;
              letter-spacing: vw_d(0.7);
              line-height: 1.624;
              @include screen('mobile') {
                padding-bottom: 23px;
                font-size: 22px;
                letter-spacing: 0.43px;
                line-height: 1;
              }
            }
            .text {
              display: flex;
              align-items: flex-start;
              font-family: $regular-font;
              font-size: vw_d(20);
              font-weight: 500;
              line-height: 1.6;

              @include screen('mobile') {
                font-size: 16px;
                line-height: 1.813;
              }

              .icon-location {
                margin-right: vw_d(14);
                @include screen('mobile') {
                  margin-right: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.confirmation {
	&-dialog {
		position: fixed;
		z-index: 9999;
		display: grid;
		place-content: center;
	}
	
	&-dialog,
	&-overlay {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	
	&-overlay {
		position: absolute;
		background-color: rgba(black, 0.6);
	}
	
	&-content {
		position: relative;
		width: 200px;
		background: black;
		border: 1px solid rgba(white, 0.28);
		padding: 12px 16px;
		border-radius: 6px;
		display: grid;
		grid-gap: 16px;
	}
	
	&-buttons {
		display: flex;
		justify-content: space-between;

		button {
			transition: opacity 200ms ease;
			
			&:hover {
				opacity: 0.8;
			}
		}
	}
	
}


@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.checkbox {
  position: relative;
  cursor: pointer;
  line-height: 1;

  &.with-error {
    .check-mark {
      box-shadow: inset 0 0 vw_d(2) rgba(252, 68, 79, 1);
      @include screen('mobile') {
        box-shadow: inset 0 0 0 1px rgba(252, 68, 79, 1);
      }
    }
  }

  .main {
    display: flex;
    column-gap: vw_d(15);
    align-items: center;

    @include screen('mobile') {
      column-gap: 18px;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ .check-mark {
      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    position: relative;
    display: block;
    height: vw_d(27);
    width: vw_d(27);
    flex-shrink: 0;
    background-color: transparent;
    border: none;
    border-radius: vw_d(6);
    box-shadow: inset 0 0 vw_d(2) #fff;

    @include screen('mobile') {
      width: 27px;
      height: 27px;
      border-radius: 6px;
      box-shadow: inset 0 0 2px #fff;
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 40%;
      top: 20%;
      transform: translate(-50%, -50%);
      width: vw_d(5);
      height: vw_d(14);
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @include screen('mobile') {
        width: 5px;
        height: 14px;
      }
    }
  }

  .label-text {
    font-size: 14px;
    line-height: 1.286;
    font-family: $regular-font;
    color: #fff;

    @include screen('mobile') {
      font-size: 14px;
    }

    a {
      color: #68a4ff;
      text-decoration: underline;
    }
  }

  .check-box-error {
    padding-top: vw_d(5);

    @include screen('mobile') {
      padding-top: 4px;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: vw_d(580);
  text-align: center;
  border: 1px solid #979797;
  border-radius: vw_d(12);
  background-color: rgba(0, 0, 0, 0.5);

  @include screen('mobile') {
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    width: 100%;
    text-align: center;
    border: none;
    border-radius: 0;
  }

  .title {
    font-size: vw_d(50);
    line-height: 1.169;
    font-weight: 700;
    letter-spacing: 0.970572px;
    text-transform: uppercase;
    margin: 0;

    @include screen('mobile') {
      font-size: 50px;
    }

    @include screen('mobile-small') {
      font-size: 40px;
    }

    @include screen('mobile-extra-small') {
      font-size: 35px;
    }
  }

  .info {
    font-size: vw_d(20);
    line-height: 1.566;
    letter-spacing: 0.39px;

    @include screen('mobile') {
      font-size: 20px;
    }

    @include screen('mobile-extra-small') {
      font-size: 18px;
    }
  }

  .close-button {
    position: absolute;
    top: vw_d(19);
    left: vw_d(16);
    width: vw_d(40);
    height: vw_d(40);
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;

    svg {
      width: vw_d(20);
      height: vw_d(20);
    }

    @include screen('desktop') {
      &:hover {
        opacity: 0.8;
      }
    }

    @include screen('mobile') {
      position: fixed;
      top: 21px;
      left: 23px;
      width: 40px;
      height: 40px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.login-success {
  padding: vw_d(137) vw_d(87) vw_d(116);

  @include screen('mobile') {
    padding: 0;
  }

  .avatar {
    width: vw_d(98);
    height: vw_d(98);
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background-color: #767676;

    @include screen('mobile') {
      width: 98px;
      height: 98px;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .name {
    font-size: vw_d(50);
    line-height: 1.169;
    font-weight: 700;
    letter-spacing: 0.970572px;
    text-align: center;
    text-transform: uppercase;
    margin: vw_d(24) vw_d(-50) vw_d(14);

    @include screen('mobile') {
      font-size: 50px;
      margin: 24px 0 14px;
    }

    @include screen('mobile-small') {
      font-size: 40px;
    }

    @include screen('mobile-extra-small') {
      font-size: 35px;
    }
  }

  .greeting-message {
    font-size: vw_d(20);
    margin-bottom: vw_d(92);

    @include screen('mobile') {
      font-size: 20px;
      margin-bottom: 92px;
    }

    @include screen('mobile-extra-small') {
      font-size: 18px;
    }
  }

  .start-button {
    width: vw_d(339);
    height: vw_d(60);
    font-size: vw_d(20);
    font-weight: 700;
    letter-spacing: 0.4px;
    border-radius: vw_d(30);
    margin-bottom: vw_d(30);
    background-color: rgb(11, 102, 194);

    @include screen('mobile') {
      max-width: 339px;
      width: 100%;
      height: 60px;
      font-size: 20px;
      border-radius: 30px;
      margin-bottom: 30px;
    }

    @include screen('mobile-extra-small') {
      font-size: 18px;
      height: 50px;
    }
  }
}

.email-send-success {
  padding: vw_d(80) vw_d(93) vw_d(20);

  @include screen('mobile') {
    padding: 0;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .info {
    padding: vw_d(115) 0 vw_d(150);

    @include screen('mobile') {
      padding: 32% 0 41%;
    }
  }

  .bottom {
    @include screen('mobile') {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
    }

    .text {
      font-size: vw_d(14);
      line-height: 1.571;
      letter-spacing: 0.27px;

      @include screen('mobile') {
        font-size: 14px;
      }
    }
  }

  .try-again-action {
    text-decoration: underline;
    color: $light-blue;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.reset-password-success {
  padding: vw_d(80) vw_d(93) vw_d(85);

  @include screen('mobile') {
    padding: 0;
  }

  .info {
    padding: vw_d(85) vw_d(25) vw_d(95);

    @include screen('mobile') {
      padding: 85px 25px 95px;
    }
  }

  .button {
    width: vw_d(340);
    height: vw_d(60);
    font-family: $regular-font;
    font-weight: 700;
    font-size: vw_d(20);
    line-height: 1.566;
    letter-spacing: 0.39px;
    background-color: $science-blue;
    border-radius: vw_d(27.53);
    transition: 0.3s;

    @include screen('mobile') {
      width: 100%;
      height: 60px;
      font-size: 20px;
      border-radius: 27.53px;
    }

    @include screen('mobile-extra-small') {
      font-size: 18px;
      height: 50px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

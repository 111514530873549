@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

// Accessibility styles
:global {
  .INDMobile #INDmenu-btn {
    top: auto !important;
    bottom: 135px !important;
  }

  .hide {
    display: none !important;
  }

  .intercom-lightweight-app-launcher {
    bottom: 15px !important;
    right: 15px !important;
  }
  .intercom-namespace .intercom-app div:nth-child(2) {
    bottom: 15px !important;
    right: 15px !important;
  }

  .hideIntercom {
    opacity: 0 !important;
    visibility: hidden !important;
    cursor: none;
    pointer-events: none;
  }

  @include screen('mobile') {
    .intercom-lightweight-app-launcher {
      bottom: 80px !important;
      right: 5px !important;
    }

    .intercom-namespace .intercom-app div:nth-child(2) {
      bottom: 80px !important;
      right: 5px !important;
    }

    .hideIntercom {
      opacity: 0 !important;
      visibility: hidden !important;
      cursor: none;
      pointer-events: none;
    }
  }
}

.app {
  position: relative;
  height: 100%;
}

.footer {
  position: fixed;
  z-index: 20;
  left: 0;
  bottom: 0;
  right: 0;
}

.pop-up-manager {
  z-index: 40;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $background-color;

  &-transition {
    $transition-duration: 350ms;
    $transition-timing-function: ease-in-out;

    &-forward {
      &-enter {
        z-index: 2;
        opacity: 1;
        transform: translate3d(100%, 0, 0);
      }

      &-enter-active {
        z-index: 2;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity, transform;
        transition-duration: #{$transition-duration};
        transition-timing-function: #{$transition-timing-function};
      }

      &-enter-done {
        z-index: 1;
      }

      &-exit {
        z-index: 1;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      &-exit-active {
        z-index: 1;
        opacity: 0.5;
        transform: translate3d(-25%, 0, 0);
        transition: opacity, transform;
        transition-duration: #{$transition-duration * 1};
        transition-timing-function: #{$transition-timing-function};
      }
    }

    &-fade {
      &-enter {
        z-index: 2;
        opacity: 0;
      }

      &-enter-active {
        z-index: 2;
        opacity: 1;
        transition: opacity, transform;
        transition-duration: #{$transition-duration};
        transition-timing-function: #{$transition-timing-function};
      }

      &-enter-done {
        z-index: 1;
        opacity: 1;
      }

      &-exit {
        z-index: 1;
      }

      &-exit-active {
        z-index: 1;
      }
    }

    &-backward {
      &-enter {
        z-index: 2;
        opacity: 1;
        transform: translate3d(-100%, 0, 0);
      }

      &-enter-active {
        z-index: 2;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity, transform;
        transition-duration: #{$transition-duration};
        transition-timing-function: #{$transition-timing-function};
      }

      &-enter-done {
        z-index: 1;
      }

      &-exit {
        z-index: 1;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      &-exit-active {
        z-index: 1;
        opacity: 0.5;
        transform: translate3d(25%, 0, 0);
        transition: opacity, transform;
        transition-duration: #{$transition-duration * 1};
        transition-timing-function: #{$transition-timing-function};
      }
    }
  }
}

.temporaryBtns {
  position: fixed;
  top: 50px;
  left: 20px;
  z-index: 20;
}

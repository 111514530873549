// Colors
// -------------------------------------------------------------------------------------------------
$science-blue: #0757d3;
$primary-color: #0757d3;
$background-color: #000;
$light-blue: #68a4ff; // action links
$primary-hover: #0757fb;

// Typography
// -------------------------------------------------------------------------------------------------

$regular-font: 'Montserrat', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$inter: 'Inter', sans-serif;

// Sizes
// -------------------------------------------------------------------------------------------------
$content-wrap-margin: 25px;
$header-height-mobile: 55px;
$footer-height-mobile: 60px;

// Media queries
// -------------------------------------------------------------------------------------------------
$media-mobile-extra-small-max: 319;
$media-mobile-small-max: 375;
$media-mobile-medium-max: 540;
$media-mobile-large-max: 767;
$media-mobile-max: 1024;
$media-tablet-min: 768;
$media-tablet-max: 1025;
$media-desktop-min: $media-mobile-max + 1;
$media-desktop-hd-min: 1920;
$media-desktop-small-max: 1300;

$media-mobile: '(max-width: #{$media-mobile-max}px)';
$media-mobile-small: '(max-width: #{$media-mobile-small-max}px)';
$media-mobile-extra-small: '(max-width: #{$media-mobile-extra-small-max}px)';
$media-mobile-medium: '(max-width: #{$media-mobile-medium-max}px)';
$media-mobile-large: '(min-width: #{$media-mobile-medium-max}px) and (max-width: #{$media-mobile-large-max}px)';
$media-mobile-landscape: '(max-width: #{$media-mobile-max}px) and (orientation: landscape)';
$media-mobile-portrait: '(max-width: #{$media-mobile-max}px) and (orientation: portrait)';

$media-tablet: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px)';
$media-tablet-landscape: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: landscape)';
$media-tablet-portrait: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: portrait)';

$media-desktop: '(min-width: #{$media-desktop-min}px)';
$media-desktop-hd: '(min-width: #{$media-desktop-hd-min}px)';
$media-small-desktop: '(min-width: #{$media-desktop-min}px) and (max-width: #{$media-desktop-small-max}px)';

$media-non-mobile: '(min-width: #{$media-mobile-max}px)';
$media-non-desktop: '(max-width: #{$media-tablet-max}px)';

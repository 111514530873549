@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.playIcon {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 55%;
    background-image: url('client/assets/player/icon-play.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    filter: blur(12px) saturate(50%);
    pointer-events: none;
  }

  &.l {
    width: vw_d(144);
    height: vw_d(144);

    &:before {
      width: vw_d(52);
      height: vw_d(52);
    }
  }

  &.m {
    width: vw_d(42);
    height: vw_d(42);

    &:before {
      width: vw_d(16);
      height: vw_d(16);
    }
  }

  &.sm {
    width: vw_d(32);
    height: vw_d(32);

    &:before {
      width: vw_d(14);
      height: vw_d(14);
    }
  }
}
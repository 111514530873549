@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.input-wrapper {
  position: relative;
  margin-top: 25px;
  @include screen('mobile') {
    margin-top: 14px;
  }
  &.bigTopMargin {
    margin-top: 57px;
    @include screen('mobile') {
      margin-top: 47px;
    }
  }
  &.focusedTopLabel {
    .label {
      top: -30px;
      font-size: 16px;
      letter-spacing: -0.18px;
      color: rgba(255, 255, 255, 0.58);
    }
  }
  &.focused {
    .label {
      opacity: 0;
      visibility: hidden;
    }
  }
  &.error {
    input {
      border-bottom: 1px solid rgba(252, 68, 79, 1);
    }
  }
  input {
    display: block;
    width: 100%;
    height: 59px;
    padding: 0 24px;
    font-family: $regular-font;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: -0.25px;
    line-height: 58px;
    color: rgba(255, 255, 255, 0.87);
    background-color: rgba(255, 255, 255, 0.07);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    -webkit-appearance: none;
    border-radius: 0px;

    @include screen('mobile') {
      font-size: 18px;
    }

    @include screen('mobile-extra-small') {
      font-size: 16px;
    }

    &:focus {
      border-bottom: 1px solid rgb(104, 164, 255);
    }
    &:-webkit-autofill {
      color: rgba(255, 255, 255, 0.87);
      -webkit-text-fill-color: rgba(255, 255, 255, 0.87);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      &:focus {
        border-bottom: 1px solid rgb(104, 164, 255);
      }
    }
  }
  .label {
    position: absolute;
    pointer-events: none;
    padding: 0 24px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    font-family: $regular-font;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: -0.25px;
    color: rgba(255, 255, 255, 0.41);
    transition: all ease 0.3s;
    width: 100%;

    @include screen('mobile') {
      font-size: 18px;
    }

    @include screen('mobile-extra-small') {
      font-size: 16px;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.content-wrapper {
  position: relative;
  width: 100%;
  max-width: vw_d(1345);
  margin: 0 auto;
  padding: 0 vw_d(10) vw_d(170);
  z-index: 2;

  .video-wrapper {
    width: 100%;
    height: vw_d(747);
    border-radius: vw_d(4);
    animation: skeleton-loading 1s linear infinite alternate;
  }
  .company-info-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: vw_d(94);

    .info-left {
      width: 100%;
      max-width: vw_d(760);

      .title {
        margin-top: vw_d(5);
        margin-bottom: vw_d(85.82);

        span {
          display: inline-block;
          font-size: vw_d(50.44);
          line-height: 0.845;
          font-family: $regular-font;
          color: transparent;
          border-radius: vw_d(4);
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }
      .description {
        p {
          display: block;
          margin-bottom: vw_d(4);
          font-size: vw_d(20);
          line-height: 1.64;
          font-family: $regular-font;
          border-radius: vw_d(4);
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }
      .general-info {
        .row {
          display: flex;
          margin: vw_d(26.7) 0px;

          justify-content: space-between;
          .col-left,
          .col-right {
            width: 49%;
            border-radius: vw_d(4);
            animation: skeleton-loading 1s linear infinite alternate;
          }
          .col-left {
            font-size: vw_d(26);
            line-height: vw_d(31.4);
            color: transparent;
            font-family: $regular-font;
            font-weight: 600;
          }
          .col-right {
            font-size: vw_d(26);
            line-height: vw_d(31.4);
            color: transparent;
            font-family: $regular-font;
            font-weight: 500;
          }
        }
      }
    }

    .info-right {
      width: 100%;
      max-width: vw_d(475);

      .action-btns {
        display: flex;
        justify-content: space-between;
        padding-bottom: vw_d(90);
        .add-to-my-list-btn {
          position: relative;
          width: vw_d(229);
          height: vw_d(56);
          border-radius: vw_d(28);
          overflow: hidden;
          animation: skeleton-loading 1s linear infinite alternate;
        }
        .upvote-btn {
          position: relative;
          width: vw_d(220);
          height: vw_d(56);
          border-radius: vw_d(28);
          overflow: hidden;
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }
      .founders {
        padding-left: vw_d(103.45);
        padding-bottom: vw_d(219.67);
        .founders-title {
          margin-bottom: vw_d(29.74);
          span {
            display: inline-block;
            font-size: vw_d(26);
            line-height: 1.054;
            font-family: $regular-font;
            font-weight: 700;
            color: transparent;
            animation: skeleton-loading 1s linear infinite alternate;
            border-radius: vw_d(4);
          }
        }
        .team-member-info {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: vw_d(35.91);
          padding-bottom: vw_d(31.75);
          border-bottom: solid vw_d(1.45) rgba(151, 151, 151, 0.25);
          .photo {
            flex-shrink: 0;
            width: vw_d(82.08);
            height: vw_d(82.08);
            margin-right: vw_d(22.64);
            border-radius: 50%;
            overflow: hidden;
            animation: skeleton-loading 1s linear infinite alternate;
          }
          .info {
            flex-grow: 0;
            .name {
              width: vw_d(100);
              margin-bottom: vw_d(15);
              font-size: vw_d(19.8);
              line-height: 1;
              font-family: $regular-font;
              font-weight: 600;
              color: transparent;
              animation: skeleton-loading 1s linear infinite alternate;
              border-radius: vw_d(4);
            }
            .position {
              width: vw_d(100);
              font-size: vw_d(19.8);
              line-height: 1;
              font-family: $regular-font;
              font-weight: 400;
              color: transparent;
              animation: skeleton-loading 1s linear infinite alternate;
              border-radius: vw_d(4);
            }
          }
        }
      }
      .categories {
        padding-left: vw_d(65);
        .categories-title {
          margin-bottom: vw_d(67.23);

          span {
            display: inline-block;
            font-size: vw_d(32);
            line-height: 1;
            font-family: $regular-font;
            font-weight: 700;
            color: transparent;
            border-radius: vw_d(4);
            animation: skeleton-loading 1s linear infinite alternate;
          }
        }

        .categories-wrapper {
          display: flex;
          position: relative;
          flex-wrap: wrap;
          .category {
            position: relative;
            height: vw_d(43.13);
            min-width: vw_d(107);
            margin-right: vw_d(17.62);
            margin-bottom: vw_d(16.75);
            border-radius: vw_d(21.6);
            overflow: hidden;
            animation: skeleton-loading 1s linear infinite alternate;
            &:nth-child(2) {
              min-width: vw_d(147);
            }
            &:nth-child(3) {
              min-width: vw_d(93);
            }
            &:nth-child(4) {
              min-width: vw_d(167);
            }
          }
        }
      }
    }
  }

  .news {
    padding-top: vw_d(160);
    .news-title {
      margin-bottom: vw_d(87);
      span {
        display: inline-block;
        font-size: vw_d(32);
        line-height: 1;
        color: transparent;
        font-family: $regular-font;
        font-weight: 700;
        border-radius: vw_d(4);
        animation: skeleton-loading 1s linear infinite alternate;
      }
    }
    .news-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .news-item {
        display: flex;
        width: vw_d(550.92);
        margin-bottom: vw_d(80);

        .image {
          flex-shrink: 0;
          width: vw_d(140);
          height: vw_d(140);
          margin-right: vw_d(31.33);
          border-radius: vw_d(4);
          animation: skeleton-loading 1s linear infinite alternate;
        }

        .content {
          flex-grow: 1;
          .title {
            padding-bottom: vw_d(2.72);

            span {
              display: inline-block;
              font-size: vw_d(20);
              line-height: 1.142;
              color: transparent;
              font-family: $regular-font;
              font-weight: 300;
              border-radius: vw_d(4);
              animation: skeleton-loading 1s linear infinite alternate;
            }
          }
          .description {
            p {
              display: block;
              margin-bottom: vw_d(5);
              font-size: vw_d(20);
              line-height: 1;
              color: transparent;
              font-family: $regular-font;
              font-weight: 500;
              border-radius: vw_d(4);
              animation: skeleton-loading 1s linear infinite alternate;
            }
          }
        }
      }
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(169, 169, 169, 0.2);
  }
  100% {
    background-color: rgba(134, 134, 134, 0.2);
  }
}

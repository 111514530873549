@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.page-navigation {
  position: relative;
  z-index: 201;
  height: vw_d(70);
  background-color: rgba(black, 0.4);
  backdrop-filter: blur(vw_d(10));

  @include screen('mobile') {
    width: 100%;
    height: 70px;
    background-color: rgba(black, 0.8);
  }

  &-content-wrap {
    position: relative;
    height: 100%;

    @include screen('mobile') {
      height: inherit;
      overflow-x: auto;
      margin: 0 28px;
    }

    @include screen('mobile-extra-small') {
      margin: 0 #{$content-wrap-margin - 10px};
    }
  }

  &-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &-list {
    display: flex;
    gap: vw_d(71);
    height: 100%;
    align-items: center;
    padding: 0;
    margin: 0;

    @include screen('mobile') {
      gap: initial;
      justify-content: space-between;
      width: 100%;
    }
    @include screen('mobile-large') {
      justify-content: center;
      gap: 50px;
    }

    @include screen('mobile-extra-small') {
      gap: 30px;
    }
  }

  &-item {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .pending-action {
      display: block;
      position: absolute;
      width: vw_d(7.03);
      height: vw_d(7.03);
      background: #f29736;
      box-shadow: 0 0 vw_d(10) vw_d(0) #ffc98f;
      border-radius: 50%;
      right: vw_d(-10);
      top: calc(50% - vw_d(12));
      transform: translateY(-50%);

      @include screen('mobile') {
        width: 7px;
        height: 7px;
        box-shadow: 0 0 12px 3px rgba(255, 201, 143, 0.5);
        right: -9px;
        top: calc(50% - 9px);
      }
    }

    &.highlighted {
      @include screen('desktop') {
        background-color: rgba(white, 0.1);

        .page-navigation-link {
          padding: 0 vw_d(16);
          color: $light-blue;
        }
      }
    }
  }

  &-link {
    position: relative;
    display: flex;
    align-items: center;
    gap: vw_d(11);
    height: 100%;
    font-size: vw_d(16);
    font-weight: 600;
    line-height: 1;
    letter-spacing: vw_d(0.97);
    color: white;
    transition: opacity 0.3s ease;

    &-icon {
      display: block;
    }

    @include screen('mobile') {
      font-size: 14px;
      letter-spacing: 0.85px;
      font-weight: 500;
      white-space: nowrap;
    }

    &::before {
      content: '';
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: vw_d(8.81);
      background: linear-gradient(-90deg, #257bff, #0757d3);

      @include screen('desktop') {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: opacity, visibility;
        transition-duration: 0.3s;
      }

      @include screen('mobile') {
        height: 9px;
      }
    }

    &.active {
      color: white;
      pointer-events: none;

      &::before {
        display: block;
      }
    }

    &:hover {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    gap: vw_d(16);

    p {
      font-size: vw_d(16);
      line-height: vw_d(19);
      letter-spacing: vw_d(2.56);
      font-weight: 600;
      text-transform: uppercase;
      max-width: vw_d(500);

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    img {
      display: block;
      max-height: vw_d(36);
    }
  }

  &.sticky-mode {
    position: sticky;
    top: 0;
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.welcome-wrapper {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  @include screen('mobile') {
    position: relative;
    padding: 0 8px;
    height: 100%;
  }
  .welcomeWrapperI {
    width: auto;
    height: auto;
    @include screen('mobile') {
      position: absolute;
      top: 0px;
      left: 8px;
      right: 8px;
      bottom: 125px;
      padding: 0 4px;
      overflow: auto;
    }
  }
  .title {
    padding-bottom: 37px;
    font-size: 40px;
    line-height: 1.32;
    font-family: $regular-font;
    font-weight: 500;
    color: #fff;
    text-align: center;

    @include screen('mobile') {
      padding-bottom: 36.54px;
      font-size: 24px;
      letter-spacing: 0.47px;
      line-height: 1.333;
    }

    @include screen('mobile-extra-small') {
      font-size: 22px;
      letter-spacing: 0.4px;
      line-height: 1.3;
    }
  }
  .subtitle {
    padding-bottom: 50px;
    font-size: 20px;
    line-height: 1.423;
    font-family: $regular-font;
    font-weight: 400;
    color: #c7c7c7;
    text-align: center;

    @include screen('mobile') {
      padding-bottom: 24px;
      font-size: 18px;
      line-height: 1.611;
    }

    @include screen('mobile-extra-small') {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .cooperation-text {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 24px;
    padding: 23px 22px 24px 32px;
    background-color: rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.18);

    @include screen('mobile') {
      padding: 18px 9px 23px 11px;
      flex-direction: column;
    }

    .time-left-icon {
      margin-right: 37px;
      @include screen('mobile') {
        width: 30px;
        height: 30px;
        margin-right: 0;
        margin-left: -6px;
        margin-bottom: 16px;
        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      font-size: 16px;
      line-height: 1.688;
      font-family: $regular-font;
      font-weight: 300;
      color: #fff;

      @include screen('mobile') {
        text-align: center;
        line-height: 25px;
      }

      @include screen('mobile-extra-small') {
        font-size: 14px;
        line-height: 1.6;
      }
    }
  }
  .additional-text {
    padding-bottom: 84px;
    font-size: 16px;
    line-height: 2;
    font-family: $regular-font;
    font-weight: 300;
    color: #fff;
    text-align: center;

    @include screen('mobile') {
      padding-bottom: 0px;
      font-size: 14px;
      line-height: 1.571;
      text-align: left;
    }

    @include screen('mobile-extra-small') {
      font-size: 13px;
      line-height: 1.5;
    }
  }
  .btn-wrapper {
    text-align: center;

    @include screen('mobile') {
      position: fixed;
      width: 100%;
      bottom: 21px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include screen('mobile-extra-small') {
      padding: 0 #{$content-wrap-margin - 10px};
    }

    .continue-button {
      display: block;
      width: 252px !important;
      height: 65px;
      margin: 0 auto;
      border-radius: 33px;
      font-size: 28px;
      letter-spacing: 0.54px;
      font-family: $regular-font;
      font-weight: 700;
      color: #fff;

      @include screen('mobile') {
        width: 100% !important;
        max-width: 338px;
        height: 60px;
        margin-bottom: 19px;
        border-radius: 30px;
        font-size: 20px;
        letter-spacing: 0.39px;
      }

      @include screen('mobile-extra-small') {
        font-size: 18px;
        height: 50px;
        width: inherit !important;
      }
    }
    .cancel-button {
      display: none;

      @include screen('mobile') {
        display: block;
        height: auto;
        background-color: transparent;
        font-size: 20px;
        line-height: 1;
        letter-spacing: 0.39px;
        font-family: $regular-font;
        font-weight: 400;
        color: #fff;
      }

      @include screen('mobile-extra-small') {
        font-size: 18px;
      }
    }
  }
}

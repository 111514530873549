@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.team-member-info {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: vw_d(35.91);
  padding-bottom: vw_d(31.75);
  border-bottom: solid vw_d(1.45) rgba(151, 151, 151, 0.25);
  &:nth-child(3) {
    border-bottom: 0;
  }
  .photo {
    flex-shrink: 0;
    width: vw_d(82.08);
    height: vw_d(82.08);
    margin-right: vw_d(22.64);
    border-radius: 50%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }
  .info {
    flex-grow: 1;
    .name {
      margin-bottom: 0.2em;
      font-size: vw_d(19.8);
      line-height: 1.43;
      color: white;
      font-family: $regular-font;
      font-weight: 600;
      color: white;
    }
    .position {
      font-size: vw_d(19.8);
      line-height: 1.43;
      color: white;
      font-family: $regular-font;
      font-weight: 400;
      color: white;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.company {
  min-height: 100vh;
  padding-top: vw_d(133);
  position: relative;

  .blur-section {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: vw_d(1479);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: 0.28;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(36px);
    }
    &:after {
      content: '';
      position: absolute;
      height: vw_d(519);
      width: 100%;
      left: 0;
      bottom: 0;
      transform: translateY(vw_d(10));
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    max-width: vw_d(1345);
    margin: 0 auto;
    padding: 0 vw_d(10) vw_d(45);
    z-index: 2;

    .video-wrapper {
      width: 100%;
      height: vw_d(747);
    }

    .company-info-wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: vw_d(38);

      .info-left {
        width: 100%;
        max-width: vw_d(760);
      }

      .info-right {
        width: 100%;
        max-width: vw_d(475);
        padding-top: vw_d(20);

        .action-btns {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-bottom: vw_d(90);

          .add-to-my-list-btn {
            position: relative;
            height: vw_d(56);
            margin-right: vw_d(26);
            border: vw_d(3) solid #535051;
            border-radius: vw_d(28);
            overflow: hidden;
            cursor: pointer;
            // transition: 0.3s;

            &:hover {
              border-color: #2478fc;
              box-shadow: 0px 0px vw_d(39) rgba(36, 121, 254, 0.54);

              .btn-inner {
                .icon {
                  opacity: 1;
                }
              }

              .filter {
                filter: none;
                // background-color: rgba(255, 255, 255, 0.26);
                background: linear-gradient(270deg, rgba(37, 123, 255, 0.3) 0%, rgba(7, 87, 211, 0.33) 100%);
              }
            }

            &.was-added {
              border-color: #2478fc;
              box-shadow: 0px 0px vw_d(39) rgba(36, 121, 254, 0.54);

              .btn-inner {
                .icon {
                  opacity: 1;
                }
              }

              .filter {
                filter: none;
                // background-color: rgba(255, 255, 255, 0.26);
                background: linear-gradient(270deg, rgba(37, 123, 255, 0.3) 0%, rgba(7, 87, 211, 0.33) 100%);
              }
            }

            &.loading {
              pointer-events: none;
            }

            .btn-inner {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              position: relative;
              padding: 0 vw_d(26.8) 0 vw_d(19.89);
              font-size: vw_d(20.09);
              font-family: $regular-font;
              font-weight: 500;
              letter-spacing: vw_d(0.39);
              color: #fff;
              z-index: 2;
              white-space: nowrap;

              .icon {
                display: block;
                width: vw_d(21.94);
                margin-right: vw_d(13);
                opacity: 0.5;
              }
            }
            .filter {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              // filter: saturate(50%);
              // backdrop-filter: blur(7.8px);
              background: rgba(255, 255, 255, 0.1);
              border-radius: vw_d(28);
              z-index: 1;
              transition: all ease 0.3s;
            }
          }

          .upvote-btn {
            position: relative;
            height: vw_d(56);
            border-radius: vw_d(28);
            overflow: hidden;
            cursor: pointer;

            &.loading {
              pointer-events: none;
            }

            &.voted {
              pointer-events: none;

              .filter {
                background: rgba(255, 255, 255, 0.26);

                &:before {
                  opacity: 0;
                }
              }
            }
            &:hover {
              .filter {
                background: rgba(255, 255, 255, 0.26);
                &:before {
                  opacity: 0;
                }
              }
            }
            .btn-inner {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              position: relative;
              padding: 0 vw_d(8.68) 0 vw_d(24);
              font-size: vw_d(20.09);
              font-family: $regular-font;
              font-weight: 500;
              letter-spacing: vw_d(0.39);
              color: #fff;
              border-radius: vw_d(28);
              z-index: 2;
              .divider {
                height: 100%;
                width: vw_d(1);
                margin: 0 vw_d(16);
                background-color: #979797;
                opacity: 0.38;
              }

              .rate {
                display: flex;
                align-items: center;
                span {
                  margin-right: vw_d(7.21);
                }
              }
            }
            .filter {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              filter: saturate(50%);
              backdrop-filter: blur(7.8px);
              border-radius: vw_d(28);
              z-index: 1;
              transition: all ease 0.3s;
              &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.05) 100%);
                border-radius: vw_d(28);
                transition: all ease 0.3s;
              }
            }
          }
        }

        .categories {
          padding-left: vw_d(100);
          .categories-title {
            padding-bottom: vw_d(67.23);
            font-size: vw_d(32);
            line-height: 0.649;
            font-family: $regular-font;
            font-weight: 700;
            color: white;
          }

          .categories-wrapper {
            display: flex;
            position: relative;
            flex-wrap: wrap;

            .category {
              position: relative;
              height: auto;
              margin-right: vw_d(17.62);
              margin-bottom: vw_d(17.62);
              border-radius: vw_d(50);
              overflow: hidden;
              .category-inner {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                padding: vw_d(5) vw_d(17.04);
                font-size: vw_d(20.86);
                letter-spacing: vw_d(0.4);
                font-weight: 600;
                color: white;
                z-index: 2;
              }
              .blured-block {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(255, 255, 255, 0.1);
                filter: saturate(-50%);
                backdrop-filter: blur(8.1px);
                border-radius: vw_d(21.6);
              }
            }
          }
        }
      }
    }

    .claim-company-link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: vw_d(120);

      .text {
        padding-right: vw_d(15.5);
        font-size: vw_d(16);
        line-height: 1;
        color: #fff;
        font-family: $regular-font;
        font-weight: 400;
      }
      .link-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: vw_d(50);
        padding: 0 vw_d(31);
        font-size: vw_d(16);
        line-height: 1;
        color: #fff;
        font-family: $regular-font;
        font-weight: 700;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: vw_d(25);
      }
    }
  }

  .slider-wrapper {
    position: relative;
    padding: vw_d(88) 0 0;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      left: 0;
      top: 0;
      height: vw_d(361);
      background: rgb(37, 37, 37);
      background: linear-gradient(180deg, rgba(37, 37, 37, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.button {
  position: relative;
  display: inline-block;
  background-color: $science-blue;
  color: white;
  font-family: $regular-font;
  font-weight: 700;
  min-width: vw_d(160);
  transition: all 0.3s ease;
  overflow: hidden;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
    pointer-events: none;
  }

  .button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
    height: 100%;
  }

  &.large {
    height: 60px;
    border-radius: 30px;
    font-size: 20px;
    width: auto;
  }

  &.medium {
    height: vw_d(50);
    border-radius: vw_d(25);
    font-size: vw_d(16);
    width: auto;
  }

  &.small {
    height: vw_d(42.33);
    width: auto;

    border-radius: vw_d(22);
    font-size: vw_d(15.45);
    line-height: 1;
    letter-spacing: vw_d(0.3);
  }

  &.big-vertical-paddings {
  }

  &.medium-vertical-paddings {
    padding: vw_d(0) vw_d(49);
  }

  &.small-vertical-paddings {
    padding: vw_d(0) vw_d(19);
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.socials {
  display: flex;
  @include screen('mobile') {
    gap: 14px;
  }
  a {
    width: vw_d(30.24);
    height: vw_d(30.24);
    margin-right: vw_d(21.17);

    @include screen('mobile') {
      width: 20px;
      height: 20px;
      margin: 0;
    }

    @include screen('mobile-extra-small') {
      flex-shrink: 0;
    }

    .icon {
      display: block;
      width: 100%;
      height: 100%;
      svg {
        display: block;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

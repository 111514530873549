@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.steps-indicator {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translate(-50%, 0);
  width: 713px;
  height: 55px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include screen('mobile') {
    position: fixed;
    top: 30px;
    width: 160px;
    margin-left: -5px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    display: block;
    width: 100%;
    height: 1px;
    background-color: #979797;
    transition: all ease 0.3s;
  }

  .step-wrapper {
    position: relative;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &:first-child {
      margin-left: -27.5px;

      @include screen('mobile-extra-small') {
        margin-left: -16px;
      }
    }
    &:last-child {
      margin-right: -27.5px;

      @include screen('mobile-extra-small') {
        margin-right: -16px;
      }
    }
    .step {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 39px;
      height: 39px;
      background-color: #000;
      border-radius: 50%;
      border: solid 1px #979797;
      z-index: 3;
      transition: all ease 0.3s;
      &.active {
        width: 55px;
        height: 55px;
        border: none;
        background-color: #0757d3;
        -webkit-box-shadow: 0px 0px 44px 0px rgba(57, 121, 220, 1);
        -moz-box-shadow: 0px 0px 44px 0px rgba(57, 121, 220, 1);
        box-shadow: 0px 0px 44px 0px rgba(57, 121, 220, 1);
        .step-number {
          font-size: 20.09px;
        }
        @include screen('mobile') {
          -webkit-box-shadow: 0px 0px 13px 0px rgba(57, 121, 220, 1);
          -moz-box-shadow: 0px 0px 13px 0px rgba(57, 121, 220, 1);
          box-shadow: 0px 0px 13px 0px rgba(57, 121, 220, 1);
        }
      }
      &.completed {
        width: 55px;
        height: 55px;
        border: none;
        background-color: rgba(48, 48, 48, 1);
        .step-number {
          font-size: 20.09px;
        }
        @include screen('mobile') {
          width: 39px;
          height: 39px;
        }
      }
      .step-number {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 14.06px;
        font-family: $regular-font;
        font-weight: 700;
        color: #fff;
        z-index: 4;
      }
      .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 49px;
          height: 49px;
          border-radius: 50%;
          background-color: #0757d3;
        }
      }

      .propgressBarSvg {
        position: absolute;
        width: 55px;
        height: 55px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        visibility: hidden;
        transition: all ease 0.3s;

        &.show {
          opacity: 1;
          visibility: visible;
        }
        .svg {
          circle {
            transition: stroke-dashoffset 0.3s linear;
            stroke: rgba(255, 255, 255, 0.4);
            stroke-width: 2px;
          }

          .bar {
            position: relative;
            transform: rotate(-90deg);
            transform-origin: 50%;
            stroke: #fff;
          }
        }
      }
    }
    .texts {
      position: absolute;
      left: 50%;
      top: 68px;
      transform: translate(-50%, 0);

      @include screen('mobile') {
        display: none;
      }

      .title {
        font-size: 17px;
        letter-spacing: 0.39px;
        line-height: 1;
        font-family: $regular-font;
        font-weight: 700;
        color: #fff;
        white-space: nowrap;
        text-transform: uppercase;
      }

      .status {
        font-size: 14px;
        letter-spacing: 0.27px;
        line-height: 2.247;
        font-family: $regular-font;
        font-weight: 400;
        text-align: center;
        color: #fff;
      }
    }
  }
}

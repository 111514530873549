@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.scrollable-block {
  position: relative;
  padding: vw_d(8);
  padding-left: 0;
  color: white;
  background-color: #1c1c1c;

  @include screen('mobile') {
    padding: 0;
  }
}

.title {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: vw_d(20);
  height: vw_d(118);
  padding-left: vw_d(50);
  display: flex;
  align-items: center;

  font-size: vw_d(45);
  line-height: vw_d(50);
  letter-spacing: vw_d(0.87);
  font-weight: 600;

  background: linear-gradient(180deg, rgba(#1c1c1c, 1) 70%, rgba(#1c1c1c, 0) 100%);

  @extend %overflow-ellipsis;

  @include screen('mobile') {
    height: 60px;
    width: 100%;
    top: -2px;
    padding-left: 34px;

    font-size: 22px;
    line-height: 1;
    letter-spacing: 0.43px;
  }

  @include screen('mobile-extra-small') {
    font-size: 20px;
    padding-left: 20px;
  }
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: vw_d(118);

  @include screen('mobile') {
    padding-top: 60px;
  }
}

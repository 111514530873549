@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.profile {
  position: relative;
  padding-top: calc(#{$header-height-mobile} + 10px);
  padding-bottom: calc(#{$footer-height-mobile} + 30px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  overflow-x: hidden;
}

.header {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
}

.control-panel {
  position: fixed;
  z-index: 202;
  left: 0;
  top: 0;
  right: 0;
}

.blur-section {
  position: absolute;
  opacity: 0.3;
  left: -61px;
  top: -5px;
  width: 570px;
  min-width: calc(100% + 61px);
  height: 570px;
  z-index: -1;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(18px);
  }

  &:after {
    content: '';
    position: absolute;
    height: 50%;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translateY(vw(10));
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.top {
  width: 100%;
}

.field {
  padding-bottom: 10px;
  width: 100%;

  &-value {
    font-size: 24px;
    font-weight: 600;
    padding: 0 27px;
    text-align: center;
    text-transform: capitalize;

    @include screen('mobile-extra-small') {
      font-size: 22px;
    }
  }

  button {
    color: white;
    font-size: 18px;
    // padding-right: 14px;
    text-transform: uppercase;
  }
}

.field,
.avatar {
  button,
  svg {
    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

.logout-btn {
  font-size: 16px;
  letter-spacing: 0.31px;
  line-height: 1.375;
  color: #68a4ff;
  text-decoration: underline;

  @include screen('mobile-extra-small') {
    font-size: 14px;
  }
}

.edit-name-btn {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  opacity: 0.68;

  svg {
    width: 100%;
    height: 100%;
  }
}

.edit-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 23px;

  .edit-info-btn {
    position: relative;
    margin-right: 14px;
    font-size: 16px;
    letter-spacing: 0.31px;
    line-height: 1.375;
    color: rgba(white, 0.68);

    @include screen('mobile-extra-small') {
      font-size: 14px;
    }

    &:after {
      content: '';
      width: 1px;
      height: 19px;
      position: absolute;
      top: 3px;
      right: -7px;
      background-color: rgba(white, 0.28);
    }
  }

  .btns-separator {
    padding: 0 5px;
  }

  .edit-info-btn {
    text-decoration: underline;
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

h1 {
  width: 100%;
  font-size: 35px;
  line-height: 1.3;
  font-family: $regular-font;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-align: center;
  @include screen('mobile') {
    padding-top: 10px;
    font-size: 24px;
    line-height: 1.333;
    letter-spacing: 0.47px;
  }
}

.form-wrapper {
  width: 658px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include screen('mobile') {
    width: 100%;
    padding: 0 11px;
  }

  .formWrapperI {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include screen('mobile') {
      position: absolute;
      width: auto;
      height: auto;
      left: 12px;
      right: 12px;
      top: 0;
      bottom: 125px;
      overflow: auto;
    }
  }

  .field-wrapper {
    width: 100%;

    &.short {
      width: 320px;
      @include screen('mobile') {
        width: 100%;
      }
    }
  }

  .btnWrapper {
    width: 100%;
    padding-top: 51px;
    @include screen('mobile') {
      position: absolute;
      bottom: 49px;
      left: 0;
      padding-top: 0;
    }
  }
}

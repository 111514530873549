@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 0 75px;
  z-index: 3;
  pointer-events: none;

  @include screen('(max-width: 1100px)') {
    padding: 0 25px;
  }

  @include screen('mobile') {
    justify-content: center;
    bottom: 14.45px;
  }

  &.skippable {
    @include screen('mobile') {
      justify-content: center;
      column-gap: 40px;
    }
  }

  .back,
  .skip {
    width: 120px !important;
    min-width: inherit !important;
    height: 55px;
    border-radius: 28px;
    background-color: #303030;
    color: #fff;
    font-size: 20.09px;
    letter-spacing: 0.39px;
    font-family: $regular-font;
    font-weight: 700;
    color: #fff;
    transition: all ease 0.3s;
    pointer-events: all;

    @include screen('mobile') {
      width: auto !important;
      height: auto;
      border-radius: 0px;
      background-color: transparent;
      font-size: 20px;
      letter-spacing: 0.39px;
      font-weight: 400;
    }

    @include screen('mobile-extra-small') {
      font-size: 18px;
    }
  }

  .back {
    &.hide {
      visibility: hidden;
      pointer-events: none;
    }
  }

  .skip {
    visibility: hidden;
    pointer-events: none;
    @include screen('mobile') {
      display: none;
    }
    &.show {
      visibility: visible;
      pointer-events: all;
      @include screen('mobile') {
        display: block;
      }
    }
  }
}

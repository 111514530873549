@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.banner-join {
  position: relative;
  background-color: $primary-color;
  color: white;
  opacity: 0;
  visibility: hidden;
  transition: 0.35s;
  pointer-events: none;
  height: 0;

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    height: vw_d(48);
  }

  p {
    font-size: vw_d(18);
    line-height: 1.889;
    font-weight: 500;
    text-align: center;
    padding: vw_d(7) vw_d(107) vw_d(7) 0;
    cursor: default;

    span {
      font-weight: 700;
      margin-left: vw_d(15);
      text-decoration: underline;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }

  .close-button {
    position: absolute;
    right: vw_d(37);
    top: 50%;
    padding: vw_d(19);
    transform: translateY(-50%);
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }

    i {
      margin-top: vw_d(2);
      width: vw_d(16);
      height: vw_d(16);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

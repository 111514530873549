@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.step-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  height: 90%;
  max-height: 750px;
  padding-top: 64px;
  padding-left: 65px;
  padding-right: 66px;
  padding-bottom: 64px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #979797;
  border-radius: 12px;
  z-index: 100;

  @include screen('mobile') {
    position: relative;
    left: auto;
    top: auto;
    transform: translate(0, 0);
    width: 100%;
    height: auto;
    max-height: inherit;
    margin-top: 50px;
    padding-top: 23px;
    padding-right: 11px;
    padding-left: 14px;
    padding-bottom: 10px;
  }

  @include screen('mobile-large') {
    max-width: 400px;
    margin: 50px auto 0;
  }

  .close-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 27px;
    top: 28px;
    cursor: pointer;
    @include screen('mobile') {
      width: 30px;
      height: 30px;
      left: -5px;
      top: -54px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 26px;
      height: 3px;
      background-color: #fff;
      border-radius: 2px;
      @include screen('mobile') {
        width: 32px;
        height: 3px;
      }
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .warpper {
    overflow-y: auto;
    height: 100%;
    position: relative;

    .top-text {
      padding-bottom: 50px;
      @include screen('mobile') {
        padding-bottom: 56px;
      }
      p {
        color: #fff;
        text-align: center;
        font-size: 20.09px;
        line-height: 31.46px;
        letter-spacing: 0.39px;
        font-family: $regular-font;
        font-weight: 400;
        @include screen('mobile') {
          font-size: 18px;
          line-height: 31.46px;
          letter-spacing: 0.35px;
        }

        &:first-child {
          padding-bottom: 16.09px;
          font-size: 50px;
          line-height: 58.46px;
          letter-spacing: 0.97px;
          font-family: $regular-font;
          font-weight: 700;
          text-transform: uppercase;
          @include screen('mobile') {
            padding-bottom: 14.54px;
            font-size: 24px;
            line-height: 26px;
            letter-spacing: 0.47px;
          }
        }
      }
    }

    .select-wrapper {
      position: relative;
      padding-bottom: 51px;
      z-index: 3;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 22px;
        font-size: 20.09px;
        line-height: 31.46px;
        letter-spacing: 0.39px;
        color: #fff;
        font-family: $regular-font;
        font-weight: 500;
        @include screen('mobile') {
          padding-bottom: 28.54px;
          font-size: 18px;
          line-height: 18px;
          letter-spacing: 0.35px;
        }

        .globe-icon {
          margin-right: 5px;
          @include screen('mobile') {
            margin-right: 15px;
          }
        }
      }

      .select {
        position: relative;
        width: 340px;
        height: 48px;
        margin: 0 auto;

        @include screen('mobile') {
          width: 100%;
        }

        .dropdown-indicator {
          margin-top: 5px;
          margin-right: 20px;
          width: 4px;
          border: solid 4px transparent;
          border-top: solid 4px #fff;
        }
      }
    }

    .join-button {
      position: relative;
      display: block;
      width: 340px !important;
      margin: 0 auto;
      color: #fff;
      text-align: center;
      font-size: 20.09px;
      letter-spacing: 0.39px;
      font-family: $regular-font;
      font-weight: 700;
      z-index: 2;
      @include screen('mobile') {
        width: 100% !important;
        min-width: inherit !important;
        margin-bottom: 64px;
        font-size: 18px;
        letter-spacing: 0.35px;
      }
    }

    .disclaimer {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      font-size: 11px;
      letter-spacing: 0.22px;
      text-align: center;
      color: #c1c1c1;

      @include screen('mobile') {
        position: relative;
        // bottom: 7%;
        font-size: 11px;
        letter-spacing: 0.22px;
        white-space: normal;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

$disable-color: rgba(255, 255, 255, 0.48);
$option-active: rgba(7, 87, 211, 0.68);
$headerH: 110px;
$headerHMobile: 56px;

@mixin content-title {
  font-size: vw_d(20.09);
  line-height: 1.566;
  letter-spacing: vw_d(0.39);
  font-weight: 700;
}

.popup {
  position: fixed;
  top: $headerH;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;

  @include screen('mobile') {
    // position: relative;
    // height: auto;
    // min-height: 100%;
    top: $headerHMobile;
    // bottom: initial;
    // left: initial;
    // right: initial;
    // z-index: 1;
    // display: flex;
    // flex-flow: column nowrap;
    // justify-content: center;
  }

  &-overlay {
    position: absolute;
    top: -$headerH;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% + #{$headerH});
    background-color: rgba(0, 0, 0, 0.82);
    backdrop-filter: blur(vw_d(10));

    @include screen('mobile') {
      background-color: #000;
      top: -$headerHMobile;
      height: calc(100% + #{$headerHMobile});
      backdrop-filter: 0;
    }
  }

  &-bg {
    filter: blur(36px);
    z-index: -1;
  }

  &-content {
    position: absolute;
    // top: vw_d(100);
    top: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    // height: calc(100% - #{vw_d(50)});
    padding: vw_d(100) vw_d(85) vw_d(74) vw_d(107);
    transform: translate(-50%, 0);
    // background-color: rgba(0, 0, 0, 0.5);

    @include screen('mobile') {
      display: block;
      padding: 0;
    }

    @include screen('mobile-large') {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  .main-player-wrap {
    position: relative;
    max-width: vw_d(1283);
    height: 100%;

    @include screen('mobile') {
      max-width: 100%;
      height: auto;
    }

    .main-player {
      position: relative;
      max-width: vw_d(1283);
      height: inherit;
      aspect-ratio: 16/9;

      @include screen('mobile') {
        max-width: 100%;
        height: auto;
      }

      &-play-icon {
        @include screen('mobile') {
          width: 49px !important;
          height: 49px !important;

          &:before {
            width: 19px;
            height: 19px;
          }
        }
      }

      & :global {
        #gallery-player {
          position: absolute;
          top: 0;
          width: 100%;
        }
        // .gallery-player-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
        //   padding-top: 0;
        //   overflow: hidden;
        //   height: 100%;
        // }
      }

      video {
        border-top-left-radius: vw_d(7);
        border-top-right-radius: vw_d(7);
        // object-fit: cover;

        // @media (max-aspect-ratio: 3/2) {
        //   height: 100% !important;
        //   width: auto;
        //   left: 50%;
        //   transform: translateX(-50%);
        // }
      }
    }

    .current-title {
      position: absolute;
      // top: 100%;
      left: 0;
      width: 100%;
      padding: vw_d(18) vw_d(26) vw_d(18) vw_d(33);
      font-size: vw_d(30);
      line-height: 1.167;
      letter-spacing: vw_d(2.4);
      font-weight: 700;
      text-transform: uppercase;
      border-bottom-left-radius: vw_d(7);
      border-bottom-right-radius: vw_d(7);
      background-color: rgba(255, 255, 255, 0.08);

      @include screen('mobile') {
        position: static;
        padding: 9px 37px 8px 39px;
        font-size: 16px;
        line-height: 1.438;
        letter-spacing: normal;
        font-weight: 600;
        text-transform: none;
        border-radius: 0;
      }
    }
  }

  .list {
    height: 100%;
    width: vw_d(408);
    margin-left: vw_d(35);
    overflow-y: auto;
    overflow-x: hidden;

    @include screen('mobile') {
      width: 100%;
      padding: 25px 37px 70px 37px;
      margin-left: 0;
    }

    &-item {
      position: relative;
      width: inherit;
      height: vw_d(266);
      margin-bottom: vw_d(40);
      border-top-left-radius: vw_d(7);
      border-top-right-radius: vw_d(7);

      @include screen('mobile') {
        display: flex;
        min-height: 90px;
        height: auto;
        margin-bottom: 14px;
        border: 0;
        overflow: hidden;
      }
    }

    &-title {
      padding: vw_d(6) vw_d(5) vw_d(6) vw_d(10);
      font-size: vw_d(16);
      line-height: 1.5;
      letter-spacing: 1.28px;
      font-weight: 500;
      border-bottom-left-radius: vw_d(4);
      border-bottom-right-radius: vw_d(4);
      background-color: rgba(255, 255, 255, 0.08);

      @include screen('mobile') {
        width: 60%;
        padding: 39px 10px 13px 14px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1.12px;
        border-radius: 0;
        flex-grow: 1;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: vw_d(28);
    left: vw_d(110);
    width: vw_d(31);
    height: vw_d(31);

    @include screen('mobile') {
      position: fixed;
      width: 21px;
      height: 21px;
      top: 18px;
      left: 25px;
      z-index: 10;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 2px;
        background-color: #fff;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    i,
    svg {
      width: 100%;
      height: 100%;

      @include screen('mobile') {
        display: none;
      }
    }
  }
}

.is-loading {
  :after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: inherit;
    width: inherit;
    z-index: 1;
    @include blink-bg();

    @include screen('mobile') {
      width: 100%;
    }
  }
}

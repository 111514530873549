@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.persons-wrapper {
  position: absolute;
  left: vw_d(37);
  top: vw_d(154.16);
  right: vw_d(20.53);
  bottom: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: vw_d(6);
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: vw_d(3);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.persons {
  display: flex;
  column-gap: 3.5%;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-right: vw_d(60.53);

  .person {
    width: 31%;
    margin-top: vw_d(31.75);
    padding-top: vw_d(35.91);
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
    border-top: solid vw_d(1.45) rgba(151, 151, 151, 0.25);
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
      border-bottom: 0;
    }
  }
}

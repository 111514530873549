@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.mandates {
  position: relative;
  height: 100%;
  color: white;
  background-color: $background-color;

  &::before {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30%;
    background: linear-gradient(0deg, #000, transparent);
    transition: opacity, visibility;
    transition-duration: 1s;
    pointer-events: none;
  }

  &.slider-active {
    &::before {
      opacity: 0;
      visibility: hidden;
    }

    .companies {
      &::before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.header {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
}

.control-panel {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  right: 0;
}

.hero {
  margin-bottom: 54px;
}

.content {
  position: relative;
}

.companies {
  &::before {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    left: -#{$content-wrap-margin};
    top: 0;
    right: -#{$content-wrap-margin};
    height: 40%;
    background: linear-gradient(180deg, #000, transparent);
    transition: opacity, visibility;
    transition-duration: 1s;
    pointer-events: none;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.double-image-slide {
  position: relative;
  margin: 0 auto;
  transition: 0.45s;

  &.sm {
    width: vw_d(422);
    height: vw_d(520);

    .slide-inner {
      border-radius: vw_d(37);
      &:after {
        height: vw_d(290);
      }
    }
    .main-image {
      border-radius: vw_d(37);
    }

    .bottom { 
      bottom: vw_d(31);
      h6 {
        font-size: vw_d(28.62);
        letter-spacing: 4.58px;
        padding-bottom: vw_d(7);
      }
      p {
        font-size: vw_d(16);
        padding-bottom: vw_d(24);
      }
      .button {
        font-size: vw_d(16);
        letter-spacing: 2.47px;
        border-radius: vw_d(22);
      }
    }
  }

  &.l {
    width: vw_d(548);
    height: vw_d(676);

    .slide-inner {
      border-radius: vw_d(47);
      &:after {
        height: vw_d(220);
        // border-radius: vw_d(47);
      }

      &:before {
        display: none;
      }
    }
    .main-image {
      border-radius: vw_d(47);
    }

    .bottom { 
      bottom: vw_d(40);
      
      h6 {
        font-size: vw_d(37.21);
        letter-spacing: 5.95px;
        padding-bottom: vw_d(7);
      }
      p {
        font-size: vw_d(14.88);
        padding-bottom: vw_d(25);
      }

      .button {
        border-radius: vw_d(27);
        font-size: vw_d(20);
        letter-spacing: 3.21px;
      }
    }
  }

  .slide-inner {
    position: relative;
    display: block;
    height: inherit;
    overflow: hidden;

    &.is-upvote-disabled {
      &:before {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(225, 217, 217, 0) 0%, rgba(0,0,0,1) 100%);
      z-index: 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: vw_d(200);
      top: 0;
      right: 0;
      background: linear-gradient(45deg, rgba(225, 0, 0, 0), rgba(225, 0, 0, 0), rgba(225, 0, 0, 0), rgba(0,0,0,1) 100%);
    }

    .main-image {
      width: 100%;
      height: inherit;
      object-fit: cover;
      object-position: top;
    }

    // .coverPNG {
    //   height: inherit;
    //   position: absolute;
    //   top: vw_d(-25);
    //   left: 50%;
    //   transform: translateX(-50%);
    // }

    .upvote {
      position: absolute;
      top: vw_d(41);
      right: vw_d(19);
      display: flex;
      align-items: center;
      font-size: vw_d(18);
      letter-spacing: 0.43px;

      span {
        margin-right: vw_d(13);
      }
    }

    .bottom {
      width: 100%;
      position: absolute;
      text-align: center;
      padding: 0 vw_d(55);
      text-shadow: 0 0 14px rgba(0, 0, 0, 0.4);
      z-index: 1;

      h6 {
        line-height: 1.2;
        margin: 0;
      }

      .button {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        white-space: nowrap;
        background-color: #212223;
        padding: vw_d(5) vw_d(20) vw_d(5) vw_d(5);

        .iconPlay {
          margin-right: vw_d(10);
        }
      }
    }
  }
}
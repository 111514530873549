@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.not-found {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;

    @include screen('desktop') {
      display: none;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;

    img {
      width: 100%;
      display: block;
      position: relative;
      z-index: 0;
    }

    .mask {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image: linear-gradient(270deg, rgba(44, 120, 237, 0.22) 1%, rgba(7, 86, 207, 0.37) 99%);
    }

    .gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .content {
    position: relative;
    z-index: 4;
    text-align: center;
    font-family: $regular-font;
    color: white;

    @include screen('mobile') {
      padding: 0 vw(20);
    }

    .top {
      margin-bottom: vw_d(54);

      @include screen('mobile') {
        margin-bottom: vw(54);
      }

      img {
        width: 604px;
        display: block;

        @include screen('mobile') {
          display: inline-block;
          width: 90%;
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: vw_d(50);
      letter-spacing: vw_d(5);
      line-height: 1.1;
      padding-bottom: vw_d(36);

      @include screen('mobile') {
        font-size: vw(35);
        letter-spacing: vw(4);
        padding-bottom: vw(36);
      }
    }
    .subtitle {
      font-size: vw_d(18);
      letter-spacing: vw_d(0.35);
      font-weight: 600;
      padding-bottom: vw_d(36);

      @include screen('mobile') {
        font-size: vw(14);
        letter-spacing: vw(0.35);
        padding-bottom: vw(36);
      }
    }

    .back-btn {
      padding: 0 vw_d(30);

      @include screen('mobile') {
        margin: auto;
        width: auto;
        padding: 0 26px;
      }
    }
  }
}

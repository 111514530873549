@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.blurred-block {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  filter: saturate(-50%);
  backdrop-filter: blur(6px);
  z-index: 2;
}

.content-wrapper {
  display: flex;
  position: absolute;
  width: 737px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  max-height: 90%;
  border: 1px solid #979797;
  border-radius: 12px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.55);

  @include screen('mobile') {
    width: auto;
    left: 25px;
    right: 25px;
    transform: translate(0, -50%);
  }

  .content-wrapper-i {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 55px;
    overflow: auto;
    @include screen('mobile') {
      margin-top: 20px;
    }
  }
}

.wrapper {
  position: relative;
  z-index: 20;
  // width: 658.49px;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 50px;

  @include screen('mobile') {
    width: 100%;
    padding: 0px 12px 15px;
  }

  h1 {
    display: block;
    width: 100%;
    padding: 0 45px 42px 45px;
    font-size: 50px;
    line-height: 60px;
    font-family: $regular-font;
    font-weight: 600;
    letter-spacing: 0.0453125vw;
    color: #fff;

    @include screen('mobile') {
      padding-bottom: 27px;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 1.92px;
      font-weight: 700;
      text-align: center;
    }

    @include screen('mobile-extra-small') {
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 1.9px;
      padding: 0;
    }
  }
}

.answerWrapper {
  position: relative;
  padding: 40px 42px 80px 57px;

  @include screen('mobile') {
    padding: 20px 15px 60px 15px;
  }

  @include screen('mobile-extra-small') {
    padding: 20px 0 40px 0;
  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 70%;
    left: 0;
    top: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(-17deg, rgba(255, 255, 255, 0) 0%, rgba(77, 140, 238, 0.14) 100%);
  }

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin-left: 3px;
    margin-bottom: 28px;
    border-radius: 13px;
    background-color: #0757d3;
    font-size: 14px;
    letter-spacing: 0.26px;
    font-family: $regular-font;
    font-weight: 700;
  }
  h3 {
    padding-bottom: 24px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: #fff;
    font-family: $regular-font;
    font-weight: 600;

    @include screen('mobile-extra-small') {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .answer {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-bottom: 35px;
      padding-left: 15px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.31px;
      font-family: $regular-font;
      font-weight: 400;

      @include screen('mobile-extra-small') {
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 25px;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 10px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
}

.closeBtnMobile {
  display: none;
  @include screen('mobile') {
    display: block;
    position: fixed;
    width: 21px;
    height: 21px;
    left: 25px;
    top: 25px;
    z-index: 10;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: #fff;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.closeBtn {
  position: absolute;
  width: 21px;
  height: 21px;
  right: 41px;
  top: 28px;
  z-index: 2;
  cursor: pointer;
  @include screen('mobile') {
    display: none;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 26px;
    height: 3px;
    background-color: #fff;
    border-radius: 2px;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

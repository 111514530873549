@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.select-wrapper {
  margin-top: 25px;
  @include screen('mobile') {
    margin-top: 15px;
  }
  .dropdownIndicator {
    width: 10px;
    height: 10px;
    margin-right: 34px;
    margin-top: 2px;

    @include screen('mobile-extra-small') {
      margin-right: 20px;
    }

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.container {
  .containerI {
    @include screen('mobile') {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 125px;
      overflow: auto;
    }
  }
  .non-mandatory {
    padding-bottom: 20px;
    font-size: 20px;
    letter-spacing: 0.39px;
    line-height: 31.46px;
    font-family: $regular-font;
    font-weight: 400;
    color: #fff;
    text-align: center;
  }
  .textarea {
    width: 658px;
    margin: 62.73px auto 85.4px;

    @include screen('mobile') {
      width: 100%;
      margin: 48.09px auto 0;
      padding: 0 12px;
    }

    textarea {
      display: block;
      width: 100%;
      padding: 10px 23.6px;
      height: 164px;
      font-size: 16px;
      line-height: 1.6;
      font-family: $regular-font;
      font-weight: 400;
      letter-spacing: -0.31px;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.07);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      resize: none;
      &::placeholder {
        color: rgba(255, 255, 255, 0.41);
      }
    }
  }
}

.continue-btn-wrapper {
  @include screen('mobile') {
    position: absolute;
    bottom: 39px;
    width: 100%;
    left: 0;
  }
}

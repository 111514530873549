@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.container {
  .containerI {
    @include screen('mobile') {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 125px;
      overflow: auto;
    }
  }
  .non-mandatory {
    padding-bottom: 20px;
    font-size: 20px;
    letter-spacing: 0.39px;
    line-height: 31.46px;
    font-family: $regular-font;
    font-weight: 400;
    color: #fff;
    text-align: center;
  }
  .btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 62.73px 0 0;

    @include screen('mobile') {
      margin: 42.73px 0 0;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 82px;
      font-size: 28px;
      letter-spacing: -0.31px;
      font-family: $regular-font;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.87);
      cursor: pointer;
      transition: all 0.3s ease;

      @include screen('mobile') {
        height: 60px;
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
      }

      @include screen('mobile-extra-small') {
        height: 50px;
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
      }

      &.yes-btn {
        margin-right: 24px;
        background-color: rgba(255, 255, 255, 0.07);
        &.active {
          background-color: rgba(7, 87, 211, 0.38);
        }
        &:hover {
          background-color: rgba(7, 87, 211, 0.38);
        }
      }

      &.no-btn {
        background-color: rgba(255, 255, 255, 0.07);
        &.active {
          background-color: rgba(7, 87, 211, 0.38);
        }
        &:hover {
          background-color: rgba(7, 87, 211, 0.38);
        }
      }
    }
  }
}

.continue-btn-wrapper {
  margin-top: 51px;
  @include screen('mobile') {
    position: absolute;
    bottom: 39px;
    width: 100%;
    left: 0;
    margin-top: 0;
  }
}

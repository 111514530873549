@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.investments {
  position: relative;
  min-height: 300px;
  overflow-x: hidden;

  @include screen('mobile') {
    padding: 24px 32px 0;
    min-height: 200px;
  }

  @include screen('mobile-extra-small') {
    padding: 24px 20px 0;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }

  section {
    margin-bottom: vw_d(56);

    @include screen('mobile') {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: vw_d(150);

      @include screen('mobile') {
        margin-bottom: 0;
      }
    }
  }
}

.section-header {
  padding: vw_d(53) 0;
  background-color: rgba(#fff, 0.08);

  @include screen('mobile') {
    padding: 14px 34px 19px;
  }

  @include screen('mobile-extra-small') {
    padding: 14px 20px 20px;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include screen('mobile') {
      margin: 0;
    }
  }

  .title-and-text {
    width: 50%;

    @include screen('mobile') {
      width: 100%;
    }

    .title {
      font-size: vw_d(45);
      line-height: vw_d(50);
      letter-spacing: vw_d(0.87);
      font-weight: 600;
      margin-bottom: vw_d(14);
      @extend %overflow-ellipsis;

      @include screen('mobile') {
        font-size: 22px;
        line-height: 1.273;
        letter-spacing: 1.3px;
        margin-bottom: 0;
        text-overflow: inherit;
        white-space: normal;
      }

      @include screen('mobile-extra-small') {
        font-size: 20px;
        letter-spacing: 1.2px;
      }
    }

    .sub-title {
      font-size: vw_d(16);
      line-height: vw_d(26);
      letter-spacing: vw_d(0.4);
    }
  }
}

.investments-list {
  position: relative;

  .investment {
    position: relative;
    padding: vw_d(32) 0;
    background-color: rgba(#fff, 0.1);
    border-top: 1px solid rgba(#fff, 0.15);

    @include screen('mobile') {
      padding: 27px 0 19px;
    }

    &-content {
      display: flex;
      align-items: center;

      @include screen('mobile') {
        display: block;
      }
    }

    .company-title-wrap {
      display: flex;
      align-items: center;
      width: 30%;
      gap: vw_d(20);

      @include screen('mobile') {
        width: calc(100% - 50px);
        padding-bottom: 24px;
        gap: 13px;
      }

      @include screen('mobile-extra-small') {
        width: 100%;
      }

      .logo-wrap {
        @include screen('mobile') {
          width: 40px;
          height: 40px;
        }
      }

      img {
        display: block;
        max-width: vw_d(40);
        max-height: vw_d(40);

        @include screen('mobile') {
          width: inherit;
          height: inherit;
          max-width: 40px;
          max-height: 40px;
          object-fit: contain;
        }
      }

      .title-and-value {
        width: auto;
      }

      .company-title {
        font-size: vw_d(20);
        font-weight: 600;
        margin-right: vw_d(40);
        @extend %overflow-ellipsis;

        @include screen('mobile') {
          width: 100%;
          padding-bottom: 8px;
          margin-right: 0;
          font-size: 16px;
          line-height: 1.25;
          letter-spacing: 0.4px;
          text-overflow: initial;
          white-space: normal;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }

    .value,
    .date {
      font-size: vw_d(20);
      width: vw_d(200);
      margin-right: vw_d(40);
      width: 15%;
      @extend %overflow-ellipsis;

      @include screen('mobile') {
        width: auto;
        font-size: 14px;
        line-height: 1.143;
        letter-spacing: 0.35px;
        overflow: inherit;
        white-space: normal;
      }

      @include screen('mobile-extra-small') {
        font-size: 13px;
        line-height: 1.1;
      }
    }

    .date {
      @include screen('mobile') {
        padding: 5px 13px 5px 0;
        margin-right: 13px;
        border-right: 1px solid rgba(255, 255, 255, 0.28);
      }
    }

    .status-wrap {
      display: flex;
      width: calc(100% - 50px);

      @include screen('mobile-extra-small') {
        width: 100%;
      }
    }

    .status {
      position: relative;
      padding-left: vw_d(54);
      font-size: vw_d(20);
      width: 30%;

      @include screen('mobile') {
        width: auto;
        padding: 5px 0 5px 25px;
        font-size: 14px;
        line-height: 1.143;
        letter-spacing: 0.35px;
      }

      @include screen('mobile-extra-small') {
        font-size: 13px;
        line-height: 1.1;
      }

      &.APPROVED {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: vw_d(29);
          height: vw_d(29);
          border-radius: 50%;
          transform: translate(0, -50%);
          background: linear-gradient(-90deg, #257bff 0%, #0757d3 100%);
          box-shadow: 0 0 vw_d(10) vw_d(0) #257bff;

          @include screen('mobile') {
            width: 17px;
            height: 17px;
            box-shadow: 0 0 22px 0 #257bff;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: vw_d(7);
          top: calc(50% - vw_d(2));
          z-index: 2;
          width: vw_d(14);
          height: vw_d(7);
          border-left: vw_d(1) solid white;
          border-bottom: vw_d(1) solid white;
          transform: translate(0, -50%) rotate(-45deg);

          @include screen('mobile') {
            width: 8px;
            height: 4px;
            left: 5px;
            top: calc(50% - 1px);
            border-left: 1px solid white;
            border-bottom: 1px solid white;
          }
        }
      }

      &.UNDER_REVIEW {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: vw_d(29);
          height: vw_d(29);
          border-radius: 50%;
          transform: translate(0, -50%) scale(0.6);
          background: #68a4ff;
          box-shadow: 0 0 vw_d(30) vw_d(0) #aeceff;

          @include screen('mobile') {
            width: 17px;
            height: 17px;
            box-shadow: 0 0 22px 0 #aeceff;
          }
        }
      }

      &.NEW {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: vw_d(29);
          height: vw_d(29);
          border-radius: 50%;
          transform: translate(0, -50%) scale(0.6);
          background: #68a4ff;
          box-shadow: 0 0 vw_d(30) vw_d(0) #aeceff;

          @include screen('mobile') {
            width: 17px;
            height: 17px;
            box-shadow: 0 0 22px 0 #aeceff;
          }
        }
      }

      &.DISAPPROVED {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: vw_d(29);
          height: vw_d(29);
          border-radius: 50%;
          transform: translate(0, -50%) scale(0.6);
          background: #f29736;
          box-shadow: 0 0 vw_d(30) vw_d(0) #ffc98f;

          @include screen('mobile') {
            width: 17px;
            height: 17px;
            box-shadow: 0 0 22px 0 #ffc98f;
          }
        }
      }
    }

    .action-btn {
      background-color: rgba(#fff, 0.1);
      height: vw_d(56);
      border-radius: vw_d(28);
      width: 10%;
      min-width: vw_d(120);
      padding: 0 vw_d(37);
      font-size: vw_d(18);
      font-weight: 400;

      @include screen('mobile') {
        position: absolute;
        top: 50%;
        right: 30px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        min-width: 40px;
        padding: 0;
        transform: translateY(-50%);

        .icon-eye {
          width: 21px;
          height: 21px;

          svg {
            width: inherit;
            height: inherit;
          }
        }
      }
    }
  }
}

.no-data-message {
  padding: vw_d(30) vw_d(30) vw_d(50);
  background-color: rgba(#fff, 0.08);
  text-align: center;
  margin: 0 -30px;

  @include screen('mobile') {
    padding: 0 30px 30px;
  }

  .title {
    max-width: vw_d(800);
    font-size: vw_d(50);
    line-height: 1.2;
    letter-spacing: vw_d(5);
    text-align: center;
    padding: vw_d(41) 0 vw_d(28);
    margin: 0 auto;

    @include screen('mobile') {
      max-width: 100%;
      font-size: 35px;
      letter-spacing: 3px;
      padding: 40px 0 30px;
    }
  }

  .message {
    max-width: vw_d(600);
    font-size: vw_d(18);
    letter-spacing: 0.35px;
    line-height: 1.389;
    // font-weight: 600;
    text-align: center;
    margin: 0 auto vw_d(50);

    @include screen('mobile') {
      max-width: 100%;
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: vw_d(234);
    height: vw_d(50);
    font-size: vw_d(16);
    line-height: 1;
    font-weight: 700;
    border-radius: vw_d(33);
    background-color: $primary-color;

    @include screen('mobile') {
      width: 100%;
      max-width: 100%;
      height: 50px;
      font-size: 16px;
      line-height: 1;
      border-radius: 33px;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.infoWrapper {
  .info {
    max-height: vw_d(460);
    height: auto;
    overflow: hidden;
    @include screen('mobile') {
      max-height: vw(310);
    }
    &.full-info {
      max-height: none;
      height: auto;
      overflow: auto;
    }
  }

  .read-more {
    padding-top: vw_d(30);
    font-size: vw_d(20);
    line-height: vw_d(32);
    letter-spacing: 0;
    cursor: pointer;
    font-weight: 600;

    @include screen('mobile') {
      padding-top: vw(30);
      font-size: 16px;
      line-height: 1.423;
    }
  }
}

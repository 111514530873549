@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.player {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  pointer-events: visiblePainted;

  & > div:first-child {
    height: 100%;
  }

  :global {
    .vjs-default-skin.vjs-paused .vjs-big-play-button,
    .video-js .vjs-big-play-button {
      display: none;
    }

    .video-js:not(.vjs-fullscreen) {
      .vjs-tech {
        object-fit: cover;
      }
    }

    .vjs-fluid:not(.vjs-audio-only-mode),
    .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
      height: 100%;
      width: 100%;
    }

    .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
      visibility: visible !important;
      opacity: 1 !important;
      pointer-events: auto !important;
    }

    .video-js .vjs-control-bar {
      background-color: transparent;
      height: 8px;
      z-index: 2;
    }

    .video-js .vjs-control:not(.vjs-progress-control) {
      display: none!important;
    }

    .video-js .vjs-progress-control .vjs-progress-holder {
      margin: 0;
      padding-bottom: 8px;
    }

    .vjs-play-progress .vjs-svg-icon {
      display: none;
    }

    .video-js .vjs-progress-holder {
      height: 8px;
    }

    .video-js .vjs-play-progress {
      background-color: $primary-color;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .play-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    background-color: rgba(#fff, 0.5);
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);

    img {
      height: 33px;
      margin-right: -5px;
    }
  }

  .play-toggle-btn {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
  }

  .player-controls {
    position: absolute;
    z-index: 3;
    right: 10px;
    bottom: 10px + 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &-btn {
      svg {
        max-width: 21px;
        max-height: 21px;
      }
    }
  }
}
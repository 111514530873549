@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.page-error {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: vw_d(900);
  overflow: hidden;
  background-position: top;
  background-size: cover;

  @include screen('mobile') {
    min-height: auto;
    height: auto;
    padding: 70px 40px 0;
    margin-bottom: -80px;
  }

  .icon {
    width: vw_d(153);
    height: vw_d(137);

    @include screen('mobile') {
      width: 106px;
      height: 90px;
    }
  }

  .title {
    font-size: vw_d(50);
    line-height: 1;
    letter-spacing: vw_d(5);
    text-align: center;
    margin: vw_d(41) 0 vw_d(28);

    @include screen('mobile') {
      font-size: 35px;
      letter-spacing: 3px;
      margin: 30px 0 30px;
    }
  }

  .message {
    max-width: vw_d(436);
    font-size: vw_d(18);
    letter-spacing: 0.35px;
    line-height: 1.389;
    font-weight: 600;
    text-align: center;
    margin-bottom: vw_d(20);

    @include screen('mobile') {
      max-width: 100%;
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  .supportText {
    max-width: vw_d(436);
    font-size: vw_d(18);
    letter-spacing: 0.35px;
    line-height: 1.389;
    font-weight: 600;
    text-align: center;
    margin-bottom: vw_d(50);

    @include screen('mobile') {
      max-width: 100%;
      font-size: 18px;
      margin-bottom: 40px;
    }

    a {
      text-decoration: underline;
      color: #fff;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-width: vw_d(234);
    height: vw_d(50);
    font-size: vw_d(16);
    line-height: 1;
    font-weight: 700;
    border-radius: vw_d(33);
    background-color: $primary-color;

    @include screen('mobile') {
      width: 100%;
      height: 50px;
      font-size: 16px;
      line-height: 1;
      border-radius: 33px;
    }
    @include screen('mobile-large') {
      max-width: 320px;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.company {
  $text-content-height: 50px + 121px + 35px + 59px;
  $text-content-height_mandate: 50px + 155px + 35px + 59px;

  $preview-height: calc(100% - #{$text-content-height});
  $preview-height_mandate: calc(100% - #{$text-content-height_mandate});
  $bg-color: #222;

  position: relative;
  height: 100%;
  padding-top: $header-height-mobile;
  padding-bottom: #{$footer-height-mobile + 35px};

  .bg {
    position: absolute;
    left: -#{$content-wrap-margin};
    top: 0;
    right: -#{$content-wrap-margin};
    height: calc(100% - #{$text-content-height});
    background-size: cover;
    background-position: center;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 20%;
    }

    &::before {
      top: -2px;
      background: linear-gradient(180deg, rgba($background-color, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
      bottom: -2px;
      background: linear-gradient(0deg, rgba($background-color, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &.with-decor-image {
      opacity: 0.4;
    }
  }

  .preview-container {
    position: relative;
    height: $preview-height;
    border-radius: 30px;
    margin-bottom: 55px;

    @include screen('mobile-large') {
      width: 300px;
      margin: 0 auto 55px;
    }

    .preview {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      background-size: cover;
      background-position: center top;
    }

    .play-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 87px;
      height: 87px;
      border-radius: 50%;
      background-color: rgba(#fff, 0.5);
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      backdrop-filter: blur(10px);

      img {
        height: 33px;
        margin-right: -5px;
      }
    }

    &.with-decor-image {
      .play-btn {
        left: 35px;
        top: auto;
        bottom: 0;
        transform-origin: 0 50%;
        transform: translate(0, 50%) scale(0.69);
      }
    }
  }

  .info {
    position: relative;

    .actions {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
    }

    .title-wrap {
      position: relative;
      z-index: 1;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: 110px;

      @include screen('mobile-extra-small') {
        padding-right: 40px;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.3;
      letter-spacing: 5px;
      text-transform: uppercase;
      margin-bottom: 11px;

      // limit text to 2 lines
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include screen('mobile-extra-small') {
        font-size: 20px;
        letter-spacing: 2.5px;
      }
    }

    .description {
      font-size: 16px;
      font-weight: 200;
      line-height: 1.5;

      // limit text to 2 lines
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include screen('mobile-extra-small') {
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }

  .hr {
    height: 1px;
    background-color: rgba(#fff, 0.2);
    margin: 17px 0;
  }

  .founders {
    position: relative;

    &-title {
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      color: rgba(#fff, 0.5);
      margin-bottom: 15px;
    }

    &-list-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }

    &-list {
      position: relative;
      display: flex;
      flex-grow: 1;
      flex-wrap: nowrap;
      width: 100%;
      max-width: calc(100% - 140px);
      overflow: hidden;
      gap: 20px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50px;
        background: linear-gradient(270deg, rgba($background-color, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }

    .action-btn {
      min-width: 103px;
    }

    .founder {
      display: flex;
      align-items: center;
      gap: 10px;

      &-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
      }

      &-info {
        &-name {
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          margin-bottom: 5px;
          white-space: nowrap;
        }

        &-position {
          font-size: 10px;
          font-weight: 100;
          line-height: 1;
          white-space: nowrap;
        }
      }
    }
  }

  .player-wrap {
    position: absolute;
    z-index: 10;
    left: -#{$content-wrap-margin};
    right: -#{$content-wrap-margin};
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  .player-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;
  }

  .player-container {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: calc(50% - #{$text-content-height * 0.5});
    width: 100%;
    transform: translate(-50%, -50%);
    aspect-ratio: 3/2;
    @include screen('mobile-large') {
      width: 58%;
    }
  }

  .raising-tag {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 11px;
    border: 1px solid rgba(#fff, 0.44);
    padding: 0 11px;
    background-color: black;
  }

  .splash {
    position: relative;
    height: 100%;

    &-bg {
      position: absolute;
      left: -#{$content-wrap-margin};
      top: -#{$header-height-mobile};
      right: -#{$content-wrap-margin};
      height: calc(100% - #{$text-content-height * 0.5});
      background: linear-gradient(180deg, transparent, #222, #222, transparent);
    }

    &-preview {
      position: relative;
      height: $preview-height;
      border-radius: 30px;
      margin-bottom: 55px;
      @include blink-bg();
    }

    &-play-btn {
      position: absolute;
      z-index: 2;
      left: 35px;
      bottom: 0;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 5px solid $background-color;
      transform: translate(0, 50%);
      @include blink-bg();
    }

    &-info {
      position: relative;
    }

    &-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }

    &-title {
      height: 32px;
      border-radius: 15px;
      width: 50%;
      @include blink-bg();
    }

    &-actions {
      display: flex;
      gap: 7px;

      i {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        opacity: 0.5;
        @include blink-bg();

        &:nth-child(2) {
          width: 60px;
        }
      }
    }

    &-description {
      position: relative;
      width: 85%;

      &-line {
        height: 14px;
        border-radius: 15px;
        margin-bottom: 10px;
        @include blink-bg();

        &:last-child {
          width: 80%;
          margin-bottom: 0;
        }
      }
    }

    &-hr {
      height: 2px;
      background-color: $bg-color;
      opacity: 0.5;
      margin: 25px 0 42px;
    }

    &-founders {
      position: relative;
      width: 100%;
      display: flex;
      gap: 20px;
    }

    &-founder {
      display: flex;
      width: 150px;
      gap: 10px;

      &-avatar {
        min-width: 30px;
        height: 30px;
        border-radius: 50%;
        @include blink-bg();
      }

      &-info {
        position: relative;
        width: 80%;

        &-line {
          height: 14px;
          border-radius: 10px;
          margin-bottom: 5px;
          @include blink-bg();

          &:last-child {
            height: 10px;
            width: 80%;
          }
        }
      }
    }
  }

  &.prev {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate(0, -100%);
  }

  &.next {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate(0, 100%);
  }

  &.video-player-active {
    .bg {
      opacity: 0.4;
    }

    .preview-container {
      visibility: hidden;
    }
  }

  &.mandate {
    .bg {
      height: calc(100% - #{$text-content-height_mandate});
    }

    .preview-container {
      height: $preview-height_mandate;
    }

    .player-container {
      top: calc(50% - #{$text-content-height_mandate * 0.5});
    }

    .splash-bg {
      height: calc(100% - #{$text-content-height_mandate * 0.5});
    }

    .splash-preview {
      height: $preview-height_mandate;
    }
  }
}

.company-transition {
  $transition-duration: 200ms;

  &-appear {
    opacity: 0;
  }

  &-appear-active {
    opacity: 1;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-appear-done {
    opacity: 1;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: ease-in;
  }

  &-exit-done {
    opacity: 0;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.companies {
  position: relative;
}

.no-results {
  position: relative;
  padding-top: 87px;
  text-align: center;

  .search-value {
    font-size: 36px;
    line-height: 45px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .no-results-text {
    font-size: 22px;
    line-height: 32.77px;
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  }
}

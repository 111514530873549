@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.company-actions {
  position: relative;
  z-index: 10;
  display: flex;
  gap: 7px;
  // backdrop-filter: blur(10px);
  // border-radius: 16px;
  // overflow: hidden;

  .save-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;
    color: white;

    &-bg {
      position: relative;
      z-index: 1;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      border: 1.5px solid rgba(white, 0.6);
      // background-color: rgba(white, 0.25);
      // opacity: 0.1;
      transition: opacity;
      transition-duration: 0.3s;
      transition-timing-function: linear;
    }

    &-label {
      pointer-events: none;
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 28px;
      font-weight: 600;
      text-transform: uppercase;
      transform: translate(0, -50%);
      opacity: 0;
    }

    &-icon {
      position: absolute;
      z-index: 2;
      right: 10px;
      top: 50%;
      width: 11px;
      height: 15px;
      opacity: 0.6;
      transform: translate(0, -50%);
    }

    &.highlighted{
      .save-btn-bg {
        border-color: #2478FC;
        box-shadow: 0 0 24px 0 rgba(36, 121, 254, 0.54);
        background: linear-gradient(270deg, rgba(37, 123, 255, 0.45) 0%, rgba(7, 87, 211, 0.54) 100%);
        transition-delay: 0s;
      }

      .save-btn-icon {
        opacity: 1;

        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }

  .rate-btn {
    gap: 6px;
    padding: 0 5px;
    transition: background-color;
    transition-duration: 0.3s;
    transition-timing-function: linear;

    span {
      margin-left: 12px;
    }

    .action-btn-icon {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: rgba(#25e061, 0.25);

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1px;
        height: 1px;
        border: 4px solid transparent;
        border-bottom-color: white;
        transform: translate(-50%, -6px) scale(0.75, 1);
      }
    }

    &.voted {
      background-color: rgba(white, 0.25) !important;

      .action-btn-icon {
        background-color: rgba(37, 224, 97, 0.25) !important;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.search-wrapper {
  display: flex;
  align-items: center;
  &.active {
    .input-wrapper {
      width: 221px;
    }
  }

  .divider {
    width: 1px;
    height: 50px;
    margin: 0 0 0 25px;
    background-color: rgba(255, 255, 255, 0.48);
  }

  .input-wrapper {
    position: relative;
    width: 0px;
    display: flex;
    align-items: center;
    height: vw_d(40);
    border-bottom: 1px solid #fff;
    overflow: hidden;
    transition: width ease 0.3s;
    input {
      flex-grow: 1;
      padding-right: 10px;
      height: 100%;
      width: 100%;
      outline: none;
      border: none;
      font-size: 16px;
      line-height: 16px;
      font-family: $regular-font;
      font-weight: 700;
      color: #fff;
      background-color: transparent;

      &::placeholder {
        color: #fff;
      }
    }
    .clear-btn {
      flex-shrink: 0;
      position: relative;
      width: 17px;
      height: 17px;
      margin-right: 1px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 2px;
        border-radius: 1px;
        background-color: #fff;
        z-index: 3;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      .filter {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        filter: saturate(50%);
        backdrop-filter: blur(6px);
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 1;
      }
    }
  }
  .search-btn {
    position: relative;
    .search-icon {
      width: 26px;
      height: 26px;
      margin-left: 7px;
      cursor: pointer;
      i {
        display: block;
        width: 100%;
        height: 100%;
        svg {
          display: block;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
    .btn-text {
      position: absolute;
      top: 50%;
      right: 31px;
      transform: translate(0, -50%);
      font-size: 16px;
      line-height: 16px;
      color: #fff;
      font-family: $regular-font;
      font-weight: 700;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: vw_d(108);
  // background-color: ;

  .wrapper-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    filter: saturate(50%);
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.1);
  }

  .button {
    position: relative;
    height: vw_d(56);
    display: flex;
    align-items: center;
    padding: 0 vw_d(24);
    overflow: hidden;
    z-index: 2;
    &:hover {
      .inner {
        color: #b6b6b6;
      }
    }
    .inner {
      position: relative;
      font-family: $regular-font;
      font-size: vw_d(20);
      letter-spacing: 0.39px;
      font-weight: 500;
      color: #fff;
      z-index: 2;
      transition: all ease 0.3s;
    }
    .button-blur {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      filter: saturate(50%);
      backdrop-filter: blur(6px);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: vw_d(28);
    }
  }
}

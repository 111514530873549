@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.page-wrapper {
  min-height: 100vh;
  padding-top: vw_d(133);
  position: relative;

  .page-header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
  }

  .control-panel {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
  }

  @include screen('mobile') {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    min-height: auto;
    padding-top: calc(47px + 29px);
    padding-bottom: calc(#{$footer-height-mobile} + 40px);
  }

  .content-wrapper {
    padding: 0 vw_d(42);

    @include screen('mobile') {
      width: 100%;
      padding-left: 20px; //$content-wrap-margin;
      padding-right: 20px; // $content-wrap-margin;
    }

    section {
      .content {
        width: 100%;
        max-width: vw_d(1520);
        margin: 0 auto;
        text-align: center;
      }

      &.about {
        position: relative;
        height: calc(100vh - #{vw_d(133)} - #{vw_d(42)});
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: cover;
        // background-position: 100% 0;
        background-repeat: no-repeat;
        background-color: #070b10;
        background-position: center;
        border-radius: vw_d(40);

        @include screen('mobile') {
          height: calc(94vh - #{$header-height-mobile} - #{$footer-height-mobile} - 60px);
          padding-left: 0;
          border-radius: 24px;
          // background-position: center;
        }

        .content {
          width: 100%;
          max-width: vw_d(1312);
          margin: 0 auto;
          text-align: center;

          @include screen('mobile') {
            max-width: 100%;
            padding: 0 vw(18);
            text-align: center;
          }

          @media only screen and (min-width: 540px) and (max-width: 767px) {
            max-width: 98%;
          }

          // @include screen('mobile-small') {
          //   max-width: 100%;
          //   padding: 0 vw(15);
          //   text-align: center;
          // }

          .main-title {
            font-family: $regular-font;
            font-size: vw_d(90);
            line-height: vw_d(100);
            font-weight: 800;
            letter-spacing: vw_d(5);
            text-transform: uppercase;
            text-align: center;
            padding-bottom: vw_d(36);

            @include screen('mobile') {
              padding-right: vw(7);
              padding-left: vw(7);
              padding-bottom: vw(18);
              font-size: vw(40);
              line-height: vw(43.7);
              letter-spacing: vw(1.5);
              text-align: center;
            }

            // @include screen('mobile-small') {
            //   padding-right: 0px;
            //   padding-left: 0px;
            //   padding-bottom: 20px;
            //   font-size: 26px;
            //   line-height: 34.7px;
            // }
            @media only screen and (min-width: 540px) and (max-width: 767px) {
              padding-right: vw(12.5);
              padding-left: vw(12.5);
              font-size: vw(33);
              line-height: 1.1;
            }
          }

          .text {
            width: 100%;
            max-width: vw_d(850);
            margin: 0 auto;
            padding-bottom: vw_d(49);

            @include screen('mobile') {
              width: 100%;
              max-width: inherit;
              padding-bottom: 26px;
            }

            p {
              font-family: $regular-font;
              font-size: vw_d(24);
              line-height: vw_d(36);
              font-weight: 500;

              @include screen('mobile') {
                font-size: 16px;
                line-height: 22px;
              }

              @include screen('mobile-small') {
                font-size: 14px;
                line-height: 20px;
              }

              strong {
                font-weight: 700;
              }
            }
          }
        }

        .scroll-down-btn {
          position: absolute;
          left: 50%;
          bottom: vw_d(54);
          transform: translateX(-50%);

          @include screen('mobile') {
            bottom: 24px;
          }
        }
      }

      &.commonInfo {
        padding-top: vw_d(230);
        padding-bottom: vw_d(250);

        @include screen('mobile') {
          padding-top: 100px;
          padding-bottom: 0;
        }

        .content {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @include screen('mobile') {
            flex-wrap: wrap;
            max-width: inherit;
            padding-left: 25px;
            padding-right: 25px;
          }

          @media only screen and (min-width: 540px) and (max-width: 767px) {
            width: 85%;
          }

          .text {
            position: relative;
            width: 100%;
            max-width: vw_d(740);
            text-align: left;
            z-index: 2;

            @include screen('mobile') {
              max-width: inherit;
              text-align: center;
            }
            p {
              padding-bottom: vw_d(50);
              font-family: $regular-font;
              // font-size: vw_d(18);
              // line-height: vw_d(32);
              font-size: clamp(14px, 9.4px + 0.4469vw, 18px);
              line-height: 1.6;
              font-weight: 500;
              text-align: left;
              @include screen('mobile') {
                padding-bottom: 26px;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
              }
              strong {
                font-weight: 700;
              }
            }
          }

          .image {
            position: absolute;
            right: vw_d(-197);
            // top: vw_d(-65);
            padding-top: vw_d(141);
            width: vw_d(1000);
            overflow: hidden;
            z-index: 1;
            @include screen('mobile') {
              display: none;
            }

            @media only screen and (min-width: 540px) and (max-width: 767px) {
              display: block;
              position: relative;
              width: 100%;
              top: auto;
              right: auto;
              margin-top: 40px;
            }
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }

      &.become-an-investor {
        @include screen('mobile') {
          margin-top: -275px;
        }
        @media only screen and (min-width: 540px) and (max-width: 767px) {
          margin-top: 0;
        }
        .content {
          @include screen('mobile') {
            max-width: inherit;
          }
          @media only screen and (min-width: 540px) and (max-width: 767px) {
            width: 78%;
          }
          .text {
            padding-bottom: vw_d(70);
            @include screen('mobile') {
              padding-bottom: 15px;
              padding-left: 25px;
              padding-right: 25px;
            }
            h2 {
              padding-bottom: vw_d(30);
              font-family: $regular-font;
              font-size: vw_d(66);
              line-height: vw_d(100);
              font-weight: 700;
              letter-spacing: vw_d(5);
              text-align: center;
              text-transform: uppercase;
              @include screen('mobile') {
                padding-bottom: 18px;
                font-size: 32px;
                line-height: 39px;
                font-weight: 700;
                letter-spacing: 3px;
              }
            }
            p {
              display: block;
              width: 100%;
              max-width: vw_d(931);
              margin: 0 auto;
              font-family: $regular-font;
              font-size: vw_d(24);
              line-height: vw_d(32);
              font-weight: 600;
              text-align: center;
              @include screen('mobile') {
                max-width: inherit;
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
              }
            }
          }

          .cards-list {
            &-item {
              position: sticky;
              top: 20px;
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 80vh;
              min-height: vw_d(650);
              padding: vw_d(60);
              margin-top: 40px;
              background-color: rgba(7, 87, 211, 1);
              border: 1px solid rgba(255, 255, 255, 0.05);
              border-radius: vw_d(40);
              transform-origin: center top;
              box-shadow: 0 0 16px -6px rgb(20 35 59 / 60%);
              @include screen('mobile') {
                flex-wrap: wrap;
                width: 100%;
                height: 413px;
                min-height: inherit;
                padding: 24px;
                top: 10px;
                margin-top: 20px;
                border-radius: 24px;
                box-shadow: 0 0 12px -6px rgb(20 35 59 / 60%);
              }
              &-image {
                width: vw_d(530);
                height: 100%;
                border-radius: vw_d(40);
                overflow: hidden;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;

                @include screen('mobile') {
                  // width: 100%;
                  // height: auto;
                  display: none;
                }

                img {
                  display: none;

                  @include screen('mobile') {
                    display: block;
                    width: 100%;
                  }
                }
              }
              &-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                width: vw_d(719);

                @include screen('mobile') {
                  width: 100%;
                  // padding-bottom: 44px;
                }

                .card-number {
                  font-family: $regular-font;
                  font-size: vw_d(40);
                  line-height: vw_d(70);
                  font-weight: 800;
                  letter-spacing: vw_d(0.4);
                  text-align: center;
                  text-transform: uppercase;

                  @include screen('mobile') {
                    padding-bottom: 44px;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: inherit;
                  }
                }

                .card-text {
                  text-align: left;
                  @include screen('mobile') {
                    min-height: 170px;
                  }

                  @include screen('mobile-small') {
                    min-height: 185px;
                  }
                  h3 {
                    padding-bottom: vw_d(17);
                    font-family: $regular-font;
                    font-size: vw_d(50);
                    line-height: vw_d(70);
                    font-weight: 800;
                    letter-spacing: vw_d(5);
                    text-transform: uppercase;

                    @include screen('mobile') {
                      padding-bottom: 24px;
                      font-family: $regular-font;
                      font-size: 24px;
                      line-height: 29px;
                      letter-spacing: 2px;
                    }
                    @include screen('mobile-small') {
                      font-size: 22px;
                      line-height: 27px;
                    }
                  }
                  p {
                    font-family: $regular-font;
                    font-size: vw_d(24);
                    line-height: vw_d(42);
                    font-weight: 600;

                    @include screen('mobile') {
                      font-size: 16px;
                      line-height: 22px;
                    }
                    @include screen('mobile-small') {
                      font-size: 15px;
                      line-height: 21px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.how-it-works {
        @include screen('mobile') {
          padding-top: 60px;
        }
        .content {
          @include screen('mobile') {
            max-width: 100%;
          }
          .text {
            padding-top: vw_d(175);
            padding-bottom: vw_d(98);
            @include screen('mobile') {
              padding-top: 90px;
              padding-bottom: 60px;
              padding-right: 25px;
              padding-left: 25px;
            }
            @media only screen and (min-width: 540px) and (max-width: 767px) {
              padding-left: 70px;
              padding-right: 70px;
            }
            h2 {
              padding-bottom: vw_d(35);
              font-family: $regular-font;
              font-size: vw_d(66);
              line-height: vw_d(100);
              font-weight: 700;
              letter-spacing: vw_d(5);
              text-align: center;
              text-transform: uppercase;
              @include screen('mobile') {
                padding-bottom: 22px;
                font-size: 32px;
                line-height: 39px;
                letter-spacing: 3px;
              }
            }
            p {
              display: block;
              width: 100%;
              max-width: vw_d(885);
              margin: 0 auto;
              font-family: $regular-font;
              font-size: vw_d(24);
              line-height: vw_d(32);
              font-weight: 600;
              text-align: center;
              @include screen('mobile') {
                max-width: inherit;
                font-size: 16px;
                line-height: 22px;
              }
            }
          }

          .list {
            position: relative;
            padding-bottom: vw_d(4);
            &-item {
              position: relative;
              padding-bottom: vw_d(43);
              z-index: 2;

              @include screen('mobile') {
                padding-bottom: 26px;
              }
              @media only screen and (min-width: 540px) and (max-width: 767px) {
                width: 80%;
                margin: 0 auto;
              }
              &-wrapper {
                position: relative;
                display: flex;
                justify-content: flex-start;
                padding: vw_d(60) vw_d(120) vw_d(60) vw_d(60);
                margin-bottom: vw_d(50);
                filter: saturate(70%);
                backdrop-filter: blur(24px);
                border-radius: vw_d(40);
                border: 1px solid rgba(255, 255, 255, 0.05);
                background-color: rgba(50, 50, 50, 0.25);
                @include screen('mobile') {
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 17px 22px 26px 22px;
                  margin-bottom: 32px;
                  border-radius: 24px;
                }
                &-text {
                  text-align: left;
                  @include screen('mobile') {
                    position: relative;
                    order: 2;
                  }
                  h3 {
                    padding-bottom: vw_d(24);
                    font-family: $regular-font;
                    font-size: vw_d(50);
                    line-height: vw_d(70);
                    font-weight: 800;
                    letter-spacing: vw_d(5);
                    text-transform: uppercase;
                    @include screen('mobile') {
                      padding-bottom: 12px;
                      font-size: 24px;
                      line-height: 29px;
                      letter-spacing: 3px;
                    }
                  }
                  p {
                    display: block;
                    width: vw_d(713);
                    font-family: $regular-font;
                    font-size: vw_d(24);
                    line-height: vw_d(32);
                    font-weight: 600;
                    @include screen('mobile') {
                      width: 100%;
                      font-size: 16px;
                      line-height: 22px;
                      font-weight: 500;
                    }
                  }
                }
                &-number {
                  position: absolute;
                  right: vw_d(60);
                  top: vw_d(60);
                  font-family: $regular-font;
                  font-size: vw_d(40);
                  line-height: vw_d(70);
                  font-weight: 800;
                  letter-spacing: vw_d(0.4);
                  text-align: center;
                  text-transform: uppercase;

                  @include screen('mobile') {
                    position: relative;
                    right: auto;
                    top: auto;
                    order: 1;
                    padding-bottom: 32px;
                    font-size: 24px;
                    line-height: 29px;
                  }
                }
              }
            }

            .how-it-works-bg {
              position: absolute;
              top: vw_d(127);
              right: vw_d(82);
              width: vw_d(1237);
              height: vw_d(1777);
              background-size: cover;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              z-index: 1;

              @include screen('mobile') {
                width: auto;
                height: auto;
                left: -20px;
                right: -20px;
                top: 53px;
                bottom: 20px;
                background-position: top;
              }

              @media only screen and (min-width: 540px) and (max-width: 767px) {
                background-position: top;
              }
            }
          }
        }
      }

      &.faq {
        padding-top: vw_d(186);
        padding-bottom: vw_d(175);
        @include screen('mobile') {
          padding-top: 95px;
        }
        .content {
          text-align: left;

          @include screen('mobile') {
            max-width: inherit;
          }
        }
      }
      .sign-up-btn {
        height: vw_d(52);
        border-radius: vw_d(26);

        @include screen('mobile') {
          height: 38px;
          min-width: 120px;
          border-radius: 19px;
          font-family: $regular-font;
          font-size: 14px;
          line-height: 14px;
          font-weight: 700;
        }

        &.in-card {
          background-color: rgba(0, 0, 0, 1);

          @include screen('mobile') {
            margin-top: 32px;
          }
        }
      }

      .fake-btn {
        height: vw_d(52);
        width: vw_d(1);
        @include screen('mobile') {
          height: 38px;
          width: 1px;
        }
      }
    }

    .mobileImage {
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -190px;

      @media only screen and (min-width: 540px) and (max-width: 767px) {
        display: none;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}

@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/theme.scss';

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  line-height: 1;
  
  &.x-large-block-size {
    padding: 0 19px;
    height: 60px;
    width: 100%;
    border-radius: 60px;
    font-size: 20px;
    font-weight: 700;
  }

  &.large-size {
    padding: 0 19px;
    height: 37px;
    min-width: 37px;
    border-radius: 19px;
    font-size: 14.78px;
    font-weight: 700;
  }

  &.medium-size {
    padding: 0 16px;
    height: 32px;
    min-width: 32px;
    border-radius: 16px;
    font-size: 12.5px;
    font-weight: 700;
  }

  &.small-size {
    padding: 0 15px;
    height: 29px;
    min-width: 29px;
    border-radius: 15px;
    font-size: 10.67px;
    font-weight: 700;
  }

  &.primary-variant {
    color: white;
    background-color: $primary-color;
  }

  &.socondary-variant {
    color: white;
    background-color: gray;
  }

  &.blured-variant {
    color: white;
    background-color: rgba(white, 0.11);
    backdrop-filter: blur(10px);
  }

  &.no-paddings {
    padding: 0;
  }
  
  &:disabled {
    filter: grayscale(100%);
  }
}

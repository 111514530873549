@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.thanks-wrapper {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  @include screen('mobile') {
    position: relative;
    height: 100%;
    padding: 0 12px;
  }
  .thanksWrapperI {
    @include screen('mobile') {
      position: absolute;
      top: 0;
      left: 12px;
      right: 12px;
      bottom: 75px;
      overflow: auto;
    }
  }
  .title {
    padding-bottom: 50px;
    font-size: 50px;
    line-height: 1.32;
    font-family: $regular-font;
    font-weight: 500;
    color: #fff;
    text-align: center;
    @include screen('mobile') {
      padding-top: 0px;
      padding-bottom: 24px;
      font-size: 24px;
      letter-spacing: 0.47px;
      line-height: 1.333;
    }
  }
  .subtitle {
    padding-bottom: 50px;
    font-size: 26px;
    line-height: 1.423;
    font-family: $regular-font;
    font-weight: 400;
    color: #fff;
    text-align: center;
    @include screen('mobile') {
      padding-bottom: 19px;
      font-size: 18px;
      line-height: 1.611;
    }
  }
  .cooperation-text {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 41px;
    padding: 23px 22px 24px 37px;
    background-color: rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.18);
    @include screen('mobile') {
      flex-direction: column;
      margin-bottom: 24px;
      padding: 20px 8px 19px 8px;
    }
    .time-left-icon {
      margin-right: 30px;
      @include screen('mobile') {
        width: 30px;
        height: 30px;
        margin-right: 0px;
        svg {
          display: block;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
    p {
      font-size: 16px;
      line-height: 1.688;
      font-family: $regular-font;
      font-weight: 300;
      color: #fff;
      @include screen('mobile') {
        padding-top: 15px;
        line-height: 1.563;
        text-align: center;
      }
      br {
        display: none;
        @include screen('mobile') {
          display: inline-block;
        }
      }
    }
  }
  .additional-text {
    padding-bottom: 84px;
    font-size: 16px;
    line-height: 2;
    font-family: $regular-font;
    font-weight: 300;
    color: #fff;
    text-align: center;
    @include screen('mobile') {
      font-size: 14px;
      line-height: 1.571;
      text-align: left;
    }
  }
  .btn-wrapper {
    text-align: center;
    @include screen('mobile') {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .finish-button {
      display: block;
      width: 275px !important;
      height: 65px;
      margin: 0 auto;
      border-radius: 33px;
      font-size: 28px;
      letter-spacing: 0.54px;
      font-family: $regular-font;
      font-weight: 700;
      color: #fff;
      @include screen('mobile') {
        width: 100% !important;
        max-width: 338px;
        height: 60px;
        border-radius: 30px;
        font-size: 20px;
        letter-spacing: 0.39px;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.player {
  width: 100%;
  height: 100%;

  &:not(.is-custom-controls) {
    :global {
      .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
        visibility: visible !important;
        opacity: 1 !important;
        pointer-events: auto !important;
      }
  
      .video-js .vjs-control-bar {
        background-color: transparent;
        height: vw_d(8);
        z-index: 2;
      }
  
      .video-js .vjs-control:not(.vjs-progress-control) {
        display: none!important;
      }
  
      .video-js .vjs-progress-control .vjs-progress-holder {
        margin: 0;
        padding-bottom: vw_d(8);
      }
  
      .vjs-play-progress .vjs-svg-icon {
        display: none;
      }
  
      .video-js .vjs-progress-holder,
      .video-js .vjs-progress-holder .vjs-load-progress {
        height: vw_d(8);
      }
  
      .video-js .vjs-play-progress {
        background-color: $primary-color;
        border-top-right-radius: vw_d(4);
        border-bottom-right-radius: vw_d(4);
      }
    }
  }

  :global {
    .video-js {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    .vjs-default-skin.vjs-paused .vjs-big-play-button,
      .video-js .vjs-big-play-button {
        display: none;
      }

    .vjs-picture-in-picture-control {
      display: none;
    }
  }

  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-size: cover;
    pointer-events: none;
  }

  .playButton {
    position: absolute;
    top: 44.6%;
    left: 53.3%;
    transform: translate(-50%, -50%);

    &.small {
      width: vw_d(77) !important;
      height: vw_d(77) !important;

      &:before {
        width: vw_d(26);
        height: vw_d(26);
      }
    }

    &.centered {
      top: 50%;
      left: 50%;
    }

    &:hover {
      pointer-events: auto;
      cursor: pointer;
    }
  }

  .castom-controls {
    position: absolute;
    bottom: vw_d(50);
    right: vw_d(30);
    z-index: 3; 

    .control-button {
      position: relative;
      width: vw_d(72) !important;
      height: vw_d(72) !important;
      border-radius: 50%;
      z-index: 100;
      overflow: hidden;
      margin-left: vw_d(30);

      &:hover {
        &:after {
          background-color: rgba(255, 255, 255, 0.39);
        }
      }

      &.small {
        width: vw_d(36) !important;
        height: vw_d(36) !important;
        svg {
          width: vw_d(18.5);
          height: vw_d(18.5);
        }
      }
  
      svg {
        width: vw_d(37);
        height: vw_d(37);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.19);
        filter: blur(12px) saturate(50%);
        pointer-events: none;
        transition: 0.45s;
      }
    }
  }
}
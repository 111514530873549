@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.blurred-block {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  filter: saturate(-50%);
  backdrop-filter: blur(6px);
}

.form-wrapper {
  display: flex;
  position: absolute;
  width: 737px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  max-height: 90%;
  border: 1px solid #979797;
  border-radius: 12px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.55);

  @include screen('mobile') {
    width: auto;
    max-height: 85%;
    left: 25px;
    right: 25px;
    top: 52px;
    transform: translate(0, 0);
    border: none;
  }

  .form-wrapper-i {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 60px;
    margin-bottom: 50px;
    overflow: auto;
    @include screen('mobile') {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.contentWrapper {
  position: relative;
  height: auto;
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 37px;
  padding-right: 42px;
  z-index: 20;

  @include screen('mobile') {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  @include screen('mobile-large') {
    max-width: 400px;
    margin: 0 auto;
  }

  h1 {
    display: block;
    width: 100%;
    padding-bottom: 22px;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0.0453125vw;
    font-family: $regular-font;
    font-weight: 600;
    color: #fff;
    @include screen('mobile') {
      padding-bottom: 12px;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 1.92px;
      font-weight: 700;
    }
  }

  h3 {
    display: block;
    width: 100%;
    padding-bottom: 10px;
    font-size: 26px;
    line-height: 37px;
    font-family: $regular-font;
    font-weight: 400;
    color: #fff;
    text-align: center;
    @include screen('mobile') {
      font-size: 18px;
      line-height: 29px;
    }
  }

  .form {
    padding-right: 51px;
    @include screen('mobile') {
      flex-grow: 1;
      padding: 0 12px;
    }
    .fieldArrayWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .row {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include screen('mobile') {
          margin-bottom: 66px;
          padding-right: 68px;
        }
        &:after {
          @include screen('mobile') {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -41px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.4);
          }
        }
        .fields {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;

          .fieldWrapper {
            width: 294px;
            @include screen('mobile') {
              width: 100%;
            }
          }
        }
        .deleteBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -52px;
          top: 37px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.3);
          cursor: pointer;
          @include screen('mobile') {
            right: 0;
            top: 55px;
            width: 48px;
            height: 48px;
          }
          .trashIcon {
            width: 17px;
            @include screen('mobile') {
              width: 22px;
            }
            svg {
              display: block;
              width: 100% !important;
            }
          }
        }
      }

      .addBtnWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 41px;
        @include screen('mobile') {
          justify-content: center;
          margin-top: 17px;
        }
        .addBtn {
          display: flex;
          align-items: center;
          position: relative;
          height: 48px;
          padding: 0 19px 0 63px;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 24px;
          font-size: 16px;
          line-height: 23px;
          font-family: $regular-font;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.47);
          cursor: pointer;
          @include screen('mobile') {
            padding: 0 27px 0 68px;
            font-size: 18px;
            font-weight: 700;
          }
          .plus {
            position: absolute;
            left: 0;
            top: 0;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #0757d3;
            &:before,
            &:after {
              content: '';
              position: absolute;
              left: 50%;
              top: 50%;
              width: 13px;
              height: 3px;
              background-color: #fff;
            }
            &:before {
              transform: translate(-50%, -50%) rotate(90deg);
            }
            &:after {
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .submitWrapper {
    position: relative;
    width: 100%;
    margin-top: 124px;
    @include screen('mobile') {
      position: relative;
      margin-top: 40px;
      padding: 0 12px;
    }
    .submitButton {
      display: block;
      width: 252px !important;
      height: 65px;
      margin: 0 auto;
      border-radius: 33px;
      font-size: 28px;
      letter-spacing: 0.54px;
      font-family: $regular-font;
      font-weight: 700;
      color: #fff;
      @include screen('mobile') {
        width: 100% !important;
        max-width: 338px;
        height: 60px;
        border-radius: 30px;
        font-size: 20px;
        letter-spacing: 0.39px;
      }
      .btnText {
        opacity: 1;
        visibility: visible;
        transition: all ease 0.2s;
        &.loading {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

.ndaWrapper {
  position: relative;
  z-index: 20;
}

.closeBtnMobile {
  display: none;
  @include screen('mobile') {
    display: block;
    position: fixed;
    width: 21px;
    height: 21px;
    left: 25px;
    top: 25px;
    z-index: 10;
  }
  @include screen('mobile-large') {
    left: calc((100vw - 400px) / 2);
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: #fff;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.closeBtn {
  position: fixed;
  width: 21px;
  height: 21px;
  right: 41px;
  top: 35px;
  z-index: 2;
  cursor: pointer;
  @include screen('mobile') {
    display: none;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: #fff;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.taxDocCloseBtn {
  position: fixed;
  z-index: 3;
  top: 28px;
  right: 25px;
  width: 21px;
  height: 21px;
  transform-origin: center center;
  cursor: pointer;

  @include screen('mobile') {
    left: 25px;
    top: 25px;
    right: auto;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 3px;
    background-color: #fff;
    border-radius: 2px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.loaderWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.55);
  transition: all ease 0.2s;
  &.loading {
    opacity: 1;
    visibility: visible;
  }
  .loader {
    width: 28px;
    height: 28px;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

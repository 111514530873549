@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.slide-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  border-radius: vw_d(8);
  overflow: hidden;
  transition: all ease 0.3s;
  cursor: pointer;
  &.normal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.first-on-screen {
    left: 0%;
    top: 50%;
    transform: translate(0%, -50%);
    transform-origin: center left;
  }
  &.last-on-screen {
    right: 0%;
    top: 50%;
    transform: translate(0%, -50%);
    transform-origin: center right;
  }
  &.active {
    width: vw_d(689);
    height: vw_d(502);
    background-color: black;
    // transform: scale(1.4);
    -webkit-box-shadow: 0px 0px vw_d(41) 0px rgba(255, 255, 255, 0.19);
    -moz-box-shadow: 0px 0px vw_d(41) 0px rgba(255, 255, 255, 0.19);
    box-shadow: 0px 0px vw_d(41) 0px rgba(255, 255, 255, 0.19);
    z-index: 1;
    .media-wrapper {
      height: vw_d(387);
      .video-block {
        opacity: 1;
        visibility: visible;
      }
      .preview-image {
        opacity: 0;
        visibility: hidden;
      }
      .progress {
        height: vw_d(8);
        border-top-right-radius: vw_d(4);
        border-bottom-right-radius: vw_d(4);
      }
    }
    .bottom-info {
      height: vw_d(124);
      padding: 0 vw_d(20);
      border: solid vw_d(2) rgba(255, 255, 255, 0.19);
      border-top: none;
      .info {
        width: 100%;
        max-width: vw_d(480);
        .name {
          margin-bottom: 0;
          padding-bottom: vw_d(3.55);
          font-size: vw_d(27.72);
          line-height: 1.426;
        }
        .description {
          font-size: vw_d(17.82);
          line-height: 1;
          font-family: $regular-font;
        }
      }
    }
  }
  .media-wrapper {
    position: relative;
    height: vw_d(255);
    width: 100%;
    border-radius: vw_d(8);
    overflow: hidden;
    .preview-image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      color: #dbc54a;
      transition: all ease 0.4s;
    }
    .video-block {
      position: relative;
      width: 100%;
      height: 100%;
      // background-color: cadetblue;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: vw_d(22);
      font-family: $regular-font;
      font-weight: 600;
      color: white;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.4s;
    }
    .progress {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: vw_d(5);
      background-color: rgba(255, 255, 255, 0.5);
      transition: all ease 0.4s;
      .progress-inner {
        position: absolute;
        height: 100%;
        background-color: $science-blue;
        border-top-right-radius: vw_d(2.5);
        border-bottom-right-radius: vw_d(2.5);
      }
    }
  }
  .bottom-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: vw_d(95);
    margin-top: vw_d(-8);
    transition: all ease 0.4s;
    border-bottom-right-radius: vw_d(8);
    border-bottom-left-radius: vw_d(8);

    .info {
      width: 100%;
      max-width: vw_d(275);
      .name {
        margin-bottom: vw_d(3.34);
        font-size: vw_d(28.62);
        letter-spacing: vw_d(0.58);
        line-height: 1.2;
        font-family: $regular-font;
        font-weight: 600;
        color: white;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .description {
        font-size: vw_d(14);
        font-size: vw_d(15);
        font-family: $regular-font;
        font-weight: 300;
        color: white;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .btns {
      position: relative;
      .rating-btn {
        display: flex;
        align-items: center;
        span {
          margin-right: vw_d(12.58);
          font-size: vw_d(22);
          letter-spacing: vw_d(0.43);
          font-family: $regular-font;
          font-weight: 500;
          color: white;
        }
      }
      .see-more-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0%, -50%);
        .icon-eye {
          display: inline-block;
          width: vw_d(35);
          height: vw_d(30);
          margin-right: vw_d(14);

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

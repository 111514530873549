@import 'client/styles/mixins.scss';
@import 'routes/client/styles/theme.scss';

.header {
  position: relative;
  padding: 15px 0;
  height: $header-height-mobile;
  background: linear-gradient(180deg, #000 0%, transparent 100%);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-button {
  width: 30px;
  height: 26px;
}

.menu-button,
.back-button,
.search-button {
  padding: 0;
}

.hero {
  position: absolute;
  min-width: 221px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include screen('mobile-extra-small') {
    width: 175px;
    min-width: auto;
  }
}

.category {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  @include screen('mobile-extra-small') {
    font-size: 14px;
  }
}

.search {
  position: relative;
  height: 29px;
  width: 100%;

  input {
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: white;
    transition: opacity 0.3s linear;

    @include screen('mobile-extra-small') {
      width: 87%;
      font-size: 14px;
    }
  }

  .underline {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transform: translateX(-50%);
    transition: transform 0.3s ease;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 0;
    width: 17px;
    height: 17px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 9px;
    transition: opacity, visibility;
    transition-duration: 0.3s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 9px;
      height: 1px;
      background-color: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.hero-transition {
  $transition-duration: 300ms;
  $transition-timing-function: linear;

  &-forward {
    &-appear {
      opacity: 0;
      transform: translate(-50%, -150%);
    }

    &-appear-active {
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};
    }

    &-appear-done {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &-enter {
      opacity: 0;
      transform: translate(-50%, -150%);
    }

    &-enter-active {
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};
    }

    &-enter-done {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &-exit {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &-exit-active {
      opacity: 0;
      transform: translate(-50%, 50%);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};
    }

    &-exit-done {
      opacity: 0;
      transform: translate(-50%, 50%);
    }
  }

  &-backward {
    &-appear {
      opacity: 0;
      transform: translate(-50%, 50%);
    }

    &-appear-active {
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};
    }

    &-appear-done {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &-enter {
      opacity: 0;
      transform: translate(-50%, 50%);
    }

    &-enter-active {
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};
    }

    &-enter-done {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &-exit {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &-exit-active {
      opacity: 0;
      transform: translate(-50%, -150%);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};
    }

    &-exit-done {
      opacity: 0;
      transform: translate(-50%, -150%);
    }
  }

  &-search {
    &-appear {
      opacity: 0;

      .underline {
        width: 0;
      }
    }

    &-appear-active {
      opacity: 1;
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};

      .underline {
        width: 100%;
        transition: opacity, transform, width;
        transition-duration: #{$transition-duration};
        transition-timing-function: #{$transition-timing-function};
      }
    }

    &-appear-done {
      opacity: 1;

      .underline {
        width: 100%;
      }
    }

    &-enter {
      opacity: 0;

      .underline {
        width: 0;
      }
    }

    &-enter-active {
      opacity: 1;
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};

      .underline {
        width: 100%;
        transition: opacity, transform, width;
        transition-duration: #{$transition-duration};
        transition-timing-function: #{$transition-timing-function};
      }
    }

    &-enter-done {
      opacity: 1;

      .underline {
        width: 100%;
      }
    }

    &-exit {
      opacity: 1;

      .underline {
        width: 100%;
      }
    }

    &-exit-active {
      opacity: 0;
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};

      .underline {
        width: 0;
        transition: opacity, transform, width;
        transition-duration: #{$transition-duration};
        transition-timing-function: #{$transition-timing-function};
      }
    }

    &-exit-done {
      opacity: 0;

      .underline {
        width: 0;
      }
    }
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.non-mandatory {
  padding-bottom: 20px;
  font-size: 20px;
  letter-spacing: 0.39px;
  line-height: 31.46px;
  font-family: $regular-font;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.containerI {
  @include screen('mobile') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 125px;
    overflow: auto;
  }
}

.select {
  position: relative;
  width: 658px;
  height: auto;
  min-height: 59px;
  margin: 62px auto 50px;
  z-index: 2;

  @include screen('mobile') {
    width: 100%;
    max-width: 340px;
    margin: 30.54px auto 0px;
  }

  .dropdownIndicator {
    width: 10px;
    height: 10px;
    margin-right: 34px;
    margin-top: 2px;

    @include screen('mobile-extra-small') {
      margin-right: 20px;
    }

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .multivalueRemove {
    width: 10px;
    height: 10px;
    margin: 0 7px;
    position: relative;
    cursor: pointer;
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 12px;
      height: 2px;
      background-color: #000;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.continue-btn-wrapper {
  @include screen('mobile') {
    position: absolute;
    bottom: 39px;
    width: 100%;
    left: 0;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.invest-now {
  position: relative;
  min-height: vw_d(643);
  padding: vw_d(49) 0 0;
  margin-bottom: vw_d(130);
  background-color: $primary-color;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 1) 100%);
    z-index: 0;
  }

  .slider-wrapper {
    position: relative;
    margin-top: vw_d(-20);

    :global {
      .swiper-container {
        padding: 0 vw_d(50) vw_d(75) vw_d(55);
      }

      .swiper-wrapper {
        padding: vw_d(20) 0;
      }
    }

    .nav-btn {
      position: absolute;
      top: vw_d(0);
      bottom: vw_d(0);
      width: vw_d(155);
      // background-color: red;
      z-index: 10;
      transition: all 0.3s;
      cursor: pointer;

      &.hide {
        opacity: 0;
        visibility: hidden;
      }

      &.next {
        right: 0;
        background: rgb(20, 35, 59);
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(20, 35, 59, 1) 100%);

        .arrow {
          position: absolute;
          width: vw_d(27);
          height: vw_d(46);
          top: calc(50% - 37px);
          left: 65%;
          transform: translate(0%, -50%);
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: vw_d(32);
            height: vw_d(6);
            background-color: #fff;
            border-radius: vw_d(3);
          }

          &:before {
            top: vw_d(11);
            transform: rotate(45deg);
          }

          &:after {
            top: vw_d(31);
            transform: rotate(-45deg);
          }
        }
      }
      &.prev {
        left: 0;
        background: rgb(20, 35, 59);
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(20, 35, 59, 1) 100%);

        .arrow {
          position: absolute;
          width: vw_d(27);
          height: vw_d(46);
          top: calc(50% - 37px);
          left: 15%;
          transform: translate(0%, -50%) rotate(180deg);
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: vw_d(32);
            height: vw_d(6);
            background-color: #fff;
            border-radius: vw_d(3);
          }

          &:before {
            top: vw_d(11);
            transform: rotate(45deg);
          }

          &:after {
            top: vw_d(31);
            transform: rotate(-45deg);
          }
        }
      }
    }

    .slide {
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 vw_d(50) vw_d(25) vw_d(68);
    z-index: 1;

    .slide {
      margin: 0 vw_d(24) vw_d(70);

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .title {
    position: relative;
    font-size: vw_d(35);
    line-height: 1.143;
    letter-spacing: 0.3px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: vw_d(67);
    padding-left: vw_d(78);
    z-index: 1;
  }
}

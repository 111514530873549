@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.pop-up-manager {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.bg {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($background-color, 0.8);

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    bottom: 100%;
    background: linear-gradient(0deg, rgba($background-color, 0.9), rgba($background-color, 0) 100%);
  }

  &::after {
    top: 100%;
    background: linear-gradient(180deg, rgba($background-color, 0.9), rgba($background-color, 0) 100%);
  }
}

.content-wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  // margin-top: 28px;
  margin-bottom: 0;
  z-index: 2;
}

.close-btn {
  position: absolute;
  z-index: 3;
  top: 28px;
  right: 0;
  width: 21px;
  height: 21px;
  transform-origin: center center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 3px;
    background-color: #fff;
    border-radius: 2px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.content {
  position: relative;
  flex: 1;
  z-index: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 #{$content-wrap-margin};
  margin: 0 -#{$content-wrap-margin};
}

.container-transition {
  $transition-duration: 650ms;

  &-enter {
    .bg {
      transform: translate3d(0, -200%, 0);
    }

    .content-wrap {
      opacity: 0;

      .close-btn {
        transform: scale(0.25);
      }
    }
  }

  &-enter-active {
    .bg {
      transform: translate3d(0, 0, 0);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
    }

    .content-wrap {
      opacity: 1;
      transition: opacity, transform;
      transition-duration: #{$transition-duration};

      .close-btn {
        transform: scale(1);
        transition: opacity, transform;
        transition-duration: #{$transition-duration * 0.6};
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1.75);
      }
    }
  }

  &-enter-done {
    .bg {
      transform: translate3d(0, 0, 0);
    }

    .content-wrap {
      opacity: 1;

      .close-btn {
        transform: scale(1);
      }
    }
  }

  &-exit {
    .bg {
      transform: translate3d(0, 0, 0);
    }

    .content-wrap {
      opacity: 1;

      .close-btn {
        transform: scale(1);
      }
    }
  }

  &-exit-active {
    .bg {
      transform: translate3d(0, 200%, 0);
      transition: opacity, transform;
      transition-duration: #{$transition-duration};
    }

    .content-wrap {
      opacity: 0;
      transition: opacity, transform;
      transition-duration: #{$transition-duration};

      .close-btn {
        transform: scale(0.25);
        transition: opacity, transform;
        transition-duration: #{$transition-duration * 0.5};
      }
    }
  }

  &-exit-done {
    .bg {
      transform: translate3d(0, calc(100% + 200px), 0);
    }

    .content-wrap {
      opacity: 0;

      .close-btn {
        transform: scale(0.25);
      }
    }
  }
}

.content-transition {
  $transition-duration: 300ms;

  &-appear {
    opacity: 0;
  }

  &-appear-active {
    opacity: 1;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-appear-done {
    opacity: 1;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
  }

  &-exit-done {
    opacity: 0;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.content {
  position: relative;
  z-index: 2;
  background-color: #000;
  flex-grow: 1;

  @include screen('mobile') {
    width: 100%;
    padding-bottom: 150px;
  }

  @include screen('mobile-extra-small') {
    padding-bottom: 50px;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.restricted-block {
  position: relative;

  .btnWrapper {
    position: relative;
    .tooltip {
      position: absolute;
      z-index: 10;
      top: vw_d(-18);
      left: 50%;
      width: vw_d(250);
      padding: vw_d(10);
      background-color: rgba(0, 0, 0, 1);
      border-radius: vw_d(4);
      font-size: vw_d(14);
      line-height: vw_d(18);
      letter-spacing: vw_d(0.1);
      text-align: center;
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, calc(-100% - vw_d(10)));
      transition: all 0.2s ease;
      @include screen('mobile') {
        top: vw(-18);
        width: vw(250);
        padding: vw(10);
        border-radius: vw(4);
        font-size: vw(14);
        line-height: vw(18);
        letter-spacing: vw(0.1);
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 100%;
        width: 1px;
        height: 1px;
        border: vw_d(10) solid transparent;
        border-top-color: rgba(0, 0, 0, 1);
        transform: translate(vw_d(-5), 0);
        @include screen('mobile') {
          border: vw(10) solid transparent;
          border-top-color: rgba(0, 0, 0, 1);
          transform: translate(vw(-5), 0);
        }
      }
    }
    &:hover,
    &:focus {
      .tooltip {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, calc(-100% - vw_d(0)));
      }
    }
  }

  &-overlay {
    position: absolute;
    z-index: 100;
    top: vw_d(-20);
    left: vw_d(-20);
    right: vw_d(-20);
    bottom: vw_d(-20);
    background-color: rgba(0, 0, 0, 0);

    &-content {
      position: absolute;
      z-index: 200;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: vw_d(12.85);
      padding: vw_d(30);
      border-radius: vw_d(7);

      p {
        width: vw_d(240);
        font-size: vw_d(19.02);
        line-height: vw_d(24);
        text-align: center;
        font-weight: 600;

        @include screen('mobile') {
          width: 230px;
          font-size: 19px;
          line-height: 1.266;
        }
      }
    }
  }

  &-fallback {
    filter: blur(7px);
  }

  &-unlock-btn {
    display: flex;
    align-items: center;
    gap: vw_d(7);

    padding: 0 vw_d(30);
    height: vw_d(42.33);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: vw_d(21.17);
    backdrop-filter: blur(10px);
    transition: background-color 0.3s;

    font-size: vw_d(15.45);
    letter-spacing: vw_d(0.3);
    font-weight: 600;
    line-height: 0.7;
    white-space: nowrap;
    text-transform: uppercase;

    @include screen('mobile') {
      gap: 7px;
      padding: 0 22px;
      height: 42px;
      border-radius: 21px;

      font-size: 15.45px;
      letter-spacing: 0.3px;
    }

    img {
      display: block;
      width: vw_d(12);
      height: vw_d(16);

      @include screen('mobile') {
        width: 12px;
        height: 16px;
      }
    }

    span {
      margin-top: vw_d(2);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &.with-pop-up {
    .restricted-block-overlay-content {
      top: vw_d(100);
      left: vw_d(100);
      transform: translate(0, 0);
      background-color: rgba(255, 255, 255, 0.225);
      backdrop-filter: blur(7px);

      @include screen('mobile') {
        top: 68px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 7px;
        gap: 12px;
        padding: 31px 31px 22px;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.news-wrapper {
  position: absolute;
  left: vw_d(37);
  top: vw_d(165);
  right: vw_d(20.53);
  bottom: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: vw_d(6);
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: vw_d(3);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.news {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: vw_d(20.53);

  .news-tem {
    width: 42%;
  }
}

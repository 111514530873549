@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.up-btn {
  position: relative;
  border-radius: 50%;
  background-color: rgba(37, 224, 97, 0.25);
  &.large {
    width: vw_d(41);
    height: vw_d(41);
  }
  &.small {
    width: vw_d(39.64);
    height: vw_d(39.64);
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: vw_d(5) solid transparent;
    border-right: vw_d(5) solid transparent;
    border-bottom: vw_d(8) solid #fff;
  }
}

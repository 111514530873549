@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.news-item {
  display: flex;
  width: vw_d(560.92);
  margin-bottom: vw_d(84);

  a {
    display: flex;
    width: 100%;
  }

  .image {
    flex-shrink: 0;
    width: vw_d(140);
    height: vw_d(140);
    margin-right: vw_d(31.33);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: vw_d(4.4);
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    .title {
      margin-bottom: vw_d(4);
      font-size: vw_d(20);
      line-height: 1.142;
      color: white;
      font-family: $regular-font;
      font-weight: 300;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      span {
        position: relative;
        display: inline-block;
        padding-left: vw_d(20);
        &:before {
          content: '';
          position: absolute;
          width: vw_d(3);
          height: vw_d(3);
          left: vw_d(5);
          top: vw_d(10);
          background-color: #fff;
          border-radius: 50%;
        }
      }
    }
    .description {
      font-size: vw_d(20);
      line-height: 1.362;
      color: white;
      font-family: $regular-font;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      span {
        font-family: $regular-font;
        font-weight: 300;
      }
    }
  }
}

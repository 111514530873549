@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

footer {
  padding: vw_d(33) vw_d(84) vw_d(37.85) vw_d(98);
  background-color: #323232;
  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left-part {
      padding-top: vw_d(24);
      .logo-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 3;
        .logo {
          display: flex;
          align-items: center;
          img {
            display: block;
            width: vw_d(183);
          }
        }
        .divider {
          width: vw_d(1);
          height: vw_d(25);
          margin: 0 vw_d(10) 0 vw_d(11.86);
          background-color: white;
        }
        .logo-text {
          width: vw_d(87);
          font-size: vw_d(12);
          line-height: vw_d(16);
          color: white;
          font-family: $regular-font;
          font-weight: 400;
        }
      }

      .copyright-text {
        padding-top: vw_d(45);
        font-family: $regular-font;
        font-size: vw_d(14);
        line-height: 1.786;
        color: #fff;
      }
    }

    .menu {
      display: flex;
      padding-top: vw_d(10);
      .links,
      .docs {
        display: flex;
        flex-direction: column;
        .nav-link,
        .doc-link {
          font-family: $regular-font;
          font-size: 13px;
          line-height: 2.7;
          color: #cecece;
          white-space: nowrap;
          transition: all 0.3s ease;
          span {
            position: relative;
            display: inline-block;
            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              height: vw_d(1);
              left: 0;
              bottom: vw_d(10);
              background-color: #aeaeae;
              opacity: 0;
              transition: all 0.3s ease;
            }
          }
          &:hover {
            color: #aeaeae;
            span {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }

      $m: vw_d(105); // space beatween columns
      $w: vw_d(270); // column width
      .docs {
        width: calc(($w * 2) + #{$m});
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        grid-column-gap: vw_d(105);
        grid-row-gap: 0px;
        grid-auto-rows: min-content;
      }

      .links {
        width: $w;
        margin-right: $m;
      }
    }

    .footer-socials {
      display: flex;
      align-items: center;
      padding-top: vw_d(5);
      a {
        width: vw_d(36);
        height: vw_d(36);
        margin-right: vw_d(26);
        &:last-child {
          margin-right: 0;
        }
        i {
          display: block;
          width: 100%;
          height: 100%;
          svg {
            display: block;
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.home {
  padding-top: vw_d(48);

  @include screen('mobile') {
    padding-top: 21px;
  }
}

.pending-action {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: vw_d(49) vw_d(72) vw_d(49) vw_d(50);
  margin-bottom: vw_d(60);
  background-color: rgba(#68a4ff, 0.23);
  color: white;
  border: 1px solid #0757d3;
  border-radius: vw_d(8);

  @include screen('mobile') {
    display: block;
    padding: 24px 35px 24px 32px;
    margin-bottom: 24px;
    border-radius: 8px;
  }

  @include screen('mobile-extra-small') {
    padding: 20px;
  }

  .message {
    flex-grow: 1;
    max-width: vw_d(600);

    @include screen('mobile') {
      min-width: 100%;
      padding-bottom: 24px;
    }
  }

  .button {
    padding: 0 vw_d(30);

    @include screen('mobile') {
      width: 100%;
      height: 50px;
      padding: 0 10px;
      font-size: 16px;
      border-radius: 33px;
    }

    @include screen('mobile-extra-small') {
      font-size: 14px;
    }
  }
}

.news-and-pie-chart {
  display: flex;
  justify-content: space-between;
  height: vw_d(600);
  margin-bottom: vw_d(60);
  gap: vw_d(70);

  @include screen('mobile') {
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: 45px;
    gap: 61px;
  }

  .news,
  .pie-chart {
    width: 50%;
    background-color: rgba(#fff, 0.1);
    height: 100%;

    @include screen('mobile') {
      width: 100%;
    }

    .no-chart-data {
      @include screen('mobile') {
        min-height: 240px;
        width: 100%;
      }
      &-top {
        position: absolute;
        top: 0;
        padding: vw_d(220) vw_d(65) vw_d(20);
        // backdrop-filter: blur(7px);
        height: 100%;
        text-align: center;

        @include screen('mobile') {
          padding: 60px 35px 24px 32px;
        }

        p {
          font-size: vw_d(26);
          line-height: 1.692;
          letter-spacing: 0.5px;
          padding-bottom: vw_d(24);
          white-space: break-spaces;

          @include screen('mobile') {
            font-size: 16px;
            padding-bottom: 24px;
            text-align: left;
          }
        }
      }

      .chart-img-default {
        position: absolute;
        bottom: 0;
        object-fit: cover;
        width: 100%;
      }

      .button {
        display: inline-block;
        width: vw_d(186);
        height: vw_d(50);
        border-radius: vw_d(33);
        font-size: vw_d(15.45);
        line-height: vw_d(50);
        letter-spacing: vw_d(0.3);
        font-weight: 700;
        background-color: $primary-color;

        @include screen('mobile') {
          width: 100%;
          height: 50px;
          font-size: 16px;
          line-height: 50px;
          border-radius: 33px;
        }
      }
    }
  }

  .news {
    &-list {
      position: relative;

      @include screen('mobile') {
        max-height: 493px;
      }
    }

    &-item {
      position: relative;
      width: 100%;
      display: flex;
      padding: vw_d(24) vw_d(80) vw_d(24) vw_d(24);

      @include screen('mobile') {
        display: block;
        padding: 24px 35px 24px 32px;
      }

      @include screen('mobile-extra-small') {
        padding: 20px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(#fff, 0.15) 0%, rgba(#000, 0) 100%);
      }
    }

    .date {
      width: vw_d(108);
      min-width: vw_d(108);
      font-size: vw_d(14);
      line-height: vw_d(24);
      letter-spacing: vw_d(0.35);

      @include screen('mobile') {
        width: 100%;
        font-size: 14px;
        line-height: 1.714;
        letter-spacing: 0.35px;
        margin-bottom: 14px;
      }
    }

    .content {
      flex-grow: 1;
      font-size: vw_d(16);
      line-height: vw_d(24);
      letter-spacing: vw_d(0.4);

      @include screen('mobile') {
        font-size: 16px;
        line-height: 1.438;
        letter-spacing: normal;
      }

      @include screen('mobile-extra-small') {
        font-size: 14px;
      }
    }

    &-title {
      font-weight: 600;
      line-height: vw_d(25.5);
      font-size: vw_d(16);
      margin-bottom: vw_d(15);

      @include screen('mobile') {
        font-size: 16px;
        line-height: 1.438;
        margin-bottom: 10px;
      }
    }
  }

  .pie-chart {
    @include screen('mobile') {
      border-radius: 8px;
      padding-bottom: 20px;
    }

    .chart {
      height: calc(100% - vw_d(50));
      @include screen('mobile') {
        height: 190px;
        max-width: 190px;
        margin: 0 auto;
      }
    }
  }
}

.reports {
  position: relative;
  border-radius: vw_d(8);
  // height: vw_d(500); // if we need scroll
  margin-bottom: vw_d(110);
  overflow: hidden;

  @include screen('mobile') {
    border-radius: 0;
  }

  .report {
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: vw_d(17) vw_d(57) vw_d(17) vw_d(50);

      @include screen('mobile') {
        display: block;
        padding: 0 29px 0 0;
      }

      @include screen('mobile-extra-small') {
        padding: 0 20px 0 0;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(#fff, 0.15) 0%, rgba(#000, 0) 100%);

        @include screen('mobile') {
          display: none;
        }
      }
    }

    &-date,
    &-filename {
      font-size: vw_d(20);
      line-height: vw_d(37.5);
      letter-spacing: vw_d(0.5);

      @include screen('mobile') {
        font-size: 14px;
        line-height: 1.143;
        letter-spacing: 0.35px;
      }
    }

    &-date {
      width: vw_d(236);

      @include screen('mobile') {
        position: relative;
        width: 100%;
        padding: 40px 0 19px 33px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, rgba(#fff, 0.15) 0%, rgba(#000, 0) 100%);
        }
      }

      @include screen('mobile-extra-small') {
        padding: 35px 0 19px 20px;
      }
    }

    &-title {
      flex-grow: 1;
      font-size: vw_d(20);
      line-height: vw_d(37.5);
      letter-spacing: vw_d(0.5);

      @include screen('mobile') {
        font-size: 16px;
        line-height: 1.438;
        letter-spacing: normal;
      }

      @include screen('mobile-extra-small') {
        font-size: 14px;
      }
    }

    &-mobile-row {
      position: relative;
      padding: 31px 50px 21px 33px;

      @include screen('mobile-extra-small') {
        padding: 20px 45px 20px 20px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(#fff, 0.15) 0%, rgba(#000, 0) 100%);
      }

      .download-btn {
        position: absolute;
        top: 26px;
        right: 0;

        @include screen('mobile-extra-small') {
          top: 20px;
        }
      }
    }
  }

  .filename-wrap {
    display: flex;
    align-items: center;
    gap: vw_d(17);
    width: 40%;

    @include screen('mobile') {
      width: 100%;
      padding-bottom: 6px;
    }

    img {
      width: vw_d(18);
    }
  }

  .download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: vw_d(56);
    height: vw_d(56);
    border-radius: 50%;
    background-color: rgba(#fff, 0.1);

    @include screen('mobile') {
      width: 40px;
      height: 40px;
    }

    img {
      width: vw_d(26);

      @include screen('mobile') {
        width: 18px;
      }
    }

    &:hover {
      background-color: rgba(#fff, 0.2);
    }
  }
}

.title {
  font-size: vw_d(45);
  line-height: vw_d(50);
  letter-spacing: vw_d(0.87);
  font-weight: 600;
  margin-bottom: vw_d(22);

  @include screen('mobile') {
    font-size: 22px;
    line-height: 1.273;
    letter-spacing: 1.3px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  @include screen('mobile-extra-small') {
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 1.1px;
  }
}

.text {
  font-size: vw_d(16);
  line-height: vw_d(28);
  letter-spacing: vw_d(0.4);

  @include screen('mobile') {
    font-size: 16px;
    line-height: 1.438;
    letter-spacing: normal;
  }

  @include screen('mobile-extra-small') {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 1.1px;
  }
}

.no-data-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 vw_d(35);
  width: 45%;
  margin: 0 auto;

  @include screen('mobile') {
    width: 100%;
    padding: 0 35px;
  }

  @include screen('mobile-extra-small') {
    padding: 0 20px;
  }

  p {
    // font-size: vw_d(26);
    font-size: 1.0416666667vw;
    line-height: 1.692;
    letter-spacing: 0.5px;
    text-align: center;
    padding-bottom: 2.1vw;
    padding-top: 1vw;
    white-space: break-spaces;

    @include screen('mobile') {
      font-size: 16px;
      padding-bottom: 24px;
      text-align: left;
    }

    @include screen('mobile-extra-small') {
      font-size: 16px;
      padding-bottom: 22px;
    }
  }

  .button {
    display: inline-block;
    width: vw_d(186);
    height: vw_d(50);
    margin-bottom: vw_d(30);
    border-radius: vw_d(33);
    font-size: vw_d(15.45);
    line-height: vw_d(50);
    letter-spacing: vw_d(0.3);
    font-weight: 700;
    text-align: center;
    background-color: $primary-color;

    @include screen('mobile') {
      width: 100%;
      height: 50px;
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 50px;
      border-radius: 33px;
    }
  }
}

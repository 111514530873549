@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.menu-wrapper {
  position: fixed;
  width: vw_d(547);
  height: 100%;
  left: 0;
  top: 0;
  padding: 101px vw_d(22) 0 vw_d(43);
  border-right: vw_d(1) solid rgba(255, 255, 255, 0.38);
  background-color: rgba(0, 0, 0, 0.87);
  z-index: 1;

  &.with-opened-banner {
    padding-top: calc(101px + vw_d(48));
  }

  .loader {
    height: vw_d(50);
  }

  .content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: auto;
    z-index: 2;

    &::-webkit-scrollbar {
      width: vw_d(6);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: vw_d(3);
    }

    .menu,
    .categories {
      .title {
        font-size: vw_d(25);
        line-height: 3.6;
        color: rgba(255, 255, 255, 0.48);
        font-family: $regular-font;
        font-weight: 700;
        text-transform: uppercase;
      }
      .links {
        .navlink {
          display: block;
          font-size: vw_d(35);
          line-height: 2.571;
          color: #fff;
          font-family: $regular-font;
          font-weight: 700;
          text-transform: uppercase;
          transition: color ease 0.3s;
          &.active-link {
            color: #68a4ff;
          }
          &:hover {
            color: #68a4ff;
          }
        }
      }
    }
    .menu {
      padding-bottom: vw_d(50);
    }
  }

  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    filter: saturate(50%);
    backdrop-filter: blur(7.8px);
    background-color: rgba(1, 1, 1, 0.1);
    z-index: 1;
  }
}

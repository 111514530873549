@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.button-wrapper {
  width: 100%;
  .continue-button {
    display: block;
    width: 252px !important;
    height: 65px;
    margin: 0 auto;
    border-radius: 33px;
    font-size: 28px;
    letter-spacing: 0.54px;
    font-family: $regular-font;
    font-weight: 700;
    color: #fff;

    @include screen('mobile') {
      width: 100% !important;
      max-width: 338px;
      height: 60px;
      border-radius: 30px;
      font-size: 20px;
      letter-spacing: 0.39px;
    }

    @include screen('mobile-extra-small') {
      font-size: 18px;
      height: 50px;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

$disable-color: rgba(255, 255, 255, 0.48);
$option-active: rgba(7, 87, 211, 0.68);
$headerH: 110px;
$headerHMobile: 56px;

@mixin content-title {
  font-size: vw_d(20.09);
  line-height: 1.566;
  letter-spacing: vw_d(0.39);
  font-weight: 700;
}

.playlist {
  display: flex;
  flex-direction: column;
  gap: vw_d(40);
  width: 100%;
  max-width: vw_d(677);
  background-color: rgba(255, 255, 255, 0.08);

  @include screen('mobile') {
    display: block;
    padding: 0;
  }

  @include screen('mobile-large') {
    max-width: 500px;
    margin: 0 auto;
  }
}

.main-player-wrap {
  position: relative;
  max-width: 100%;
  // height: 100%;

  @include screen('mobile') {
    max-width: 100%;
    height: auto;
  }

  .main-player {
    position: relative;
    max-width: 100%;
    aspect-ratio: 16/9;

    @include screen('mobile') {
      max-width: 100%;
      height: auto;
    }

    &-play-icon {
      @include screen('mobile') {
        width: 49px !important;
        height: 49px !important;

        &:before {
          width: 19px;
          height: 19px;
        }
      }
    }

    & :global {
      #gallery-player {
        position: absolute;
        top: 0;
        width: 100%;
      }
      // .gallery-player-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
      //   padding-top: 0;
      //   overflow: hidden;
      //   height: 100%;
      // }
    }

    video {
      border-top-left-radius: vw_d(7);
      border-top-right-radius: vw_d(7);
      // object-fit: cover;

      // @media (max-aspect-ratio: 3/2) {
      //   height: 100% !important;
      //   width: auto;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }
    }
  }

  .current-title {
    width: 100%;
    padding: vw_d(18) vw_d(26) vw_d(18) vw_d(33);
    font-size: vw_d(30);
    line-height: 1.167;
    letter-spacing: vw_d(2.4);
    font-weight: 700;
    text-transform: uppercase;
    border-bottom-left-radius: vw_d(7);
    border-bottom-right-radius: vw_d(7);

    @include screen('mobile') {
      position: static;
      padding: 9px 37px 8px 39px;
      font-size: 16px;
      line-height: 1.438;
      letter-spacing: normal;
      font-weight: 600;
      text-transform: none;
      border-radius: 0;
    }
  }
}

.list-wrap {
  overflow: hidden;
}

.list {
  display: flex;
  width: 100%;
  margin-bottom: vw_d(-20);
  padding: 0 vw_d(20) vw_d(20);
  overflow-x: auto;
  overflow-y: hidden;
  background: linear-gradient(360deg, black, transparent);

  @include screen('mobile') {
    width: 100%;
    padding: 25px 37px 70px 37px;
    margin-left: 0;
  }

  &::-webkit-scrollbar {
    width: vw_d(6);
  }

  &-item {
    position: relative;
    min-width: vw_d(210);
    margin-right: vw_d(20);
    border-top-left-radius: vw_d(7);
    border-top-right-radius: vw_d(7);
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    @include screen('mobile') {
      display: flex;
      min-height: 90px;
      height: auto;
      margin-bottom: 14px;
      border: 0;
      overflow: hidden;
    }
  }

  &-title {
    padding: vw_d(6) vw_d(5) vw_d(6) vw_d(10);
    font-size: vw_d(16);
    line-height: 1.5;
    letter-spacing: 1.28px;
    font-weight: 500;
    border-bottom-left-radius: vw_d(4);
    border-bottom-right-radius: vw_d(4);
    display: -webkit-box;            /* Use box model */
    -webkit-box-orient: vertical;    /* Set box orientation to vertical */
    -webkit-line-clamp: 2;           /* Limit to 3 lines */
    overflow: hidden;                /* Hide overflowing text */
    text-overflow: ellipsis;         /* Add ellipsis at the end */
    max-height: 4.5em;               /* 3 lines, adjust based on line-height */

    @include screen('mobile') {
      width: 60%;
      padding: 39px 10px 13px 14px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1.12px;
      border-radius: 0;
      flex-grow: 1;
    }
  }
}

.is-loading {
  :after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: inherit;
    width: inherit;
    z-index: 1;
    @include blink-bg();

    @include screen('mobile') {
      width: 100%;
    }
  }
}

@import 'client/styles/mixins.scss';
@import 'client/styles/theme.scss';

.news-item {
  position: relative;
  padding: 16px 0 15px 0;
  border-bottom: 1px solid rgba(#fff, 0.1);

  a {
    display: flex;

    align-items: center;
    gap: 15px;
  }

  &-photo {
    width: 68px;
    min-width: 68px;
    height: 68px;
    border-radius: 4px;
    background-position: center;
    background-size: cover;
  }

  &-info {
    font-size: 16px;
  }

  &-title {
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    span {
      position: relative;
      display: inline-block;
      padding-left: 20px;
      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        left: 5px;
        top: 10px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }

  &-description {
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    span {
      font-weight: 300;
    }
  }
}

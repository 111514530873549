@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.news {
  padding-top: vw_d(150);
  .news-title {
    padding-bottom: vw_d(87);
    font-size: vw_d(32);
    line-height: 0.649;
    color: white;
    font-family: $regular-font;
    font-weight: 700;
  }
  .news-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .btn {
    padding: 0 vw_d(50);
    i {
      display: block;
      margin-top: vw_d(3);
      margin-left: vw_d(7);
      width: vw_d(8);
      height: vw_d(8);
      svg {
        display: block;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

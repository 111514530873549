@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.non-mandatory {
  padding-bottom: 20px;
  font-size: 20px;
  letter-spacing: 0.39px;
  line-height: 31.46px;
  font-family: $regular-font;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.containerI {
  @include screen('mobile') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 125px;
    overflow: auto;
  }
}

.field-wrapper {
  width: 658px;
  margin: 51.09px auto 50.92px;

  @include screen('mobile') {
    width: 100%;
    margin: 48.09px auto 0;
    padding: 0 12px;
  }

  label {
    position: relative;
    display: block;
    margin-bottom: 24px;
    cursor: pointer;

    @include screen('mobile') {
      margin-bottom: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .answer {
        background-color: rgba(255, 255, 255, 0.18);
      }
      input {
        &:checked ~ .answer {
          background-color: rgba(7, 87, 211, 0.38);
          .letter {
            background-color: #193360;
          }
        }
      }
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      &:checked ~ .answer {
        background-color: rgba(7, 87, 211, 0.38);
        .letter {
          background-color: #193360;
        }
      }
    }

    .answer {
      display: flex;
      background-color: rgba(255, 255, 255, 0.07);
      transition: all ease 0.3s;
      .letter {
        display: flex;
        justify-content: center;
        width: 60px;
        min-width: 60px;
        padding-top: 16px;
        background-color: #272728;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.22px;
        font-family: $regular-font;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        transition: all ease 0.3s;

        @include screen('mobile') {
          width: 40px;
          min-width: 40px;
          height: 40px;
          padding-top: 6px;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.22px;
        }
      }
      .text {
        flex-grow: 1;
        padding: 19px 24px 17px 17px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        font-family: $regular-font;
        font-weight: 400;
        color: #fff;

        @include screen('mobile') {
          padding: 8px 16px 8px 15px;
        }

        @include screen('mobile-extra-small') {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .other {
    height: 60px;
    position: relative;
    cursor: pointer;

    @include screen('mobile') {
      height: 40px;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.07);
      }
      .answer {
        background-color: rgba(7, 87, 211, 0.38);
        .letter {
          background-color: #193360;
        }
      }
      &:hover {
        .answer {
          background-color: rgba(7, 87, 211, 0.38);
          .letter {
            background-color: #193360;
          }
        }
      }
    }
    &:hover {
      .answer {
        background-color: rgba(255, 255, 255, 0.18);
      }
    }
    .answer {
      display: flex;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.07);
      transition: all ease 0.3s;

      .letter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 100%;
        min-width: 60px;
        background-color: #272728;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.22px;
        font-family: $regular-font;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        transition: all ease 0.3s;

        @include screen('mobile') {
          width: 40px;
          height: 40px;
          min-width: 40px;
        }
      }
      .text {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 19px 24px 17px 17px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        font-family: $regular-font;
        font-weight: 400;
        color: #fff;

        @include screen('mobile') {
          padding: 9px 0px 10px 14px;
        }

        @include screen('mobile-extra-small') {
          font-size: 16px;
          line-height: 22px;
        }

        span {
          flex-shrink: 0;
        }
        input {
          flex-grow: 1;
          display: block;
          height: 100%;
          padding: 0 10px;
          font-size: 18px;
          line-height: 60px;
          letter-spacing: -0.2px;
          font-family: $regular-font;
          font-weight: 400;
          color: #fff;

          @include screen('mobile') {
            width: 100%;
            padding: 0 10px;
            line-height: 20px;
          }

          @include screen('mobile-extra-small') {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.continue-btn-wrapper {
  @include screen('mobile') {
    position: absolute;
    bottom: 39px;
    width: 100%;
    left: 0;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.content-wrap {
  margin: 0 vw_d(199);

  @include screen('mobile') {
    margin: 0 37px;
  }

  @include screen('mobile-extra-small') {
    margin: 0 #{$content-wrap-margin - 10px};
  }
}

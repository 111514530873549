@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.avatar-uploader {
  cursor: pointer;
  display: block;
  width: vw_d(330);
  height: vw_d(330);
  position: relative;

  @include screen('mobile') {
    width: 100%;
    height: initial;
  }

  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    background: rgba(0, 0, 0, 0.26);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: inherit;
      width: inherit;
      border: vw_d(4) solid rgba(255, 255, 255, 0.37);
      border-radius: 50%;
      pointer-events: none;
      z-index: 2;

      @include screen('mobile') {
        border: 2px solid rgba(white, 0.38);
      }
    }

    @include screen('mobile') {
      margin: 0 auto 14px;
      width: 119px;
      height: 119px;
      background-clip: content-box;
    }
  }

  input[type='file'] {
    display: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    width: vw_d(69);
    height: vw_d(69);

    @include screen('mobile') {
      width: 25px;
      height: auto;
    }

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }

  img {
    position: relative;
    z-index: 1;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

  .error {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 15px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: rgba(252, 68, 79, 1);

    @include screen('mobile') {
      position: relative;
      top: initial;
      left: initial;
      padding: 0 30px;
    }
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.popup-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  @include screen('mobile') {
    padding-bottom: 26px;
  }

  &.confirmation {
    @include screen('mobile') {
      display: flex;
      flex-direction: column;
    }
  }

  .blurred-block {
    position: absolute;
    height: 100%;
    left: -#{$content-wrap-margin};
    right: -#{$content-wrap-margin};
    top: 0;
    // background-color: rgba(0, 0, 0, 0.85);
    filter: saturate(-50%);
    backdrop-filter: blur(6px);
    opacity: 1;
    visibility: visible;

    @include screen('mobile') {
      display: none;
    }
  }
}

.step-transition {
  $transition-duration: 300ms;

  &-appear {
    opacity: 0;
  }

  &-appear-active {
    opacity: 1;
    transition: opacity;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-appear-done {
    opacity: 1;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
  }

  &-exit-done {
    opacity: 0;
  }
}

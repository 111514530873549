@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.mandate {
  position: relative;

  @include screen('mobile') {
    height: 100%;
    padding-bottom: 160px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 130vh;
  background-size: cover;
  background-position: center bottom;

  &::before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &.sticky {
    position: fixed;
    top: -100vh;
  }
}

.hero {
  color: inherit;
}

.content-wrap {
  position: relative;
  // padding-top: vw_d(78);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.025) 0%, transparent 100%);
}

.content {
  display: flex;
  gap: vw_d(300);
  width: 100%;
}

.aside-column {
  width: vw_d(545);
  min-width: vw_d(545);
  margin-top: vw_d(78);

  .aside-block {
    position: sticky;
    top: vw_d(78 * 2);
    border-radius: vw_d(7);
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 5vw;

    &-section {
      padding: vw_d(27) vw_d(34);

      &-title {
        &-wrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: vw_d(40);
          margin-bottom: vw_d(24);

          p,
          a {
            font-size: vw_d(18);
            line-height: vw_d(32.65);
            letter-spacing: vw_d(1.1);
            font-weight: 600;
          }

          a {
            color: #68a4ff;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    .progress {
      &-wrap {
        position: relative;
        width: 100%;
        display: flex;
        gap: vw_d(40);
        margin-bottom: vw_d(28);
      }

      &-chart {
        width: vw_d(158);
        min-width: vw_d(158);
        height: vw_d(158);
      }

      &-info {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        max-width: calc(100% - vw_d(158) - vw_d(40));

        &-section {
          padding: vw_d(19) 0;
          border-top: 1px solid rgba(255, 255, 255, 0.05);
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);

          .target {
            font-size: vw_d(30);
            line-height: vw_d(42.5);
            letter-spacing: vw_d(0.76);
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: vw_d(7);

            // @extend %overflow-ellipsis;
          }

          .raised {
            font-size: vw_d(14.77);
            line-height: vw_d(28.5);
            letter-spacing: vw_d(0.9);
            font-weight: 600;
            text-transform: uppercase;

            // @extend %overflow-ellipsis;
          }

          .current-round,
          .previous-rounds {
            font-size: vw_d(14);
            line-height: vw_d(26);
            letter-spacing: vw_d(0.85);
          }

          .current-round {
            font-weight: 600;
          }

          &:first-child {
            border: 0;
            padding: 0;
          }

          &:not(:first-child) {
            margin-top: vw_d(19);
          }
        }
      }
    }

    .actions-wrap {
      display: flex;
      align-items: center;
      gap: vw_d(40);

      .terms-of-deal {
        position: relative;
        width: vw_d(158);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: vw_d(10);

        p {
          cursor: pointer;
          font-size: vw_d(16);
          line-height: vw_d(16);
          letter-spacing: vw_d(0.85);
          text-decoration: underline;
        }

        &-icon {
          cursor: pointer;
          position: relative;
          width: vw_d(17);
          height: vw_d(17);
          border: 1px solid white;
          border-radius: 50%;

          &::before {
            content: '?';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: vw_d(12);
          }
        }

        &-tooltip {
          position: absolute;
          z-index: 10;
          top: vw_d(-18);
          left: 30%;
          width: vw_d(250);
          padding: vw_d(10);
          background-color: rgba(0, 0, 0, 1);
          border-radius: vw_d(4);
          font-size: vw_d(14);
          line-height: vw_d(18);
          letter-spacing: vw_d(0.1);
          opacity: 0;
          visibility: hidden;
          transform: translate(-50%, calc(-100% - vw_d(10)));
          transition: all 0.2s ease;

          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 100%;
            width: 1px;
            height: 1px;
            border: vw_d(10) solid transparent;
            border-top-color: rgba(0, 0, 0, 1);
            transform: translate(vw_d(-5), 0);
          }
        }

        &:hover,
        &:focus {
          .terms-of-deal-tooltip {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, calc(-100% - vw_d(0)));
          }
        }
      }

      .btn-wrap {
        position: relative;
        flex-grow: 1;

        .place-an-offer-Btn {
          width: 100%;
          height: vw_d(55);
          border-radius: vw_d(28);

          font-size: vw_d(20.09);
          line-height: vw_d(31.46);
          letter-spacing: vw_d(0.39);
        }
      }
    }

    .lead-investor {
      display: flex;
      align-items: center;
      gap: vw_d(15.35);
      margin-bottom: vw_d(28);

      &-logo {
        width: vw_d(51.31);
        height: vw_d(50.89);
        border-radius: vw_d(7);
        background-color: white;
        background-size: contain;
        background-position: center;
      }

      &-name {
        font-size: vw_d(16);
        line-height: vw_d(25.65);
        letter-spacing: vw_d(0.4);
        font-weight: 600;
      }

      &:hover {
        .lead-investor-name {
          text-decoration: underline;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.main-column {
  flex-grow: 1;
  margin-top: vw_d(-70);
  margin-bottom: vw_d(183);

  @include screen('desktop') {
    display: flex;
    flex-direction: column;
  }

  @include screen('mobile') {
    width: 100%;
    margin-top: 0;
    margin-bottom: 100px;
    padding-bottom: 100px;
  }

  @include screen('mobile-extra-small') {
    padding-bottom: 50px;
  }

  .section {
    padding-top: vw_d(78 + 50);
    margin-bottom: vw_d(50);

    @include screen('mobile') {
      padding: 70px 37px 40px;
      margin: 0 -37px;
    }

    &:first-child {
      padding-top: vw_d(70 + 78);

      @include screen('mobile') {
        padding-top: 47px;
      }
    }

    &-title {
      font-size: vw_d(50.44);
      line-height: vw_d(42.65);
      letter-spacing: vw_d(4);
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: vw_d(50.35);

      @include screen('mobile') {
        font-size: 22px;
        line-height: 1.273;
        letter-spacing: 1.3px;
        font-weight: 700;
        margin-bottom: 24px;
      }

      @include screen('mobile-extra-small') {
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: 1.26px;
      }
    }

    &[id='about'] {
      @include screen('desktop') {
        order: 1;
      }
    }

    &[id='founders'] {
      @include screen('desktop') {
        order: 2;
      }

      @include screen('mobile') {
        background: linear-gradient(0deg, rgba(37, 37, 37, 0.5) 0%, rgba(0, 0, 0, 1) 100%);
        padding-top: 19px;
        padding-bottom: 1px;
      }

      .team-member {
        margin-bottom: vw_d(60);

        @include screen('mobile') {
          margin-bottom: 40px;
        }

        &-photo-and-name {
          display: flex;
          gap: vw_d(21.75);
          align-items: center;

          @include screen('mobile') {
            gap: 15px;
            margin-left: 4px;
          }
        }

        &-photo {
          width: vw_d(78.84);
          height: vw_d(78.84);
          border-radius: 50%;
          background-color: white;
          background-size: cover;
          background-position: center;

          @include screen('mobile') {
            width: 57px;
            height: 57px;
          }
        }

        &-name,
        &-role {
          font-size: vw_d(20);
          line-height: 1.2;
          letter-spacing: vw_d(0);

          @include screen('mobile') {
            font-size: 16px;
          }

          @include screen('mobile-extra-small') {
            font-size: 14px;
          }
        }

        &-name {
          font-weight: 600;
          margin-bottom: vw_d(5);

          @include screen('mobile') {
            margin-bottom: 4px;
          }
        }

        &-description {
          position: relative;
          padding-top: vw_d(16);
          margin-top: vw_d(16);
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          font-size: vw_d(20);
          line-height: vw_d(32);

          @include screen('mobile') {
            font-size: 16px;
            line-height: 1.423;
            padding-top: 14px;
            margin-top: 14px;
          }

          @include screen('mobile-extra-small') {
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }
    }

    &.videos {
      @include screen('desktop') {
        order: 5;
      }
    }

    &[id='key-info'] {
      @include screen('desktop') {
        order: 3;
      }

      @include screen('mobile') {
        padding-top: 59px;
      }

      .key-info-content {
        position: relative;
        font-size: vw_d(20);
        line-height: vw_d(32);
        letter-spacing: 0;

        @include screen('mobile') {
          font-size: 16px;
          line-height: 1.423;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 1.4;
        }

        h2 {
          font-size: vw_d(50.44);
          line-height: 1.05;
          letter-spacing: vw_d(8.07);
          font-weight: 600;
          text-transform: uppercase;
          margin: vw_d(50) 0;

          @include screen('mobile') {
            font-size: 22px;
            line-height: 1.273;
            letter-spacing: 1.3px;
            font-weight: 700;
            margin: 24px 0;
          }
        }

        h3 {
          margin-top: vw_d(25);

          @include screen('mobile') {
            font-size: 16px;
            line-height: 1.423;
            font-weight: 700;
            margin-top: 24px;
          }
        }

        h6 {
          font-size: 0.68028vw;
          line-height: 1.3;
          font-weight: initial;
        }

        p {
          margin-bottom: 1em;
        }
      }
    }

    &[id='deal-terms'] {
      @include screen('desktop') {
        order: 4;
      }

      @include screen('mobile') {
        padding-top: 59px;
      }

      .deal-terms-content {
        position: relative;
        font-size: vw_d(20);
        line-height: vw_d(32);
        letter-spacing: 0;

        @include screen('mobile') {
          font-size: 16px;
          line-height: 1.423;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 1.4;
        }

        h1 {
          font-size: vw_d(50.44);
          line-height: 1.05;
          letter-spacing: vw_d(8.07);
          font-weight: 600;
          text-transform: uppercase;
          margin: vw_d(50) 0;
          text-align: left;

          @include screen('mobile') {
            font-size: 22px;
            line-height: 1.273;
            letter-spacing: 1.3px;
            font-weight: 700;
            margin: 24px 0;
          }
        }

        h2 {
          font-size: vw_d(50.44);
          line-height: 1.05;
          letter-spacing: vw_d(8.07);
          font-weight: 600;
          text-transform: uppercase;
          margin: vw_d(50) 0;

          @include screen('mobile') {
            font-size: 22px;
            line-height: 1.273;
            letter-spacing: 1.3px;
            font-weight: 700;
            margin: 24px 0;
          }
        }

        h3 {
          margin-top: vw_d(25);

          @include screen('mobile') {
            font-size: 16px;
            line-height: 1.423;
            font-weight: 700;
            margin-top: 24px;
          }
        }

        h6 {
          font-size: 0.68028vw;
          line-height: 1.3;
          font-weight: initial;
        }

        p {
          margin-bottom: 1em;
        }
      }
    }

    &[id='investors'] {
      @include screen('desktop') {
        order: 4;
      }

      @include screen('mobile') {
        padding-top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(37, 37, 37, 0.5) 100%);
      }

      .section-title {
        @include screen('mobile') {
          margin-bottom: 0;
        }
      }

      .investors-content {
        display: flex;
        flex-wrap: wrap;
        row-gap: vw_d(30);
        column-gap: vw_d(56);

        @include screen('mobile') {
          display: block;
        }
      }

      .investor {
        position: relative;
        display: flex;
        align-items: center;
        gap: vw_d(20);
        width: calc(50% - vw_d(28));
        padding-bottom: vw_d(35);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        text-align: left;

        @include screen('mobile') {
          width: 100%;
          padding-top: 24px;
          padding-bottom: 15px;

          &:last-child {
            border-bottom: 0;
          }
        }

        &-logo {
          width: vw_d(80);
          min-width: vw_d(80);
          height: vw_d(80);
          border-radius: vw_d(7);
          background-color: white;
          background-size: contain;
          background-position: center;

          @include screen('mobile') {
            width: 57px;
            height: 57px;
            min-width: 57px;
            border-radius: 7px;
            margin-right: 12px;
          }
        }

        &-name,
        &-type {
          font-size: vw_d(20);
          line-height: 1.2;
          letter-spacing: vw_d(0);

          @include screen('mobile') {
            font-size: 16px;
          }

          @include screen('mobile-extra-small') {
            font-size: 14px;
          }
        }

        &-name {
          font-weight: 600;
          margin-bottom: vw_d(6);

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          @include screen('mobile') {
            margin-bottom: 5px;
            text-decoration: underline;
          }
        }

        &-type {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        &:hover {
          .investor-name {
            text-decoration: underline;
          }
        }
      }
    }

    &[id='documents'] {
      @include screen('desktop') {
        order: 6;
        padding-top: vw_d(78 + 50);
      }

      @include screen('mobile') {
        padding-top: 60px;
      }

      .bordered-wrap {
        @include screen('desktop') {
          padding: vw_d(40);
          border: 1px solid rgba(255, 255, 255, 0.4);
          border-radius: vw_d(7);
        }
      }

      .documents-sub-title {
        font-size: vw_d(26);
        line-height: vw_d(31.4);
        letter-spacing: vw_d(0);
        font-weight: 600;
        margin-bottom: vw_d(22);
        color: rgba(255, 255, 255, 0.5);
        text-transform: uppercase;
      }

      .document-link {
        display: flex;
        width: 100%;
        align-items: center;
        gap: vw_d(22);
        margin-bottom: vw_d(27);

        @include screen('mobile') {
          gap: 1px;
          margin-bottom: 25px;
        }

        &-icon {
          display: block;
          width: vw_d(31);
          height: vw_d(31);

          path {
            fill: $light-blue;
          }

          @include screen('desktop') {
            max-width: vw_d(18);
            max-height: vw_d(19);

            svg {
              width: 100%;
              height: 100%;
            }
          }

          @include screen('mobile') {
            width: 19px;
            height: 19px;
            margin-right: 15px;
          }
        }

        &-name {
          font-size: vw_d(20);
          line-height: vw_d(42);
          letter-spacing: vw_d(0);
          font-weight: 600;
          color: #68a4ff;

          @include screen('mobile') {
            font-size: 16px;
            line-height: 1.438;
          }

          @include screen('mobile-extra-small') {
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }
    }
  }
}

.controlPanel {
  @include screen('mobile') {
    z-index: 4;
  }
}

.docs-icon {
  max-width: vw_d(16);
  max-height: vw_d(17);

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: $light-blue;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.mediaWrapper {
  width: 100%;

  &.error {
    label {
      border: solid 1px rgba(252, 68, 79, 1);
    }
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 57px 20px 16px;
    border: dashed 1px rgba(255, 255, 255, 0.78);
    background-color: rgba(255, 255, 255, 0.08);

    input {
      display: none;
    }

    .fakeBtn {
      padding: 19px 42.5px 18px 39.5px;
      font-size: 22px;
      line-height: 29px;
      letter-spacing: 1.3px;
      font-family: $regular-font;
      font-weight: 700;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.18);
      border-radius: 38px;
      text-transform: uppercase;
      cursor: pointer;

      @include screen('mobile') {
        padding: 19px 30px 18px 30px;
        font-size: 22px;
        min-width: 240px;
        text-align: center;
      }

      @include screen('mobile-extra-small') {
        padding: 15px 24px;
        font-size: 18px;
        min-width: 200px;
      }
    }

    .acceptFilesText {
      padding-top: 58px;
      font-size: 16px;
      line-height: 23px;
      font-family: $regular-font;
      font-weight: 400;
      color: #fff;
      text-align: center;

      @include screen('mobile') {
        font-size: 14px;
      }

      @include screen('mobile-extra-small') {
        padding-top: 38px;
      }
    }
  }

  .file {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 14px;
    padding: 5px 9px 6px 10px;
    .fileWrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 16px;
      line-height: 23px;
      font-family: $regular-font;
      font-weight: 400;
      color: #fff;
      z-index: 2;
      .trashBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .bluredBlock {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.3);
      filter: saturate(-50%);
      backdrop-filter: blur(6px);
      z-index: 1;
    }
  }

  .errorWrapper {
    width: 100%;
    margin-top: 14px;
  }
}

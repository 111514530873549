@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.form-wrapper {
  position: relative;
  padding-top: vw_d(44.45);

  @include screen('mobile') {
    padding-top: 10px;
  }

  .row {
    padding-bottom: vw_d(22);

    @include screen('mobile') {
      padding-bottom: 11px;

      & + .check-box-row {
        margin-top: 19px;
      }
    }
  }

  .check-box-row {
    padding-bottom: vw_d(25);

    @include screen('mobile') {
      padding-bottom: 24px;
    }
  }

  .button-wrapper {
    padding-top: vw_d(15);

    @include screen('mobile') {
      padding-top: 11px;
    }

    .submit-btn {
      display: flex;
      align-items: center;
      height: vw_d(50);
      padding: 0 vw_d(58.5);
      font-size: vw_d(20.09);
      font-family: $regular-font;
      font-weight: 600;
      border-radius: vw_d(27.5);
      background-color: #0757d3;
    }
  }

  .form-loader {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.claim-company-form {
  display: flex;
  position: relative;

  .page-header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
  }

  .control-panel {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
  }

  @include screen('mobile') {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }

  .video-block {
    display: flex;
    align-items: center;
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 40px;
    @include screen('mobile') {
      display: none;
    }
    .video {
      width: 100%;
      padding: 56.25% 0 0 0;
      position: relative;
    }
  }

  .video-block-mobile {
    display: none;
    @include screen('mobile') {
      display: block;
      width: 100%;
      margin: 20px 0;
    }
    .video {
      width: 100%;
      padding: 56.25% 0 0 0;
      position: relative;
    }
  }
  .info {
    width: 50%;

    @include screen('mobile') {
      width: 100%;
      padding-left: $content-wrap-margin;
      padding-right: $content-wrap-margin;
    }

    .info-wrapper {
      width: vw_d(535);
      margin: 0 auto;
      padding-top: vw_d(133.75);
      padding-bottom: vw_d(52);

      @include screen('mobile') {
        width: 100%;
        padding-top: calc(#{$header-height-mobile} + 29px);
        padding-bottom: calc(#{$footer-height-mobile} + 40px);
      }

      .title {
        font-size: vw_d(50);
        line-height: 1.073;
        color: #fff;
        font-family: $regular-font;
        letter-spacing: vw_d(8);
        font-weight: 700;
        text-transform: uppercase;

        @include screen('mobile') {
          font-size: 50px;
          letter-spacing: 1px;
          text-align: center;
          line-height: 58px;
          margin-bottom: 21px;
        }
      }
    }

    .tank-you {
      width: vw_d(624);
      margin: 0 auto;
      padding-top: vw_d(250);
      padding-bottom: vw_d(248);

      @include screen('mobile') {
        text-align: center;
        width: 100%;
        padding-top: calc(#{$header-height-mobile} + 47px);
        padding-bottom: calc(#{$footer-height-mobile} + 40px);
      }

      .icon-wrapper {
        padding-bottom: vw_d(32.12);

        @include screen('mobile') {
          padding-bottom: 27px;
        }

        .icon {
          width: vw_d(92.76);
          height: vw_d(92.76);

          svg {
            display: block;
            width: 100%;
            height: 100%;
          }

          @include screen('mobile') {
            margin: 0 auto;
            width: 94px;
            height: auto;
          }
        }
      }

      .title {
        width: vw_d(580);
        padding-bottom: vw_d(36);
        font-size: vw_d(50);
        line-height: 1.4;
        color: #fff;
        font-family: $regular-font;
        font-weight: 600;

        @include screen('mobile') {
          font-size: 40px;
          line-height: 1.5;
          padding-bottom: 24px;
          width: auto;
          margin: 0 -10px;
          letter-spacing: -1px;
        }
      }

      .subtitle {
        padding-bottom: vw_d(57);
        font-size: vw_d(35);
        line-height: 1.343;
        color: #fff;
        font-family: $regular-font;
        font-weight: 400;

        @include screen('mobile') {
          padding-bottom: 50px;
          font-size: 20px;
          line-height: 1.5;
          text-indent: 5px;
        }
      }

      .button-wrapper {
        display: flex;

        @include screen('mobile') {
          button {
            display: block;
            text-transform: lowercase;

            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }

      .btn-desktop {
        display: flex;
        align-items: center;
        height: vw_d(55);
        padding: 0 vw_d(61);
        font-size: vw_d(20.09);
        text-transform: uppercase;
        font-family: $regular-font;
        font-weight: 600;
        color: #fff;
        border-radius: vw_d(27.5);
        background-color: #0757d3;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.main {
  position: relative;

  .featured-slider {
    padding: 0 0 vw_d(15);
  }
  .mandates {
    height: vw_d(891);
    background-color: $primary-color;
  }
  .ai-slider {
    padding: vw_d(137) 0 vw_d(95);
  }
}

.upvote {
  display: flex;
  align-items: center;
  font-size: vw_d(22);
  letter-spacing: 0.43px;

  .icon {
    position: relative;
    display: inline-block;
    margin-left: vw_d(13);
    width: vw_d(41);
    height: vw_d(41);
    border-radius: 50%;
    background-color: rgba(37, 224, 97, .25);
    transition: 0.35s;

    &:hover {
      cursor: pointer;
      transform: scale(1.15);
      background-color: rgba(37, 224, 97, .35);
    }

    &:after {
      content: '';
      position: absolute;
      top: vw_d(15);
      left: vw_d(15);
      width: 0; 
      height: 0; 
      border-style: solid;
      border-width: 0 vw_d(5.85) vw_d(9) vw_d(5.85);
      border-color: transparent transparent #fff transparent;
    }
  }
}
@import './theme.scss';
@import './functions.scss';
@import './mixins.scss';

.title {
  padding-bottom: vw_d(85.1);
  font-family: $regular-font;
  font-size: vw_d(50);
  font-weight: 700;
  line-height: 0.853;
  letter-spacing: vw_d(8);
  color: #fff;

  @include screen('mobile') {
    padding-bottom: 50px;
    font-size: 36px;
    line-height: 1.25;
    letter-spacing: normal;
  }
}

.rich-text-content {
  figure {
    max-width: 400px;
    margin: vw_d(25) auto;

    @include screen('mobile') {
      max-width: 200px;
      margin: 15px auto;
    }
  }

  a {
    text-decoration: underline;
  }

  p, li {
    margin: vw_d(25) 0;
    font-size: vw_d(18);
    line-height: 1.98;
    color: #fff;
  
    @include screen('mobile') {
      font-size: 16px;
      margin: 15px 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    padding: vw_d(24) 0;

    @include screen('mobile') {
      padding: 15px 0;
    }
  }
  
  h6 {
    font-size: vw_d(20);
    line-height: 1.2;
  
    @include screen('mobile') {
      font-size: 18px;
    }
  }
  
  h5 {
    font-size: vw_d(22);
    line-height: 1.2;
  
    @include screen('mobile') {
      font-size: 20px;
    }
  }
  
  h4 {
    font-size: vw_d(24);
    line-height: 1.2;
  
    @include screen('mobile') {
      font-size: 22px;
    }
  }
  
  h3 {
    font-size: vw_d(26);
    line-height: 1.2;
  
    @include screen('mobile') {
      font-size: 24px;
    }
  }
  
  h2 {
    font-size: vw_d(28);
    line-height: 1.2;
  
    @include screen('mobile') {
      font-size: 26px;
    }
  }
  
  h1 {
    font-size: vw_d(30);
    line-height: 1.2;
  
    @include screen('mobile') {
      font-size: 28px;
    }
  }
}





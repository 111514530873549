@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

$disable-color: rgba(255, 255, 255, 0.48);
$option-active: rgba(7, 87, 211, 0.68);

@mixin content-title {
  font-size: vw_d(20.09);
  line-height: 1.566;
  letter-spacing: vw_d(0.39);
  font-weight: 700;
}

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @include screen('mobile') {
    min-height: 100%;
    // z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  &-loader {
    top: 50%;
    transform: translateY(-50%);
  }

  &-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.78);
    backdrop-filter: blur(vw_d(10));

    @include screen('mobile') {
      backdrop-filter: 0;
    }
  }

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #979797;
    border-radius: vw_d(12);

    @include screen('mobile') {
      min-height: 0;
      max-height: calc(90vh - 65px - 77px); // - footer - padding-top
      width: calc(100% - 50px);
      top: 77px;
      border-radius: 12px;
      transform: translate(-50%, 0);
      overflow-y: auto;
    }

    @include screen('mobile-large') {
      max-width: 450px;
    }

    @include screen('mobile-extra-small') {
      width: calc(100% - 30px);
    }

    .offer-section {
      width: vw_d(610);
      padding: 0 vw_d(30) vw_d(21);

      @include screen('mobile') {
        width: 100%;
        padding: 0 12px 40px;
      }
    }

    .title {
      padding: vw_d(37) 0 vw_d(36);
      margin: 0 vw_d(-30);
      @include content-title();
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      background-color: rgba(255, 255, 255, 0.07);

      @include screen('mobile') {
        padding: 26px 0 15px;
        margin: 0 -12px;
        font-size: 24px;
        line-height: 1.417;
        letter-spacing: 1.92px;
      }

      .company-name {
        display: inline-block;
        font-size: vw_d(30.44);
        line-height: 1.401;
        letter-spacing: vw_d(4.87);

        @include screen('mobile') {
          font-size: 24px;
          line-height: 1.417;
          letter-spacing: 1.92px;
        }

        @include screen('mobile-extra-small') {
          font-size: 22px;
          line-height: 1.3;
          letter-spacing: 1.4px;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    top: vw_d(30);
    left: vw_d(29);

    @include screen('mobile') {
      position: fixed;
      width: 21px;
      height: 21px;
      left: 25px;
      top: 25px;
      z-index: 10;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 2px;
        background-color: #fff;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .invest-amount {
    padding: vw_d(27) 0 vw_d(45);
    min-height: vw_d(261);

    @include screen('mobile') {
      min-height: 100%;
      padding: 12px 0 25px;
    }

    .sub-title {
      @include content-title();
      padding-bottom: vw_d(22);

      @include screen('mobile') {
        font-size: 18px;
        line-height: 1.556;
        letter-spacing: 0.35px;
        text-align: center;
        padding-bottom: 24px;
      }

      @include screen('mobile-extra-small') {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.3px;
      }
    }

    .invest-options {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(auto, 1fr);
      grid-column-gap: vw_d(20);
      grid-row-gap: vw_d(23);

      @include screen('mobile') {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 14px;
        grid-row-gap: 14px;
      }
    }

    .option {
      width: vw_d(170);
      padding: vw_d(12) 0 vw_d(11);
      @include content-title();
      border: 1px solid #fff;
      border-radius: vw_d(27.53);
      transition: 0.3s;

      @include screen('mobile') {
        width: 100%;
        font-size: 20px;
        line-height: 1;
        letter-spacing: 0.39px;
        padding: 16px 12px;
        border-radius: 27px;
      }

      @include screen('mobile-extra-small') {
        font-size: 16px;
        line-height: 1;
        padding: 12px 12px;
        letter-spacing: 0.3px;
      }

      &:hover,
      &.selected {
        background-color: $option-active;
        border-color: transparent;
        cursor: pointer;
      }
    }
  }

  .input-section {
    .back-button {
      font-size: vw_d(16);
      line-height: 1.966;
      letter-spacing: 0.31px;
      padding-bottom: 22px;

      @include screen('mobile') {
        font-size: 16px;
        line-height: 1.438;
      }
    }

    .input-wrap {
      position: relative;
      height: vw_d(48);
      width: vw_d(339);
      margin-top: vw_d(-8);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);

      @include screen('mobile') {
        height: 48px;
        width: 100%;
        margin-top: 0;
      }

      input {
        height: 100%;
        width: 100%;
        padding-left: vw_d(12);
        outline: none;
        border: none;
        font-size: vw_d(16);
        color: #fff;
        background-color: rgba(255, 255, 255, 0.07);

        @include screen('mobile') {
          padding-left: 12px;
          font-size: 18px;
          letter-spacing: -0.2px;
        }

        &:focus ~ .placeholder {
          opacity: 0;
          visibility: hidden;
        }
      }

      .error {
        position: absolute;
        left: vw_d(3);
        bottom: vw_d(-30);
        font-size: vw_d(16);
        color: red;

        @include screen('mobile') {
          left: 3px;
          bottom: -20px;
          font-size: 12px;
        }
      }

      .placeholder {
        position: absolute;
        top: 0;
        left: vw_d(12);
        height: inherit;
        width: inherit;
        display: flex;
        align-items: center;
        font-size: vw_d(16);
        line-height: 1.25;
        letter-spacing: -0.18px;
        color: rgba(255, 255, 255, 0.87);
        pointer-events: none;

        @include screen('mobile') {
          left: 12px;
          font-size: 16px;
        }

        .smaller {
          color: rgba(255, 255, 255, 0.47);
          margin-left: vw_d(10);
        }
      }
    }
  }

  .result-amount {
    padding: vw_d(32) vw_d(30) vw_d(10) vw_d(30);
    border-top: 1px solid rgba(151, 151, 151, 0.32);
    margin: 0 vw_d(-30);

    @include screen('mobile') {
      padding: 24px 12px 2px;
      margin: 0 -12px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      padding-bottom: vw_d(15);

      @include screen('mobile') {
        padding-bottom: 15px;
      }

      &-title {
        @include content-title();

        @include screen('mobile') {
          font-size: 18px;
          letter-spacing: 0.35px;
        }

        @include screen('mobile-extra-small') {
          font-size: 16px;
        }
      }
      &-value {
        @include content-title();
        color: rgba(255, 255, 255, 0.48);
        text-align: right;

        @include screen('mobile') {
          font-size: 18px;
          letter-spacing: 0.35px;
        }

        @include screen('mobile-extra-small') {
          font-size: 16px;
        }

        &.total-sum {
          font-size: vw_d(35);
          line-height: 0.899;
          letter-spacing: vw_d(0.68);
          font-weight: 700;
          color: #fff;

          @include screen('mobile') {
            font-size: 35px;
            letter-spacing: 0.68px;
          }

          @include screen('mobile-extra-small') {
            font-size: 16px;
            letter-spacing: 0.48px;
            line-height: 1.56;
          }
        }
      }
    }
  }

  .submit-button {
    min-width: 100%;
    height: vw_d(70);
    @include content-title();
    // text-transform: uppercase;
    border-radius: vw_d(35);

    @include screen('mobile') {
      height: 60px;
      font-size: 18px;
      letter-spacing: 0.35px;
      border-radius: 27px;
    }

    @include screen('mobile-extra-small') {
      font-size: 16px;
      height: 50px;
    }
  }

  .back-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -4px;
    left: 3px;
    width: 32px;
    height: 32px;
  }

  .success-message {
    width: vw_d(580);
    padding: vw_d(145) vw_d(55) vw_d(144);
    text-align: center;

    @include screen('mobile') {
      width: 100%;
      padding: 48px 12px 40px;
    }

    .icon {
      width: vw_d(92);
      height: vw_d(92);

      @include screen('mobile') {
        width: 78px;
        height: 78px;
      }

      svg {
        width: inherit;
        height: inherit;
      }
    }
    &-title {
      padding: vw_d(20) 0 vw_d(24);
      font-size: vw_d(50);
      line-height: 1.169;
      letter-spacing: 0.97px;
      font-weight: 700;
      text-transform: uppercase;

      @include screen('mobile') {
        padding: 33px 0 14px;
        font-size: 24px;
        line-height: 1.417;
        letter-spacing: 1.92px;
      }

      @include screen('mobile-extra-small') {
        font-size: 22px;
      }
    }

    &-info {
      width: vw_d(387);
      margin: 0 auto;
      font-size: vw_d(20.09);
      line-height: 1.566;
      letter-spacing: vw_d(0.39);
      font-weight: 400;
      padding-bottom: vw_d(50);

      @include screen('mobile') {
        width: 100%;
        max-width: 300px;
        font-size: 18px;
        line-height: 1.556;
        letter-spacing: 0.35px;
        padding-bottom: 40px;
      }

      @include screen('mobile-extra-small') {
        font-size: 16px;
      }
    }
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      min-width: vw_d(339);
      height: vw_d(60);
      font-size: vw_d(20.09);
      line-height: 1.566;
      letter-spacing: vw_d(0.39);
      font-weight: 700;
      border-radius: vw_d(27.5);
      background-color: $primary-color;

      @include screen('mobile') {
        width: 100%;
        height: 60px;
        font-size: 18px;
        line-height: 1.748;
        letter-spacing: 0.35px;
        border-radius: 27.53px;
      }

      @include screen('mobile-extra-small') {
        height: 50px;
        font-size: 16px;
      }

      a {
        font-size: vw_d(20.09);
        line-height: 1.566;
        letter-spacing: vw_d(0.39);
        font-weight: 700;

        @include screen('mobile') {
          font-size: 18px;
          line-height: 1.748;
          letter-spacing: 0.35px;
        }
      }
    }

    .close-btn {
      position: absolute;
      top: vw_d(33);
      left: vw_d(29);

      @include screen('mobile') {
      }
    }
  }
}
.checkbox {
  position: relative;
  padding: vw_d(4) 0 vw_d(43);
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;

  @include screen('mobile') {
    padding: 4px 0 43px;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ .check-mark {
      background-color: $primary-color;
      box-shadow: none;
      &:after {
        display: block;
      }
    }
  }

  .error {
    position: absolute;
    bottom: vw_d(20);
    font-size: vw_d(16);
    color: red;

    @include screen('mobile') {
      bottom: 20px;
      font-size: 16px;
    }

    @include screen('mobile-extra-small') {
      font-size: 14px;
    }
  }

  .check-mark {
    position: relative;
    display: block;
    height: vw_d(30);
    width: vw_d(30);
    flex-shrink: 0;
    background-color: transparent;
    border-color: transparent;
    border-radius: vw_d(4);
    box-shadow: inset 0 0 vw_d(2) #fff;

    @include screen('mobile') {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      box-shadow: inset 0 0 2px #fff;
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 35%;
      top: 10%;
      transform: translate(-50%, -50%);
      width: vw_d(10);
      height: vw_d(19);
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @include screen('mobile') {
        width: 10px;
        height: 19px;
      }
    }
  }
  .label-text {
    margin-left: vw_d(14);

    @include screen('mobile') {
      margin-left: 14px;
    }
  }

  a {
    color: $light-blue;
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.label-text {
  font-size: vw_d(16);
  line-height: 1.688;
  font-weight: 500;
  color: #fff;

  @include screen('mobile') {
    font-size: 16px;
    line-height: 1.438;
    font-weight: 400;
  }

  @include screen('mobile-extra-small') {
    font-size: 14px;
    line-height: 1.4;
  }

  a {
    color: #68a4ff;
    text-decoration: underline;
  }
}

.fee-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: vw_d(39);

  @include screen('mobile') {
    padding-bottom: 47px;
  }

  .switch-wrap {
    @include screen('mobile') {
      margin-top: -18px;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    z-index: -100;
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: vw_d(40);
    height: vw_d(23);
    background: rgba(255, 255, 255, 0.48);
    display: block;
    border-radius: vw_d(20);
    position: relative;

    @include screen('mobile') {
      width: 40px;
      height: 23px;
      border-radius: 20px;
    }
  }

  label:after {
    content: '';
    position: absolute;
    top: vw_d(2);
    left: vw_d(2);
    width: vw_d(19);
    height: vw_d(19);
    background: #fff;
    border-radius: vw_d(19);
    transition: 0.3s;

    @include screen('mobile') {
      top: 2px;
      left: 2px;
      width: 19px;
      height: 19px;
      border-radius: 19px;
    }
  }

  input:checked + label {
    background: $primary-color;
  }

  input:checked + label:after {
    left: calc(100% - #{vw_d(2)});
    transform: translateX(-100%);

    @include screen('mobile') {
      left: calc(100% - 2px);
    }
  }

  label:active:after {
    width: vw_d(20);

    @include screen('mobile') {
      width: 20px;
    }
  }

  .label-text {
    @include screen('mobile') {
      padding-right: 10px;
    }

    @include screen('mobile-extra-small') {
      font-size: 14px;
    }
  }
}

.loader {
  height: vw_d(70);

  @include screen('mobile') {
    height: 70px;
  }
}

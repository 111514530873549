@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.category {
  min-height: 100vh;
  position: relative;

  .main-loader {
    min-height: 100vh;
  }
}

.intro {
  padding: vw_d(214) 0 vw_d(0);
  height: vw_d(634);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: vw_d(170);
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
  }

  .info {
    position: relative;
    width: vw_d(850);
    padding-left: vw_d(200);
    z-index: 3;

    .back-button {
      font-size: vw_d(20);
      font-weight: 700;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.48);
    }

    .title {
      font-size: vw_d(70);
      line-height: 1.43;
      text-transform: uppercase;
      padding: vw_d(19) 0 vw_d(17);
      text-align: left;
    }

    .description {
      width: vw_d(510);
      font-size: vw_d(20);
      line-height: 1.7;
      font-weight: 600;
      padding-bottom: vw_d(42);
    }
  }

  .image-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: vw_d(1473);
    height: 100%; //vw_d(832);
    overflow: hidden;
    z-index: 1;

    img {
      width: 100%;
      object-fit: cover;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: vw_d(699);
      height: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 1;
    }
  }
}

.companies {
  display: flex;
  flex-wrap: wrap;
  width: vw_d(1808);
  margin: 0 auto;
  padding: vw_d(80) 0 0;

  .company {
    position: relative;
    width: vw_d(422);
    height: vw_d(314);
    margin: 0 vw_d(15) vw_d(90);
  }
}

.view-more-wrapper {
  display: flex;
  justify-content: center;
  padding-top: vw_d(10);
  padding-bottom: vw_d(91);
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  .view-more-btn {
    position: relative;
    display: flex;
    align-items: center;
    height: vw_d(80);
    padding: 0 vw_d(58.5);
    font-size: vw_d(25.6);
    font-family: $regular-font;
    font-weight: 600;
    border-radius: vw_d(40);
    background-color: rgba(255, 255, 255, 0.3);
    overflow: hidden;

    .companies-loader {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

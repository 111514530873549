@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/functions.scss';

.heroSlider {
  position: relative;
  min-height: vw_d(832);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: vw_d(934);
    height: 100%;
    background: linear-gradient(270deg, rgba(221, 215, 215, 0) 0%, rgba(0, 0, 0, 1) 50%);
    z-index: 2;
    pointer-events: none;
  }

  .swiper-wrapper {
    position: relative;
    width: vw_d(730);
    z-index: 3;
  }

  :global {
    .swiper-slide-active {
      :local {
        .slide {
          opacity: 1;
        }
      }
    }
  }

  .slide {
    padding: vw_d(253) 0 vw_d(262);
    height: vw_d(832);
    opacity: 0;
    transition: 1s;
  }

  .info {
    position: relative;
    width: vw_d(730);
    padding-left: vw_d(200);
    z-index: 1;

    .title {
      font-size: vw_d(70);
      line-height: 1.2;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-bottom: vw_d(17);
      cursor: default;
    }
    .description {
      font-size: vw_d(20);
      line-height: 1.7;
      font-weight: 600;
      padding-bottom: vw_d(42);
      cursor: default;
    }

    .actions {
      display: flex;
      min-width: vw_d(330);
    }
  }

  .videoWrap {
    position: absolute;
    top: 0;
    right: 0;
    width: vw_d(1478);
    height: 100%; //vw_d(832);
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: vw_d(278);
      background: linear-gradient(180deg, rgba(225, 217, 217, 0) 0%, rgba(0, 0, 0, 1) 75%);
      z-index: 2;
      pointer-events: none;
    }
  }
}

.upvote {
  display: flex;
  align-items: center;
  font-size: vw_d(22);
  letter-spacing: 0.43px;
  margin-left: vw_d(45);

  &.voted {
    cursor: default;
    pointer-events: none;
  }

  .icon {
    position: relative;
    display: inline-block;
    margin-left: vw_d(13);
    width: vw_d(41);
    height: vw_d(41);
    border-radius: 50%;
    background-color: rgba(37, 224, 97, 0.25);
    transition: 0.35s;

    &:hover {
      cursor: pointer;
      transform: scale(1.15);
      background-color: rgba(37, 224, 97, 0.35);
    }

    &:after {
      content: '';
      position: absolute;
      top: vw_d(15);
      left: vw_d(15);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 vw_d(5.85) vw_d(9) vw_d(5.85);
      border-color: transparent transparent #fff transparent;
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.header {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
}

.control-panel {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  right: 0;
}

.company-mobile {
  $text-content-height: 55px + 90px + 62px + 34px;

  position: relative;
  padding: 0 0 $footer-height-mobile 0;
  height: 100%;
  color: white;
  background-color: $background-color;

  .bg {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    height: calc(100% - #{$text-content-height});

    &-image-wrap {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

    &-image {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
      opacity: 0.5;
      filter: blur(50px);
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      height: 20%;
    }

    &::before {
      top: -2px;
      background: linear-gradient(180deg, rgba($background-color, 1), rgba(0, 0, 0, 0));
    }

    &::after {
      bottom: -2px;
      height: 50%;
      background: linear-gradient(0deg, rgba($background-color, 1), rgba(0, 0, 0, 0));
    }
  }

  .content-wrap {
    position: relative;
    z-index: 2;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .content {
    position: relative;
    z-index: 5;
    padding-top: $header-height-mobile;
    padding-bottom: $footer-height-mobile;
  }

  .info {
    position: relative;

    .actions {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
    }

    .title-wrap {
      position: relative;
      z-index: 1;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 30px;
      padding-right: 110px;

      @include screen('mobile-extra-small') {
        padding-right: 40px;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.3;
      letter-spacing: 5px;

      @include screen('mobile-extra-small') {
        font-size: 22px;
        letter-spacing: 4px;
      }
    }

    .description {
      font-size: 16px;
      font-weight: 200;
      line-height: 20.7px;
      margin-bottom: 20px;

      @include screen('mobile-extra-small') {
        font-size: 14px;
        line-height: 19px;
      }
    }

    .inner-wrap {
      margin: 0 15px;

      @include screen('mobile-extra-small') {
        margin: 0;
      }
    }

    .details {
      position: relative;
      margin-bottom: 15px;

      &-row {
        position: relative;
        padding: 15px 8px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 20.7px;
        border-bottom: 1px solid rgba(#fff, 0.1);

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 18px;
        }

        &-title {
          width: 50%;
          font-weight: 700;
        }

        &-value {
          width: 50%;
          font-weight: 500;

          a {
            text-decoration: underline;
          }

          &.website-link {
            display: inline-block;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      &-disclaimer {
        margin: 14px 8px 0;
        font-size: 14px;
        line-height: 20.7px;
        font-weight: 100;

        div {
          display: inline;

          p {
            display: inline;
          }
        }
      }
    }

    .founders {
      position: relative;
      padding: 34px 0;
    }

    .categories {
      padding: 34px 0;

      &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

      &-item {
        display: block;
        font-size: 15px;
        letter-spacing: 0.3px;
        line-height: 32px;
        font-weight: 700;
        white-space: nowrap;
        background-color: rgba(#fff, 0.1);
        border-radius: 16px;
        padding: 0 15px;

        @include screen('mobile-extra-small') {
          font-size: 14px;
        }
      }
    }

    .claim-your-page {
      padding: 34px 0;
      margin: 0 -#{$content-wrap-margin + 15px} 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: rgba(white, 0.1);

      &-title {
        font-size: 16px;
        line-height: 19px;
      }

      &-btn {
        background-color: rgba(white, 0.1);
      }
    }
  }

  .with-bg-wrap {
    position: relative;
    margin: 0 -#{$content-wrap-margin + 15px};
    padding-left: #{$content-wrap-margin + 15px};
    padding-right: #{$content-wrap-margin + 15px};
    background: linear-gradient(180deg, rgba($background-color, 1), rgba(#fff, 0.1));
  }

  .inner-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 20.7px;
    margin-bottom: 10px;

    @include screen('mobile-extra-small') {
      font-size: 20px;
    }
  }

  .view-more-btn {
    min-width: 185px;
    margin: 30px auto 0;
  }

  .splash {
    position: relative;
    height: 100%;

    &-bg {
      position: absolute;
      left: -#{$content-wrap-margin};
      top: -#{$header-height-mobile};
      right: -#{$content-wrap-margin};
      height: calc(100% - #{$text-content-height});
      background: linear-gradient(180deg, rgba(white, 0.2), rgba($background-color, 0));
    }

    &-video-slider {
      height: 240px;
      margin: 0 -#{$content-wrap-margin} 14px;
      @include blink-bg();
    }

    &-video-slider-pagination {
      width: 58px;
      height: 8px;
      border-radius: 4px;
      margin: 0 auto 34px;
      @include blink-bg();
    }

    &-info {
      position: relative;
    }

    &-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }

    &-title {
      height: 32px;
      border-radius: 15px;
      width: 50%;
      @include blink-bg();
    }

    &-actions {
      display: flex;
      gap: 7px;

      i {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        opacity: 0.5;
        @include blink-bg();

        &:nth-child(2) {
          width: 60px;
        }
      }
    }

    &-description {
      position: relative;
      width: 85%;
      margin-bottom: 30px;

      &-line {
        height: 14px;
        border-radius: 15px;
        margin-bottom: 10px;
        @include blink-bg();

        &:last-child {
          width: 80%;
          margin-bottom: 0;
        }
      }
    }

    &-info-details {
      padding: 0 15px;

      &-row {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid rgba(white, 0.1);

        &-title,
        &-value {
          position: relative;
          height: 20px;
          width: 50%;

          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 80%;
            height: 100%;
            border-radius: 10px;
            @include blink-bg();
          }
        }

        &:nth-child(1) {
          .splash-info-details-row-title::before {
            width: 80%;
          }

          .splash-info-details-row-value::before {
            width: 50%;
          }
        }

        &:nth-child(2) {
          .splash-info-details-row-title::before {
            width: 60%;
          }

          .splash-info-details-row-value::before {
            width: 40%;
          }
        }

        &:nth-child(3) {
          .splash-info-details-row-title::before {
            width: 65%;
          }

          .splash-info-details-row-value::before {
            width: 90%;
          }
        }

        &:nth-child(4) {
          .splash-info-details-row-title::before {
            width: 50%;
          }

          .splash-info-details-row-value::before {
            width: 70%;
          }
        }

        &:nth-child(5) {
          .splash-info-details-row-title::before {
            width: 30%;
          }

          .splash-info-details-row-value::before {
            width: 20px;
          }

          .splash-info-details-row-value::after {
            content: '';
            display: block;
            position: absolute;
            left: 30px;
            top: 0;
            width: 20px;
            height: 100%;
            border-radius: 10px;
            @include blink-bg();
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .expandable {
    position: relative;
  }
}

.splash-transition {
  $transition-duration: 200ms;

  &-appear {
    opacity: 0;
  }

  &-appear-active {
    opacity: 1;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-appear-done {
    opacity: 1;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: ease-in;
  }

  &-exit-done {
    opacity: 0;
  }
}

@import 'client/styles/mixins.scss';
@import 'routes/client/styles/theme.scss';

.content-wrap {
  margin: 0 $content-wrap-margin;

  @include screen('mobile-extra-small') {
    margin: 0 #{$content-wrap-margin - 10px};
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.company-banner {
  margin-top: vw_d(80);
  padding: vw_d(40) vw_d(70) vw_d(41) vw_d(38);
  background: linear-gradient(270deg, #267BFF 0%, #0857D3 100%);
  border-radius: vw_d(8);

  @include screen('mobile') {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 31px 21px;
    border-radius: 8px;
  }

  .heading {
    padding-bottom: vw_d(25);
    font-size: vw_d(40);
    line-height: 1.375;
    letter-spacing: vw_d(2.38);
    font-weight: 700;
    margin: 0;
    cursor: default;

    @include screen('mobile') {
      padding-bottom: 25px;
      font-size: 24px;
      line-height: 1.417;
      letter-spacing: 1.43px;
    }
  }

  .text {
    padding-bottom: vw_d(35);
    font-family: $regular-font;
    font-size: vw_d(20);
    line-height: 1.7;
    letter-spacing: 0.39px;
    font-weight: 500;
    cursor: default;

    @include screen('mobile') {
      padding-bottom: 39px;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .link {
    display: inline-block;
    padding: vw_d(12) vw_d(30);
    font-size: vw_d(20);
    letter-spacing: 0.39px;
    font-weight: 700;
    color: $primary-color;
    background-color: white;
    border-radius: vw_d(28);

    @include screen('mobile') {
      padding: 9px 18px;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.31px;
      border-radius: 21px;
    }
  }
}
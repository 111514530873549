@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.founders {
  padding-left: vw_d(103.45);
  padding-bottom: vw_d(120);
  .founders-title {
    padding-bottom: vw_d(29.74);
    font-size: vw_d(26);
    line-height: 1.054;
    font-family: $regular-font;
    font-weight: 700;
    color: white;
  }
  .founders-wrapper {
    width: 100%;
  }
  .btn {
    padding: 0 vw_d(50);
    i {
      display: block;
      margin-top: vw_d(3);
      margin-left: vw_d(7);
      width: vw_d(8);
      height: vw_d(8);
      svg {
        display: block;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

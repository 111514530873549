@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.expandable-container {
  position: relative;

  &.expandable {
    .expand-toggle-btn {
      display: inline-block;
    }
  }
}

.expand-toggle-btn {
  display: none;
  font-weight: 600;

  @include screen('mobile') {
    font-weight: 500;
  }
}

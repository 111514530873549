@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.email-provider {
  @include screen('mobile') {
    margin: 0 -11px;
  }

  @include screen('mobile-extra-small') {
    margin: 0;
  }

  &.sign-in {
    margin-top: -24px;
  }
  &.forgot-password {
    margin-top: 16px;
  }
}

.form {
  max-width: vw_d(363);
  margin: 0 auto;

  @include screen('mobile') {
    max-width: 100%;
  }

  .response-error {
    color: red;
    font-size: vw_d(14);
    font-weight: 400;
    letter-spacing: 0;
    width: 100%;
    text-align: center;

    @include screen('mobile') {
      font-size: 14px;
    }
  }

  &.inactive {
    pointer-events: none;
  }

  &.sign-up-form {
    padding: vw_d(38) 0 vw_d(20);

    @include screen('mobile') {
      padding: 27px 0 0;
    }

    .submit-button {
      margin: vw_d(12) 0 vw_d(42);

      @include screen('mobile') {
        margin: 21px 0 19%;
      }

      @include screen('mobile-extra-small') {
        font-size: 16px;
        height: 50px;
        width: 100%;
      }
    }

    .loader {
      margin-top: vw_d(-35);
      padding-bottom: vw_d(35);
      @include screen('mobile') {
        margin-top: -60px;
        padding-bottom: 60px;
      }
    }
  }

  &.sign-in-form {
    padding: vw_d(90) 0 vw_d(244);

    @include screen('mobile') {
      padding: 90px 0 50%;
    }

    .form-title {
      margin-top: vw_d(-23);
    }

    .field {
      margin-bottom: vw_d(18);

      @include screen('mobile') {
        margin-bottom: 18px;
      }
    }

    .submit-button {
      margin: vw_d(16) 0 0;

      @include screen('mobile') {
        margin: 25px 0 0;
      }
    }

    .loader {
      margin-top: vw_d(35);
      @include screen('mobile') {
        margin-top: 35px;
      }
    }
  }

  &.forgot-password-form {
    padding: vw_d(80) 0 vw_d(209);

    @include screen('mobile') {
      padding: 8% 0 0%;
    }

    .form-title {
      padding-top: vw_d(15);

      @include screen('mobile') {
        padding-top: 15px;
      }
    }

    .submit-button {
      margin: vw_d(54) 0 0;

      @include screen('mobile') {
        margin: 35px 0 0;
      }
    }
  }

  &.reset-password-form {
    padding: vw_d(80) 0 vw_d(150);

    @include screen('mobile') {
      padding: 22% 0 0;
    }

    .form-title {
      padding-top: vw_d(15);

      @include screen('mobile') {
        padding-top: 15px;
      }
    }

    .submit-button {
      margin: vw_d(68) 0 0;

      @include screen('mobile') {
        margin: 68px 0 0;
      }
    }
  }
}

.form-title {
  max-width: vw_d(385);
  font-size: vw_d(50);
  line-height: 1.169;
  font-weight: 700;
  letter-spacing: 0.970572px;
  margin: 0 auto;
  text-transform: uppercase;

  @include screen('mobile') {
    max-width: 100%;
    font-size: 50px;
  }

  @include screen('mobile-small') {
    font-size: 40px;
  }

  @include screen('mobile-extra-small') {
    font-size: 35px;
  }
}

.fields {
  padding: 0 0 vw_d(15);
}

.field {
  margin-bottom: vw_d(11);

  @include screen('mobile') {
    margin-bottom: 11px;
  }

  input {
    font-family: $inter;

    @include screen('mobile') {
      box-shadow: inset 0 0 0 1px white !important;
    }
  }

  .hint {
    font-size: 10px;
    letter-spacing: 0.08px;
    color: rgba(255, 255, 255, 0.6);

    @include screen('mobile') {
      font-size: 11px;
    }
  }
}

.submit-button {
  width: vw_d(340);
  height: vw_d(60);
  font-family: $regular-font;
  font-weight: 700;
  font-size: vw_d(20);
  line-height: 1.566;
  letter-spacing: 0.39px;
  background-color: $science-blue;
  border-radius: vw_d(27.53);
  transition: 0.3s;

  @include screen('mobile') {
    height: 60px;
    width: 93%;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.566;
    letter-spacing: 0.39px;
    border-radius: 27.53px;
  }

  @include screen('mobile-small') {
    font-size: 18px;
  }

  @include screen('mobile-extra-small') {
    font-size: 18px;
    height: 50px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:focus {
    cursor: pointer;
    opacity: 0.8;
  }
}

.back-button {
  position: absolute;
  top: vw_d(29);
  left: vw_d(30);
  font-size: vw_d(22);
  letter-spacing: 0.43px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.48);
  transition: 0.3s;

  @include screen('mobile') {
    display: block;
    top: 0;
    left: vw(10);
    font-size: 16px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.forgot-button {
  position: absolute;
  left: 0;
  bottom: vw_d(34);
  width: 100%;
  text-align: center;
  font-size: vw_d(14);
  letter-spacing: 0.27px;
  color: $light-blue;
  text-decoration: underline;
  transition: 0.3s;

  @include screen('mobile') {
    bottom: 10px;
    font-weight: 700;
    font-size: 16px;
  }

  @include screen('mobile-extra-small') {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.close-button {
  position: absolute;
  top: vw_d(21);
  left: vw_d(17);
  width: vw_d(40);
  height: vw_d(40);
  transition: 0.3s;

  @include screen('mobile') {
    top: -4px;
    left: 3px;
    width: 32px;
    height: 32px;
  }

  svg {
    width: vw_d(20);
    height: vw_d(20);

    @include screen('mobile') {
      width: 14px;
      height: 14px;
    }
  }

  @include screen('desktop') {
    &:hover {
      opacity: 0.8;
    }
  }
}

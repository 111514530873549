@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.faq-wrapper {
  min-height: 100vh;
  padding-top: vw_d(133);
  position: relative;

  .page-header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
  }

  .control-panel {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
  }

  @include screen('mobile') {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    min-height: auto;
    padding-top: calc(#{$header-height-mobile} + 29px);
    padding-bottom: calc(#{$footer-height-mobile} + 40px);
  }

  :global {
    .react-slidedown.transitioning {
      overflow-y: hidden;
    }

    .react-slidedown.closed {
      display: none;
    }
  }

  .content-wrapper {
    width: vw_d(1342);
    margin: 0 auto;
    padding-top: vw_d(60);
    padding-bottom: vw_d(135);

    @include screen('mobile') {
      width: 100%;
      padding-left: $content-wrap-margin;
      padding-right: $content-wrap-margin;
    }

    .title {
      font-family: $regular-font;
      font-size: vw_d(50);
      font-weight: 700;
      line-height: 0.853;
      letter-spacing: vw_d(8);
      color: #fff;

      @include screen('mobile') {
        font-size: 50px;
        letter-spacing: normal;
      }
    }

    .faqs {
      padding-top: vw_d(85.1);

      @include screen('mobile') {
        padding-top: 80px;
      }

      .faq {
        padding: vw_d(39) vw_d(15) vw_d(39) vw_d(11);
        border-top: vw_d(1.51) solid #979797;

        @include screen('mobile') {
          padding: 25px 15px 25px 10px;
          border-top-width: 1px;
        }

        &.active {
          background-color: rgba(255, 255, 255, 0.05);
          .name {
            .btn {
              &:after {
                opacity: 0;
              }
            }
          }
        }
        .name {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            flex-grow: 1;
            padding-right: vw_d(20);
            font-size: vw_d(26);
            line-height: 1.208;
            color: #fff;
            font-family: $regular-font;
            font-weight: 600;

            @include screen('mobile') {
              padding-right: 20px;
              font-size: 22px;
            }
          }

          .btn {
            position: relative;
            width: vw_d(33);
            height: vw_d(33);
            border-radius: vw_d(16.5);
            background-color: #0757d3;
            flex-shrink: 0;

            @include screen('mobile') {
              width: 33px;
              height: 33px;
              border-radius: 16px;
            }

            &:before,
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: vw_d(20);
              height: vw_d(3);
              border-radius: vw_d(1.5);
              background-color: #fff;
              transition: opacity ease 0.2s;
              @include screen('mobile') {
                width: 20px;
                height: 3px;
                border-radius: 2px;
              }
            }
            &:before {
              transform: translate(-50%, -50%);
            }
            &:after {
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }
        }

        .description-wrapper {
          transition-property: none;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;

          .description {
            padding-top: vw_d(25);
            font-size: vw_d(18);
            line-height: 1.98;
            color: #fff;
            font-family: $regular-font;
            font-weight: 400;

            @include screen('mobile') {
              padding-top: 25px;
              font-size: 16px;
            }

            p {
              font-size: vw_d(18) !important;
              line-height: 1.98 !important;

              @include screen('mobile') {
                font-size: 16px !important;
              }

              a {
                text-decoration: underline;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import 'client/styles/mixins.scss';
@import 'client/styles/theme.scss';

.video-slider {
  position: relative;
  margin: 0 -#{$content-wrap-margin} 34px;

  &-items {
    position: relative;
    width: 100%;
    height: 240px;
    overflow: hidden;
    margin-bottom: 14px;
  }

  &-item {
    position: relative;
    height: 240px;
    background-size: cover;
    background-position: center top;
    background-color: #111;

    @include screen('mobile-large') {
      width: 375px;
      margin: 0 auto;
    }
  }

  .progress-bar {
    position: relative;
    height: 5px;
    background-color: rgba(white, 0.34);
    margin-bottom: 14px;

    .progress {
      width: 30%;
      height: 100%;
      background-color: $primary-color;
    }
  }

  .pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    :global {
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: rgba(white, 0.3);
        transition: width, background-color;
        transition-duration: 0.5s;
      }

      .swiper-pagination-bullet-active {
        width: 22px;
        background-color: rgba(white, 0.7);
      }
    }
  }
}

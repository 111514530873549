@import 'client/styles/mixins.scss';
@import 'client/styles/theme.scss';

.founder {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 8px;
  border-bottom: 1px solid rgba(#fff, 0.1);

  &-photo {
    width: 56px;
    min-width: 56px;
    height: 56px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
  }

  &-info {
    font-size: 16px;

    @include screen('mobile-extra-small') {
      font-size: 14px;
    }
  }

  &-name {
    font-weight: 700;
  }

  &-role {
    font-weight: 400;
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.target-info {
  position: fixed;
  bottom: 59px;
  width: 100%;
  background-color: rgb(26, 26, 26);
  border-top: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 100;

  &.opened {
    .content {
      .details {
        height: 145px;
        padding: 24px 0 19px;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
      // unlock button
      button {
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  .height-trigger {
    position: absolute;
    top: 29px;
    left: 27px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.91);
    opacity: 0.38;
    transition: 0.3s;

    @include screen('mobile-extra-small') {
      left: 15px;
    }

    &.is-opened {
      transform: rotate(180deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 6px;
      height: 10px;
      width: 3px;
      background-color: rgb(26, 26, 26);
      border-radius: 7px;
    }

    &:before {
      left: 55%;
      transform: rotate(-45deg);
    }
    &:after {
      right: 55%;
      transform: rotate(45deg);
    }
  }

  .content {
    padding-left: 72px;
    padding-right: 37px;
    transition: 3s;

    @include screen('mobile-extra-small') {
      padding-left: 50px;
      padding-right: 15px;
    }

    .details {
      height: 0;
      border-bottom: 2px solid rgba(255, 255, 255, 0.18);
      transform: translateY(100%);
      opacity: 0;
      visibility: hidden;
      transition: 0.45s;
    }
    // unlock button
    button {
      visibility: hidden;
      pointer-events: none;
    }

    .short-view {
      button {
        visibility: visible;
        pointer-events: all;
      }

      button:not(.place-offer-btn) {
        margin-left: -30px;
        padding: 0 30px;
        background-color: transparent;
        backdrop-filter: none;
      }

      .restricted {
        font-size: 16px;
        font-weight: 600;
        filter: blur(7px);
      }
    }
  }
}

.investment-details {
  display: block;
  align-items: center;

  &-block {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: vw_d(18);

    &:first-child {
      padding-left: 0;
      flex-direction: column-reverse;
    }
  }

  .progress-wrap {
    position: relative;
    width: 100%;
    height: 11px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5.3px;
    margin: 10px 0 15px;

    @include screen('mobile-extra-small') {
      height: 8px;
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 11px;
      background: linear-gradient(270deg, rgb(37, 123, 255) 0%, rgb(7, 87, 211) 100%);
      border-radius: 5.3px;

      @include screen('mobile-extra-small') {
        height: 8px;
      }
    }
  }

  .values {
    display: flex;
    width: 100%;
    white-space: wrap;

    @include screen('mobile-extra-small') {
      flex-flow: column nowrap;
    }

    .target {
      position: relative;
      padding-right: 17px;
      font-size: 20px;
      line-height: 1.35;
      letter-spacing: 1px;
      font-weight: 600;
      text-transform: uppercase;

      @include screen('mobile-extra-small') {
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0.8px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 5px;
        right: 8px;
        height: 16px;
        width: 1px;
        background-color: white;

        @include screen('mobile-extra-small') {
          display: none;
        }
      }
    }

    .raised {
      font-size: 16px;
      line-height: 1.688;
      letter-spacing: 0.8px;
      font-weight: 600;

      @include screen('mobile-extra-small') {
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: 0.8px;
      }
    }
  }

  .round {
    display: flex;
    font-size: 14px;
    line-height: 1.714;
    letter-spacing: 0.85px;
    white-space: nowrap;

    @include screen('mobile-extra-small') {
      font-size: 13px;
      line-height: 1.6;
      letter-spacing: 0.8px;
    }

    &-title {
      font-weight: 600;
    }

    &-valuation {
      position: relative;
      padding-left: 16px;
      font-weight: 600;

      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 8px;
        height: 14px;
        width: 1px;
        background-color: white;

        @include screen('mobile-extra-small') {
          top: 3px;
        }
      }
    }
  }
}

.short-view {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 0 24px;
  background-color: rgb(26, 26, 26);

  @include screen('mobile-extra-small') {
    flex-flow: column nowrap;
    align-items: flex-start;
    row-gap: 20px;
  }

  .short-details {
    @include screen('mobile-extra-small') {
      width: 100%;
    }

    .short-investment-details {
      .progress-wrap {
        position: relative;
        width: 100%;
        height: 6px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 5.3px;
        margin: 7px 0 0;

        .progress {
          position: absolute;
          top: 0;
          left: 0;
          height: inherit;
          background: linear-gradient(270deg, rgb(37, 123, 255) 0%, rgb(7, 87, 211) 100%);
          border-radius: 5.3px;
        }
      }

      .values-info {
        @include screen('mobile-extra-small') {
          display: flex;
          align-items: center;
        }
      }

      .values {
        width: 100%;

        .target {
          position: relative;
          padding-right: 10px;
          font-size: 12.8px;
          line-height: 1.125;
          letter-spacing: 0.64px;
          font-weight: 600;
          text-transform: uppercase;

          @include screen('mobile-extra-small') {
            font-size: 11px;
            line-height: 1.1;
          }
        }

        .raised {
          font-size: 11.2px;
          line-height: 1.286;
          letter-spacing: 0.56px;
          font-weight: 600;
        }
      }
    }
  }

  .terms-of-deal {
    position: relative;
    // width: vw_d(158);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @include screen('mobile-extra-small') {
      height: 27.41px;
    }

    p {
      cursor: pointer;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.85px;
      text-decoration: underline;
    }

    &-icon {
      cursor: pointer;
      position: relative;
      width: 15px;
      height: 15px;
      border: 1px solid white;
      border-radius: 50%;
      @include screen('mobile') {
        min-width: 15px;
      }

      &::before {
        content: '?';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
      }
    }

    &-tooltip {
      position: absolute;
      z-index: 10;
      top: vw_d(-18);
      left: 30%;
      width: vw_d(250);
      padding: vw_d(10);
      background-color: rgba(0, 0, 0, 1);
      border-radius: vw_d(4);
      font-size: vw_d(14);
      line-height: vw_d(18);
      letter-spacing: vw_d(0.1);
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, calc(-100% - vw_d(10)));
      transition: all 0.2s ease;
      @include screen('mobile') {
        width: 250px;
        padding: 10px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 100%;
        width: 1px;
        height: 1px;
        border: vw_d(10) solid transparent;
        border-top-color: rgba(0, 0, 0, 1);
        transform: translate(vw_d(-5), 0);
      }
    }

    &:hover,
    &:focus {
      .terms-of-deal-tooltip {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, calc(-100% - vw_d(0)));
      }
    }
  }

  .place-offer-btn {
    height: 42px;
    min-width: 156px;
    font-size: 16px;
    letter-spacing: 0.31px;
    border-radius: 27.53px;

    @include screen('mobile') {
      margin-left: 15px;
    }

    @include screen('mobile-extra-small') {
      width: 100%;
      margin-left: 0;
      font-size: 14px;
      height: 40px;
    }
  }
}

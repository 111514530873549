@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.investor-profile {
  position: relative;

  @include screen('mobile') {
    padding: 24px 32px 0;
  }

  @include screen('mobile-extra-small') {
    padding: 24px 20px 0;
  }

  section {
    margin-bottom: vw_d(56);

    @include screen('mobile') {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: vw_d(150);

      @include screen('mobile') {
        margin-bottom: 0;
      }
    }
  }
}

.section-header {
  padding: vw_d(53) 0;
  background-color: rgba(#fff, 0.08);

  @include screen('mobile') {
    padding: 22px 34px 24px;
  }

  @include screen('mobile-extra-small') {
    padding: 20px;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include screen('mobile') {
      display: block;
      margin: 0;
    }
  }

  .title-and-text {
    width: 50%;

    @include screen('mobile') {
      width: 100%;
    }

    .title {
      font-size: vw_d(45);
      line-height: vw_d(50);
      letter-spacing: vw_d(0.87);
      font-weight: 600;
      margin-bottom: vw_d(14);
      @extend %overflow-ellipsis;

      @include screen('mobile') {
        font-size: 22px;
        line-height: 1.273;
        letter-spacing: 1.5px;
        margin-bottom: 23px;
        overflow: auto;
        white-space: normal;
      }

      @include screen('mobile-extra-small') {
        font-size: 20px;
        line-height: 1.273;
        letter-spacing: 1.2px;
        margin-bottom: 20px;
      }
    }

    .sub-title {
      font-size: vw_d(16);
      line-height: vw_d(26);
      letter-spacing: vw_d(0.4);
    }
  }

  .progress-wrap {
    width: vw_d(352);
    margin-top: vw_d(10);

    @include screen('mobile') {
      width: 100%;
      margin-top: 5px;
    }

    .progress-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: vw_d(20);

      @include screen('mobile') {
        margin-bottom: 6px;
      }

      p {
        font-size: vw_d(20);
        line-height: vw_d(37.5);
        letter-spacing: vw_d(0.5);
        text-transform: uppercase;

        @include screen('mobile') {
          font-size: 16px;
          line-height: 2.344;
          letter-spacing: 0.4px;
        }

        @include screen('mobile-extra-small') {
          font-size: 14px;
          line-height: 2;
        }

        &:first-child {
          width: 50%;
          @extend %overflow-ellipsis;
        }
      }
    }

    .progress-bar {
      position: relative;
      height: vw_d(32.68);
      border-radius: vw_d(5);
      background-color: rgba(#fff, 0.2);

      @include screen('mobile') {
        height: 20px;
        border-radius: 7px;
      }

      .progress {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: inherit;
        background: linear-gradient(-90deg, #257bff 0%, #0757d3 100%);
      }
    }
  }
}

.section-stages {
  position: relative;

  .stage {
    position: relative;
    padding: vw_d(32) 0;
    background-color: rgba(#fff, 0.1);
    border-top: 1px solid rgba(#fff, 0.15);

    @include screen('mobile') {
      padding: 28px 0 23px;
    }

    &-content {
      display: flex;
      align-items: center;

      @include screen('mobile') {
        display: block;
      }
    }

    &-title {
      font-size: vw_d(20);
      font-weight: 600;
      width: vw_d(280);
      margin-right: vw_d(40);
      @extend %overflow-ellipsis;

      @include screen('mobile') {
        width: calc(100% - 50px);
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.4px;
        overflow: auto;
        white-space: normal;
      }

      @include screen('mobile-extra-small') {
        font-size: 14px;
      }
    }

    &-status-completed,
    &-status-incompleted {
      position: relative;
      padding-left: vw_d(54);
      font-size: vw_d(20);
      flex-grow: 1;

      @include screen('mobile') {
        width: calc(100% - 50px);
        padding-left: 25px;
        font-size: 14px;
        line-height: 1.143;
        letter-spacing: 0.35px;
      }

      @include screen('mobile-extra-small') {
        font-size: 13px;
      }
    }

    &-status-completed {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: vw_d(29);
        height: vw_d(29);
        border-radius: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(-90deg, #257bff 0%, #0757d3 100%);
        box-shadow: 0 0 vw_d(10) vw_d(0) #257bff;

        @include screen('mobile') {
          width: 17px;
          height: 17px;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: vw_d(9);
        top: calc(50% - vw_d(2));
        z-index: 2;
        width: vw_d(14);
        height: vw_d(7);
        border-left: vw_d(1) solid white;
        border-bottom: vw_d(1) solid white;
        transform: translate(0, -50%) rotate(-45deg);

        @include screen('mobile') {
          width: 8px;
          height: 4px;
          left: 5px;
          top: calc(50% - 1px);
          border-left: 1px solid white;
          border-bottom: 1px solid white;
        }
      }
    }

    &-status-incompleted {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: vw_d(29);
        height: vw_d(29);
        border-radius: 50%;
        transform: translate(0, -50%) scale(0.6);
        background: #f29736;
        box-shadow: 0 0 vw_d(30) vw_d(0) #ffc98f;

        @include screen('mobile') {
          width: 14px;
          height: 14px;
          box-shadow: 0 0 23px vw_d(0) #ffc98f;
        }
      }
    }

    .action-btn {
      background-color: rgba(#fff, 0.1);
      height: vw_d(56);
      border-radius: vw_d(28);
      min-width: vw_d(120);
      padding: 0 vw_d(37);
      font-size: vw_d(18);
      font-weight: 400;

      @include screen('mobile') {
        position: absolute;
        top: 50%;
        right: 30px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        min-width: 40px;
        padding: 0;
        transform: translateY(-50%);

        @include screen('mobile-extra-small') {
          right: 20px;
        }

        .edit-icon {
          width: 14px;
          height: 14px;

          svg {
            width: inherit;
            height: inherit;
          }
        }
      }
    }
  }
}

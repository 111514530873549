@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.kyc-wrapper {
  min-height: 100%;
  position: relative;

  @include screen('mobile') {
    display: block;
    height: 100%;
    min-height: auto;
  }

  @include screen('mobile-large') {
    max-width: 400px;
    margin: 0 auto;
  }

  .page-header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
  }

  .control-panel {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
  }

  .kyc-content {
    border-radius: 10px;
    border: solid 1px #464646;
    overflow: hidden;

    iframe {
      display: block;
    }
  }

  .content-wrapper {
    width: 551px;
    margin: 0 auto;
    padding-top: vw_d(60);
    padding-bottom: vw_d(135);

    @include screen('mobile') {
      position: absolute;
      left: 0;
      top: 40px;
      bottom: 20px;
      width: 100%;
      padding: 0;
      overflow: auto;
    }
  }
}

.closeBtnMobile {
  display: none;
  @include screen('mobile') {
    display: block;
    position: fixed;
    width: 21px;
    height: 21px;
    left: 25px;
    top: 25px;
    z-index: 10;
  }
  @include screen('mobile-large') {
    left: calc((100vw - 400px) / 2);
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: #fff;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 737px;
  height: 90%;
  max-height: 968px;
  padding-top: 75px;
  background-color: rgba(0, 0, 0, 0.55);
  border: 1px solid #979797;
  border-radius: 12px;
  z-index: 2;

  @include screen('mobile') {
    position: relative;
    left: auto;
    top: auto;
    transform: translate(0, 0);
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-top: 57px;
    flex-grow: 1;
    max-height: inherit;
  }

  @include screen('mobile-large') {
    max-width: 400px;
    margin: 50px auto 0;
  }

  .close-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 27px;
    top: 23px;
    cursor: pointer;

    @include screen('mobile') {
      width: 30px;
      height: 30px;
      left: -5px;
      top: -54px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 26px;
      height: 3px;
      background-color: #fff;
      border-radius: 2px;

      @include screen('mobile') {
        width: 32px;
        height: 3px;
      }
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .country {
    position: absolute;
    top: 22px;
    right: 34px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    font-family: $regular-font;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.87);
    .globe-icon {
      margin-right: 5px;
    }

    @include screen('mobile') {
      top: 19px;
      right: 11px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
    }
  }

  .title {
    padding: 0 50px 30px;
    font-size: 35px;
    line-height: 50px;
    font-family: $regular-font;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.23);
    @include screen('mobile') {
      padding: 0 11px 10px;
      font-size: 24px;
      line-height: 35.46px;
    }
    @include screen('(max-width: 380px)') {
      font-size: 20px;
    }

    br {
      display: inline-block;
      @include screen('mobile') {
        display: none;
      }
    }
  }
  .content {
    position: absolute;
    top: 236px;
    bottom: 126px;
    left: 50px;
    right: 49px;
    @include screen('mobile') {
      top: 150px;
      bottom: 194px;
      left: 11px;
      right: 11px;
    }

    .content-i {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 50px;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8954175420168067) 100%);
      bottom: -1px;
      left: 0;
    }
  }
  .text {
    margin-bottom: 20px;
    p {
      padding-bottom: 29px;
      font-size: 16px;
      line-height: 27px;
      font-family: $regular-font;
      font-weight: 400;
      color: #fff;
    }

    ul {
      margin: 0;
      padding: 0 0 0 34px;
      list-style: none;
      & > li {
        position: relative;
        margin: 0;
        padding: 0 0 29px 14px;
        list-style: none;
        font-size: 16px;
        line-height: 29px;
        font-family: $regular-font;
        font-weight: 400;
        color: #fff;
        &:after {
          content: '';
          position: absolute;
          left: 0px;
          top: 12px;
          width: 7px;
          height: 7px;
          background-color: #fff;
          border-radius: 50%;
        }
        ol {
          margin: 0;
          padding: 0 0 0 34px;
          li {
            position: relative;
            margin: 0;
            padding: 0 0 29px 0;
            font-size: 16px;
            line-height: 29px;
            font-family: $regular-font;
            font-weight: 400;
            color: #fff;
          }
        }
      }
    }
    ol {
      margin: 0;
      padding: 0 0 0 34px;
      li {
        position: relative;
        margin: 0;
        padding: 0 0 29px 0;
        font-size: 16px;
        line-height: 29px;
        font-family: $regular-font;
        font-weight: 400;
        color: #fff;
      }
    }
  }

  .checkboxes {
    padding-top: 52px;
    padding-bottom: 62px;
    .checkbox-row {
      padding-bottom: 20px;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .buttons {
    display: flex;
    column-gap: 25px;
    padding: 0 50px 41px 50px;
    @include screen('mobile') {
      flex-wrap: wrap;
      padding: 0 11px 23px;
      column-gap: 0px;
    }
    .cancel-button,
    .confirm-button {
      position: relative;
      height: 55px;
      min-width: 160px;
      border-radius: 28px;
      font-size: 20.09px;
      letter-spacing: 0.39px;
      font-family: $regular-font;
      font-weight: 700;
      color: #fff;
      overflow: inherit;
      pointer-events: all;

      @include screen('mobile') {
        width: 100%;
        height: 60px;
        font-size: 18px;
        letter-spacing: 0.35px;
        border-radius: 30px;
      }
      .tooltip {
        position: absolute;
        z-index: 10;
        top: vw_d(-18);
        left: 50%;
        width: vw_d(250);
        padding: vw_d(10);
        background-color: rgba(0, 0, 0, 1);
        border-radius: vw_d(4);
        font-size: vw_d(14);
        line-height: vw_d(18);
        letter-spacing: vw_d(0.1);
        white-space: normal;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, calc(-100% - vw_d(10)));
        transition: all 0.2s ease;
        @include screen('mobile') {
          top: vw(-18);
          width: vw(250);
          padding: vw(10);
          border-radius: vw(4);
          font-size: vw(14);
          line-height: vw(18);
          letter-spacing: vw(0.1);
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 100%;
          width: 1px;
          height: 1px;
          border: vw_d(10) solid transparent;
          border-top-color: rgba(0, 0, 0, 1);
          transform: translate(vw_d(-5), 0);
          @include screen('mobile') {
            border: vw(10) solid transparent;
            border-top-color: rgba(0, 0, 0, 1);
            transform: translate(vw(-5), 0);
          }
        }
      }
      &:hover,
      &:focus {
        .tooltip {
          opacity: 1;
          visibility: visible;
          transform: translate(-50%, calc(-100% - vw_d(0)));
        }
      }
    }
    .cancel-button {
      background-color: rgba(255, 255, 255, 0.18);
      @include screen('mobile') {
        width: 100% !important;
      }
    }

    .confirm-button {
      padding: 0 31px 0 32px;
      @include screen('mobile') {
        width: 100% !important;
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

:global {
  .custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: vw_d(24);
    .swiper-pagination-bullet {
      width: vw_d(12);
      height: vw_d(12);
      margin-right: vw_d(6);
      border-radius: vw_d(6);
      background-color: rgba(255, 255, 255, 0.38);
      transition: all ease 0.2s;
      opacity: 1;
      &:last-child {
        margin-right: 0;
      }
      &.swiper-pagination-bullet-active {
        width: vw_d(33);

        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
.video-wrapper {
  width: 100%;
  height: vw_d(747);
}

@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.main-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  @include screen('mobile') {
    position: absolute;
    left: 25px;
    right: 25px;
    top: 0;
    bottom: 10px;
    width: auto;
    height: auto;
    overflow: initial;
  }

  &.nda {
    @include screen('mobile') {
      bottom: 20px;
      padding-bottom: 0px;
    }
  }

  .wrapper {
    min-height: 100vh;
    position: relative;
    padding-top: 211px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    @include screen('mobile') {
      min-height: inherit;
      padding-top: 0px;
      padding-bottom: 0px;
      height: 100%;
    }
  }

  .close-btn {
    display: none;

    @include screen('mobile') {
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      left: 0px;
      top: 0px;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 26px;
        height: 3px;
        background-color: #fff;
        border-radius: 2px;
        @include screen('mobile') {
          width: 32px;
          height: 3px;
        }
      }

      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  .stage-wrapper {
    width: 100%;
    max-width: 910px;
    margin: 0 auto;
    @include screen('mobile') {
      height: 100%;
      padding-top: 85px;
    }
  }

  .blurred-block {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.85);
    filter: saturate(-50%);
    backdrop-filter: blur(6px);
  }
}

.stage-transition {
  $transition-duration: 300ms;

  &-appear {
    opacity: 0;
  }

  &-appear-active {
    opacity: 1;
    transition: opacity;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-appear-done {
    opacity: 1;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity;
    transition-duration: #{$transition-duration};
    transition-timing-function: linear;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity, transform;
    transition-duration: #{$transition-duration};
  }

  &-exit-done {
    opacity: 0;
  }
}

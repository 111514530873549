@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 150;
  position: relative;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    width: vw_d(30);
    height: vw_d(30);
    // margin-left: vw_d(-15);
    // margin-top: vw_d(-15);
    border: vw_d(4) solid #0757d3;
    border-bottom-color: transparent !important;
    animation-name: rotate;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-style: preserve-3d;

    @include screen('mobile') {
      width: 30px;
      height: 30px;
      margin: 0;
      border-width: 4px;
    }
  }
}

@keyframes rotate {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

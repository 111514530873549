@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.page-wrapper {
  min-height: 100vh;
  padding-top: vw_d(133);
  position: relative;

  .page-header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
  }

  .control-panel {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
  }

  @include screen('mobile') {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    min-height: auto;
    padding-top: calc(#{$header-height-mobile} + 29px);
    padding-bottom: calc(#{$footer-height-mobile} + 40px);
  }

  .content-wrapper {
    width: vw_d(1342);
    margin: 0 auto;
    padding-top: vw_d(90.75);
    padding-bottom: vw_d(135);

    @include screen('mobile') {
      width: 100%;
      padding-left: $content-wrap-margin;
      padding-right: $content-wrap-margin;
    }
  }
}

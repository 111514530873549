@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.footer-disclaimer {
  width: vw_d(1328);
  margin: 0 auto;
  padding: vw_d(44) 0 vw_d(64);

  @include screen('mobile') {
    display: none;
  }

  .title {
    padding-bottom: vw_d(24);
    font-size: vw_d(18);
    line-height: vw_d(22);
    color: #cecece;
    font-family: $regular-font;
    font-weight: 600;
  }

  .text {
    font-size: vw_d(13);
    line-height: vw_d(22);
    color: #cecece;
    font-family: $regular-font;
    font-weight: 400;
    p {
      padding-bottom: vw_d(30);
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @include screen('mobile') {
    position: relative;
    height: auto;
    min-height: 100%;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  &-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.78);
  }

  &.sign-up {
    .title {
      @include screen('mobile') {
        margin-bottom: 34%;
      }
    }
  }

  &.sign-in {
    .login {
      @include screen('desktop') {
        padding: vw_d(80) vw_d(87) vw_d(28);
      }

      .providers {
        padding-bottom: vw_d(80);
      }
    }
    .bottom {
      font-size: vw_d(20);
      letter-spacing: 0.39px;
      font-weight: 700;

      @include screen('mobile') {
        font-size: 16px;
      }

      @include screen('mobile-extra-small') {
        font-size: 14px;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: vw_d(21);
    left: vw_d(17);
    width: vw_d(40);
    height: vw_d(40);
    transition: 0.3s;

    svg {
      width: vw_d(20);
      height: vw_d(20);
    }

    @include screen('desktop') {
      &:hover {
        opacity: 0.8;
      }
    }

    @include screen('mobile') {
      top: -4px;
      left: 3px;
      width: 32px;
      height: 32px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .back-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -4px;
    left: 3px;
    width: 32px;
    height: 32px;
  }
}

.login-wrapper {
  position: absolute;
  display: flex;
  width: vw_d(1153);
  min-height: vw_d(750);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #979797;
  border-radius: vw_d(12);
  overflow: hidden;

  @include screen('mobile') {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    width: 100%;
    border: none;
    overflow: inherit;
  }

  .desktop-additional {
    position: relative;
    max-width: vw_d(573);
    flex-grow: 1;
    background-image: url('client/assets/login/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: vw_d(60);

    @include screen('mobile') {
      display: none;
    }

    .title {
      padding-bottom: vw_d(36.54);
      font-family: $regular-font;
      font-size: vw_d(20.09);
      line-height: 1.566;
      letter-spacing: vw_d(0.39);
      font-weight: 700;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: block;
        position: relative;
        padding-left: vw_d(30);
        margin-bottom: vw_d(20);
        list-style-type: none;
        font-family: $regular-font;
        font-size: vw_d(16);
        line-height: 1.625;
        font-weight: 500;

        .icon {
          position: absolute;
          top: vw_d(3);
          left: vw_d(1);
          width: vw_d(30);
          min-width: vw_d(30);
        }

        span {
          flex-grow: 1;
        }
      }
    }
  }
}
.login {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  position: relative;
  width: vw_d(580);
  padding: vw_d(80) vw_d(87) vw_d(35);
  text-align: center;
  // border: 1px solid #979797;
  // border-radius: vw_d(12);
  // background-color: rgba(0, 0, 0, 0.5);

  @include screen('mobile') {
    position: initial;
    top: initial;
    left: initial;
    transform: none;
    width: 100%;
    padding: 25px 11px;
    text-align: center;
    border: none;
    border-radius: 0;
    background-color: transparent;

    @include screen('mobile-large') {
      width: 400px;
      margin: 0 auto;
    }

    @include screen('mobile-extra-small') {
      padding: 25px 0;
    }
  }

  .title {
    height: vw_d(180);
    font-size: vw_d(50);
    line-height: 1.169;
    font-weight: 700;
    letter-spacing: 0.970572px;
    text-transform: uppercase;

    @include screen('mobile') {
      height: auto;
      font-size: 50px;
      line-height: 1.169;
      margin-bottom: 25%;
      letter-spacing: 0.970572px;
    }

    @include screen('mobile-small') {
      font-size: 40px;
    }

    @include screen('mobile-extra-small') {
      font-size: 35px;
    }
  }

  .divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: vw_d(340);
    margin: vw_d(10) auto vw_d(40);
    font-size: vw_d(20);

    @include screen('mobile') {
      max-width: 100%;
      margin: 10px auto 12%;
      font-size: 20px;
    }

    @include screen('mobile-extra-small') {
      font-size: 16px;
    }

    &-text {
      display: inline-block;
      margin: 0 vw_d(40);

      @include screen('mobile') {
        margin: 0 40px;
      }
    }

    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
  }

  .providers {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: vw_d(100);

    .google,
    .linkedin,
    .email {
      position: relative;
      display: flex;
      align-items: center;
      width: vw_d(339);
      height: vw_d(60);
      font-size: vw_d(20);
      font-weight: 700;
      letter-spacing: 0.4px;
      border-radius: vw_d(30);
      margin-bottom: vw_d(30);

      @include screen('mobile') {
        width: 100%;
        height: 60px;
        font-size: 20px;
        border-radius: 30px;
        margin-bottom: 9%;
      }

      @include screen('mobile-small') {
        font-size: 18px;
      }

      @include screen('mobile-extra-small') {
        font-size: 15px;
        letter-spacing: 0px;
        height: 50px;
      }

      &:hover {
        opacity: 0.8;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: vw_d(63);
        transform: translate(0, -50%);
        width: 1px;
        height: vw_d(43);
        background-color: rgba(118, 118, 118, 0.17);

        @include screen('mobile') {
          left: 63px;
          width: 1px;
          height: 43px;
        }

        @include screen('mobile-extra-small') {
          left: 50px;
        }
      }

      .icon {
        position: absolute;
        left: vw_d(19);
        top: 50%;
        transform: translate(0, -50%);

        @include screen('mobile') {
          left: 19px;
        }

        @include screen('mobile-extra-small') {
          left: 15px;
        }
      }

      .label {
        padding-left: vw_d(79);

        @include screen('mobile') {
          padding-left: 79px;
          padding-right: 15px;
        }

        @include screen('mobile-extra-small') {
          padding-left: 59px;
          padding-right: 10px;
        }
      }
    }

    .google {
      color: #767676;
      background-color: #fff;

      i {
        width: vw_d(31);
        height: vw_d(31);

        @include screen('mobile') {
          width: 31px;
          height: 31px;
        }

        @include screen('mobile-extra-small') {
          width: 26px;
          height: 26px;
        }

        svg {
          width: inherit;
          height: inherit;
        }
      }
    }

    .linkedin {
      color: #fff;
      background-color: #0b66c2;

      &:before {
        background-color: rgba(255, 255, 255, 0.17);
      }

      i {
        width: vw_d(29);
        height: vw_d(29);

        @include screen('mobile') {
          width: 29px;
          height: 29px;
        }

        @include screen('mobile-extra-small') {
          width: 26px;
          height: 26px;
        }

        svg {
          width: inherit;
          height: inherit;
        }
      }
    }

    .email {
      justify-content: center;
      background-color: #202020;

      &:before {
        display: none;
      }

      .label {
        padding: 0;
      }
    }
  }

  .bottom {
    font-size: vw_d(14);
    letter-spacing: 0.22px;
    white-space: nowrap;

    @include screen('mobile') {
      position: absolute;
      bottom: 9px;
      left: 0;
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.39px;
      white-space: normal;
    }

    .link {
      color: $light-blue;
      text-decoration: underline;
      transition: 0.3s;
      @include screen('mobile') {
        display: inline-block;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}

.terms {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: vw_d(33);
  font-size: 13px;
  letter-spacing: 0.22px;
  white-space: normal;

  @include screen('mobile') {
    position: relative;
    // bottom: 7%;
    font-size: 14px;
    letter-spacing: 0.22px;
    white-space: normal;
    padding: 0 11%;
  }

  @include screen('mobile-extra-small') {
    font-size: 13px;
  }

  .link {
    color: $light-blue;
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.login-success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: vw_d(580);
  padding: vw_d(137) vw_d(87) vw_d(116);
  text-align: center;
  border: 1px solid #979797;
  border-radius: vw_d(12);
  background-color: rgba(0, 0, 0, 0.5);

  .avatar {
    width: vw_d(98);
    height: vw_d(98);
    margin: 0 auto;
    border-radius: 100%;
    overflow: hidden;
    background-color: #767676;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .name {
    font-size: vw_d(50);
    line-height: 1.169;
    font-weight: 700;
    letter-spacing: 0.970572px;
    text-transform: uppercase;
    margin: vw_d(24) 0 vw_d(14);
  }

  .greeting-message {
    font-size: vw_d(20);
    margin-bottom: vw_d(92);
  }

  .start-button,
  .join-button {
    width: vw_d(339);
    height: vw_d(60);
    font-size: vw_d(20);
    font-weight: 700;
    letter-spacing: 0.4px;
    border-radius: vw_d(30);
    margin-bottom: vw_d(30);
    background-color: rgb(11, 102, 194);
  }

  .join-button {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
